import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Input, Select, Checkbox, Button, Col, Row, Divider, notification } from 'antd';
// import { BtnSubmit } from '../button/BtnSubmit';
import { createDiagniosticRcp } from '../../redux/CreateDiagniosticRcpSlice';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';

const { Option, OptGroup } = Select;
const { TextArea } = Input;


const Diagniostigue = ({ data, RcpId, UserId, PatientId, close }) => {
    // console.log(data);
    const [datePrelevement, setDatePrelevement] = useState(null);
    const [dateDiagnostic, setDateDiagnostic] = useState(null);
    const [TechniquePrelevement, setTechniquePrelevement] = useState('');
    const [EtatGeneralPatient, setEtatGeneralPatient] = useState([]);
    const [MoyenDiagnosticBCRABL, setMoyenDiagnosticBCRABL] = useState('');
    const [CytogenetiqueT, setCytogenetiqueT] = useState('');
    const [CytogenetiqueAca, setCytogenetiqueAca] = useState('');
    const [Sc, setSc] = useState('');
    const [IRMCP, setIRMCP] = useState('');
    const [IRMMP, setIRMMP] = useState('');
    const [IRMCPO, setIRMCPO] = useState('');
    const [IRMMPO, setIRMMPO] = useState('');
    const [LCR, setLCR] = useState('');
    const [ExamenAnatomopathlogique, setExamenAnatomopathlogique] = useState('');
    const [MargesResection, setMargesResection] = useState("");
    const [Medullogramme, setMedullogramme] = useState("");
    const [delaiRenduResultat, setDelaiRenduResultat] = useState(null);
    const [moyenDiagnosticCytologie, setMoyenDiagnosticCytologie] = useState([]);
    const [moyenDiagnosticCytologieResultats, setMoyenDiagnosticCytologieResultats] = useState([]);
    const [moyenDiagnosticCytogenetique, setMoyenDiagnosticCytogenetique] = useState([]);
    const [moyenDiagnosticCytogenetiqueResultats, setMoyenDiagnosticCytogenetiqueResultats] = useState([]);
    const [moyenDiagnosticImmunophénotypageSanguin, setMoyenDiagnosticImmunophénotypageSanguin] = useState('');
    const [moyenDiagnosticImmunophenotypageSanguinResultats, setMoyenDiagnosticImmunophenotypageSanguinResultats] = useState([]);
    const [moyenDiagnosticHistologie, setMoyenDiagnosticHistologie] = useState('');
    const [moyenDiagnosticHistologieResultats, setMoyenDiagnosticHistologieResultats] = useState([]);
    const [moyenDiagnosticImuunohistochimie, setMoyenDiagnosticImuunohistochimie] = useState('');
    const [moyenDiagnosticImuunohistochimieResultats, setMoyenDiagnosticImuunohistochimieResultats] = useState([]);
    const [moyenDiagnosticMedullogramme, setMoyenDiagnosticMedullogramme] = useState([]);
    const [moyenDiagnosticMedullogrammeResultats, setMoyenDiagnosticMedullogrammeResultats] = useState([]);
    const [moyenDiagnosticBiopsieOsteomedullaire, setMoyenDiagnosticBiopsieOsteomedullaire] = useState('');
    const [moyenDiagnosticBiopsieOsteomedullaireResultats, setMoyenDiagnosticBiopsieOsteomedullaireResultats] = useState([]);
    const [psOms, setPsOms] = useState('');
    const [poids, setPoids] = useState('');
    const [poidsAge, setPoidsAge] = useState('');
    const [poidsTaille, setPoidsTaille] = useState('');
    const [taille, setTaille] = useState('');
    const [tailleAge, setTailleAge] = useState('');
    const [media, setMedia] = useState('');
    const [siege, setSiege] = useState('');
    const [imcSc, setImcSc] = useState('');
    const [imcAge, setImcAge] = useState('');
    const [ALK, setALK] = useState('');
    const [CMET, setCMET] = useState('');
    const [ROSI, setROSI] = useState('');
    const [KRAS, setKRAS] = useState('');
    const [BRAF, setBRAF] = useState('');
    const [HER2, setHER2] = useState('')
    const [NTRK, setNTRK] = useState('');
    const [NRG1, setNRG1] = useState('');
    const [RET, setRET] = useState('');
    const [TA, setTA] = useState('');
    const [endoscopieDigestive, setEndoscopieDigestive] = useState('');
    const [bilanPreTherapeutique, setBilanPreTherapeutique] = useState([]);
    const [SignesPhysiques, setSignesPhysiques] = useState([]);
    const [SignesCliniques, setSignesCliniques] = useState([]);
    const [SignesParacliniques, setSignesParacliniques] = useState([]);
    const [examParaClinique, setExamParaClinique] = useState({
        date: null,
        type: '',
        resultat: ''

    });
    const [TypeHistologies, setTypeHistologies] = useState([]);
    const [MedulloblasmeSelect, setMedulloblasmeSelect] = useState([]);
    const [GradeHistopronostique, setGradeHistopronostique] = useState([]);
    const [biologieMoleculaire, setBiologieMoleculaire] = useState('');
    const [Tils, setTils] = useState('Reins');
    const [Immunohistochimie, setImmunohistochimie] = useState('');
    const [mutationGenetique, setMutationGenetique] = useState([]);
    const [MarqueursTumoraux, setMarqueursTumoraux] = useState([])
    const [FacteursPronostiques, setFacteursPronostiques] = useState([]);
    const [StadeClinique, setStadeClinique] = useState('');
    const [StadeAnnArbor, setStadeAnnArbor] = useState('');
    const [TypeCytologique, setTypeCytologique] = useState([]);
    const [cytogenetiqueConventionnelle, setCytogenetiqueConventionnelle] = useState('');
    const [FISH, setFISH] = useState('');
    const [CytogenetiqueDel17p, setCytogenetiqueDel17p] = useState([]);
    const [BiologieMoleculaireMutationTp53, setBiologieMoleculaireMutationTp53] = useState([]);
    const [ClassificationBinet, setClassificationBinet] = useState('');
    const [StadeSokal, setStadeSokal] = useState('');
    const [ScoreHasford, setScoreHasford] = useState('');
    const [ScoreEUTOS, setScoreEUTOS] = useState('');
    const [IMWG2014, setIMWG2014] = useState('');
    const [ClassificationSalmonDiurie, setClassificationSalmonDiurie] = useState([]);
    const [ClassificationSalmonDiurieStade, setClassificationSalmonDiurieStade] = useState([]);
    const [ClassificationISS, setClassificationISS] = useState([]);
    const [ClassificationAltmann, setClassificationAltmann] = useState([]);
    const [ClassificationISSStade, setClassificationISSStade] = useState([]);
    const [ClassificationISSRevisee, setClassificationISSRevisee] = useState([]);
    const [ClassificationISSReviseeStade, setClassificationISSReviseeStade] = useState([]);
    const [ClassificationOmsSyndromesMyelodysplasiques, setClassificationOmsSyndromesMyelodysplasiques] = useState([]);
    const [NéoplasmesCellulesLangerhansAutresCellulesDndritiques, setNéoplasmesCellulesLangerhansAutresCellulesDndritiques] = useState([]);
    const [NéoplasmesHistiocytesMacrophages, setNéoplasmesHistiocytesMacrophages] = useState([]);
    const [TypeCistologie, setTypeCistologie] = useState([]);
    const [AtteinteLCR, setAtteinteLCR] = useState('');
    const [AtteinteTesticulaire, setAtteinteTesticulaire] = useState('');
    const [Age, setAge] = useState(null);
    const [Phenotype, setPhenotype] = useState('');
    const [FacteurGenetique, setFacteurGenetique] = useState('');
    const [CorticoSensibilite, setCorticoSensibilite] = useState('');
    const [Malade, setMalade] = useState("");
    const [tumeur, setTumeur] = useState([]);
    const [adenopathie, setAdenopathie] = useState("");
    const [Métastase, setMétastase] = useState("");
    const [MoyenDiagnostic, setMoyenDiagnostic] = useState([]);
    const [facteurPronostique, setFacteurPronostique] = useState([]);
    const [immuno, setImmuno] = useState([]);
    const [EGFR, setEGFR] = useState('');
    const [pdl1mTor, setPdl1mTor] = useState('');
    const [AfficheChexbox, setAfficheChexbox] = useState(false);
    const [AfficheChexbox1, setAfficheChexbox1] = useState(false);
    const [AfficheChexbox2, setAfficheChexbox2] = useState(false);
    const [AfficheChexbox3, setAfficheChexbox3] = useState(false);
    const [AfficheChexbox4, setAfficheChexbox4] = useState(false);
    const [AfficheChexbox5, setAfficheChexbox5] = useState(false);
    const [AfficheChexbox6, setAfficheChexbox6] = useState(false);
    const [AfficheChexbox7, setAfficheChexbox7] = useState(false);
    const [AfficheChexbox8, setAfficheChexbox8] = useState(false);
    const [AfficheChexbox9, setAfficheChexbox9] = useState(false);
    const [AfficheChexbox10, setAfficheChexbox10] = useState(false)
    const [AfficheChexbox11, setAfficheChexbox11] = useState(false);
    const [AfficheChexbox12, setAfficheChexbox12] = useState(false);
    const [AfficheChexbox13, setAfficheChexbox13] = useState(false);
    const [AfficheChexbox14, setAfficheChexbox14] = useState(false);
    const [AfficheChexbox15, setAfficheChexbox15] = useState(false);
    const [AfficheChexbox16, setAfficheChexbox16] = useState(false);
    const [AfficheChexbox17, setAfficheChexbox17] = useState(false);
    const [AfficheChexbox18, setAfficheChexbox18] = useState(false);
    const [AfficheChexbox19, setAfficheChexbox19] = useState(false);
    const [AfficheChexbox20, setAfficheChexbox20] = useState(false);
    const [AfficheChexbox21, setAfficheChexbox21] = useState(false);
    const [AfficheChexbox22, setAfficheChexbox22] = useState(false);
    const [AfficheChexbox23, setAfficheChexbox23] = useState(false);
    const [AfficheChexbox24, setAfficheChexbox24] = useState(false);
    const [AfficheChexbox25, setAfficheChexbox25] = useState(false);
    const [AfficheChexbox26, setAfficheChexbox26] = useState(false);
    const [AfficheChexbox27, setAfficheChexbox27] = useState(false);
    const [AfficheChexbox28, setAfficheChexbox28] = useState(false);
    const [AfficheChexbox29, setAfficheChexbox29] = useState(false);
    const [AfficheChexbox30, setAfficheChexbox30] = useState(false);
    const [AfficheChexbox31, setAfficheChexbox31] = useState(false);
    const [AfficheChexbox32, setAfficheChexbox32] = useState(false);
    const [AfficheChexbox33, setAfficheChexbox33] = useState(false);
    const [CheckOui, setCheckOui] = useState(false);
    const [valueStade, setValueStade] = useState(false);
    const [bilanTherapeutique, setBilanTherapeutique] = useState([]);
    const [invasion, setInvasion] = useState([]);
    const [Envahissement, setEnvahissement] = useState([]);
    const [tailleTumoral, setTailleTumoral] = useState([]);
    const [ageJeune, setAgeJeune] = useState([]);
    const [taux, setTaux] = useState([]);
    const [sexe, setSexe] = useState([]);
    const [Hemoglobine, setHemoglobine] = useState([]);
    const [facteurMoleculaire, setfacteurMoleculaire] = useState([]);
    const [medulloblasme, setMedulloblasme] = useState([]);
    const [DesirFertiliteUlterieur, setDesirFertiliteUlterieur] = useState('');
    const [PreservationFertiliteProposer, setPreservationFertiliteProposer] = useState('');
    const [OncoSexualite, setOncoSexualite] = useState('');
    const [OncoGenetique, setOncoGenetique] = useState('');
    const [Indication, setIndication] = useState('');
    const [Proposition, setProposition] = useState('');
    const [ScorePronostic, setScorePronostic] = useState([]);

    const [CheckboxChoice, setCheckboxChoice] = useState([]);
    const [Risque, setRisque] = useState([]);
    const [TypeTesticule, setTypeTesticule] = useState([]);
    const [TumeursGerminaux, setTumeursGerminaux] = useState([]);
    const [CatecholaminesUrinaires, setCatecholaminesUrinaires] = useState([]);
    const [OsteosarcomeMR, setOsteosarcomeMR] = useState([]);
    const [OsteosarcomeSH, setOsteosarcomeSH] = useState([]);
    const [biologie, setBiologie] = useState([]);
    const [addBiologie, setAddBiolologie] = useState('');
    const [BilanEvaluationPatient, setBilanEvaluationPatient] = useState([]);
    const [BilanEvaluationMalade, setBilanEvaluationMalade] = useState([]);
    const [Glucocorticoïdes, setGlucocorticoïdes] = useState([]);
    const [Minéralocorticoïdes, setMinéralocorticoïdes] = useState([]);
    const [SteroideSexuel, setSteroideSexuel] = useState([]);
    const [Biopsie, setBiopsie] = useState([]);
    const [Testicule, setTesticule] = useState([]);
    const [ParaTesticule, setParaTesticule] = useState([]);
    const [Seminome, setSeminome] = useState([]);
    const [NonSeminome, setNonSeminome] = useState([]);
    const [TumeurCelluleSL, setTumeurCelluleSL] = useState([]);
    const [TumeurCelluleG, setTumeurCelluleG] = useState([]);
    const [Rhabdomyosarcome, setRhabdomyosarcome] = useState([]);
    const [Carcinome, setCarcinome] = useState([]);
    const [Dysgerminome, setDysgerminome] = useState('');
    const [Teratome, setTeratome] = useState('');
    const [Germinale, setGerminale] = useState('');
    const [Choriocarcinome, setChoriocarcinome] = useState('');
    const [TumeurSacVitellin, setTumeurSacVitellin] = useState('');
    const [CordonSexuel, setCordonSexuel] = useState('');
    const [KraBrafPik, setKraBrafPik] = useState([]);
    const [Fox, setFox] = useState('');
    const [PcinquanteTrois, setPcinquanteTrois] = useState([]);
    const [Adenok, setAdenok] = useState('');
    const [ConsultationOncogetique, setConsultationOncogetique] = useState([]);
    const [ResultatFacteur, setResultatFacteur] = useState('');
    const [metanephrinesPlasma, setMetanephrinesPlasma] = useState('');
    const [normetanephrinesPlasma, setNormetanephrinesPlasma] = useState('');
    const [metanephrinesUrine, setMetanephrinesUrine] = useState('');
    const [normetanephrinesUrine, setNormetanephrinesUrine] = useState('');
    const [creatininurie24h, setCreatininurie24h] = useState('');
    const [chromogranineA, setChromogranineA] = useState('');
    const [tauxPotassium, setTauxPotassium] = useState('');
    const [glycemie, setGlycemie] = useState('');



    const handleDateChangeExamClinique = (date, dateString) => {
        setExamParaClinique({ ...examParaClinique, date: dateString });
    };


    const handleChangeExamParaClinique = (value) => {
        setExamParaClinique({ ...examParaClinique, type: value });
    };

    const handleChangeResultatExamParaClinique = (e) => {
        setExamParaClinique({ ...examParaClinique, resultat: e.target.value });
    };

    const handleAdd = () => {
        setSignesParacliniques([...SignesParacliniques, examParaClinique]);
        setExamParaClinique({ date: null, type: '', resultat: '' });
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBiologie((prevBiologie) =>
            prevBiologie.map((item) =>
                item.name === name ? { ...item, value } : item
            )
        );
    };

    // Fonction de gestion du changement de niveau de risque
    const handleRiskChange = (checkedValues) => {
        setCheckboxChoice(checkedValues); // Stocke les checkboxes sélectionnées
        setRisque([]); // Réinitialise les stades
        setRisque(''); // Réinitialise Risque
    };

    // Fonction de gestion du changement des stades
    const handleRisqueChange = (selectedRisque) => {
        setRisque(selectedRisque); // Met à jour les stades sélectionnés

        // Mise à jour de Risque avec les checkboxes sélectionnées et les stades choisis
        if (selectedRisque.length > 0) {
            setRisque(`${CheckboxChoice.join(', ')}: ${selectedRisque.join(', ')}`);
        } else {
            setRisque(CheckboxChoice.join(', ')); // Si aucun stade n'est sélectionné, Risque est juste les checkboxes choisies
        }
    };

    // Fonction pour réinitialiser tous les états
    const resetStates = () => {
        setDatePrelevement(null);
        setDateDiagnostic(null);
        setTechniquePrelevement('');
        setEtatGeneralPatient([]);
        setMoyenDiagnosticBCRABL('');
        setCytogenetiqueT('');
        setCytogenetiqueAca('');
        setSc('');
        setIRMCP('');
        setIRMMP('');
        setIRMCPO('');
        setIRMMPO('');
        setLCR('');
        setExamenAnatomopathlogique('');
        setMargesResection("");
        setMedullogramme("");
        setDelaiRenduResultat(null);
        setMoyenDiagnosticCytologie([]);
        setMoyenDiagnosticCytologieResultats([]);
        setMoyenDiagnosticImmunophénotypageSanguin('');
        setMoyenDiagnosticImmunophenotypageSanguinResultats([]);
        setMoyenDiagnosticHistologie('');
        setMoyenDiagnosticHistologieResultats([]);
        setMoyenDiagnosticImuunohistochimie('');
        setMoyenDiagnosticImuunohistochimieResultats([]);
        setMoyenDiagnosticMedullogramme([]);
        setMoyenDiagnosticMedullogrammeResultats([]);
        setMoyenDiagnosticBiopsieOsteomedullaire('');
        setMoyenDiagnosticBiopsieOsteomedullaireResultats([]);
        setPsOms('');
        setPoids('');
        setPoidsAge('');
        setPoidsTaille('');
        setTaille('');
        setTailleAge('');
        setMedia('');
        setBiologie([]);
        setOncoGenetique('');
        setConsultationOncogetique([]);
        setSiege('');
        setImcSc('');
        setImcAge('');
        setALK('');
        setCMET('');
        setROSI('');
        setKRAS('');
        setBRAF('');
        setHER2('');
        setNTRK('');
        setNRG1('');
        setRET('');
        setTA('');
        setEndoscopieDigestive('');
        setBilanPreTherapeutique([]);
        setSignesPhysiques([]);
        setSignesCliniques([]);
        setSignesParacliniques([]);
        setTypeHistologies([]);
        setMedulloblasmeSelect([]);
        setGradeHistopronostique([]);
        setBiologieMoleculaire([]);
        setTils('');
        setBilanEvaluationMalade([]);
        setBilanEvaluationPatient([]);
        setImmunohistochimie([]);
        setMutationGenetique([]);
        setMarqueursTumoraux([]);
        setFacteursPronostiques([]);
        setStadeClinique([]);
        setStadeAnnArbor([]);
        setTypeCytologique([]);
        setCytogenetiqueConventionnelle('');
        setFISH('');
        setCytogenetiqueDel17p([]);
        setBiologieMoleculaireMutationTp53([]);
        setClassificationBinet([]);
        setStadeSokal([]);
        setScoreHasford([]);
        setScoreEUTOS([]);
        setIMWG2014([]);
        setClassificationSalmonDiurie([]);
        setClassificationSalmonDiurieStade([]);
        setClassificationISS([]);
        setClassificationAltmann([]);
        setClassificationISSStade([]);
        setClassificationISSRevisee([]);
        setClassificationISSReviseeStade([]);
        setClassificationOmsSyndromesMyelodysplasiques([]);
        setNéoplasmesCellulesLangerhansAutresCellulesDndritiques([]);
        setNéoplasmesHistiocytesMacrophages([]);
        setRisque('');
        setTypeCistologie([]);
        setAtteinteLCR('');
        setAtteinteTesticulaire('');
        setAge('');
        setPhenotype('');
        setFacteurGenetique('');
        setCorticoSensibilite('');
        setMalade([]);
        setTumeur([]);
        setAdenopathie([]);
        setMétastase([]);
        setMoyenDiagnostic([]);
        setFacteurPronostique('');
        setImmuno('');
        setEGFR('');
        setImmuno([]);
        setScorePronostic([]);
    };

    useEffect(() => {
        // Appeler la fonction de réinitialisation lorsque le composant se monte
        resetStates();
    }, []); // Si vous souhaitez que cela se produise à chaque fois que le composant se monte, laissez le tableau de dépendances vide


    useEffect(() => {
        if (data?.Organe) {
            if (data.Specialite === 'Urologie' && data.Organe === 'Testicule') {
                setFacteurPronostique(`${data?.Organe} N+`);
            } else if (data.Specialite === 'Urologie' && data.Organe === 'Prostate') {
                setFacteurPronostique(`${data?.Organe} D’AMICO`);
            } else if (data.Specialite === 'Urologie' && data.Organe === 'Reins') {
                setFacteurPronostique(``);
            } else if (data.Specialite === 'ORL' && data.Organe === 'Thyroide') {
                setFacteurPronostique(``);
            } else if (data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx')) {
                setFacteurPronostique(``);
            }
            else if (data.Specialite === 'Pédiatrie') {
                setFacteurPronostique(``);
            }
            else if (data.Specialite === 'Cancers Rares') {
                setFacteurPronostique(``);
            }
            else if (data.Specialite === 'Hématologie' && data.Organe === 'Myélome multiple') {
                setFacteurPronostique(``);
            } else {
                setFacteurPronostique(data?.Organe);
            }
        }
    }, [data?.Organe]);

    // mettre à jour dynamiquement les champs diagnostiques
    const updateMoyensDiagnostiques = (key, value) => {
        setMoyenDiagnostic((prev) => ({
            ...prev,
            [key]: value,
        }));
    };



    // mettre à jour dynamiquement les champs bilan
    const updateBilanPreTherapeutique = (key, value) => {
        setBilanTherapeutique((prev) => ({
            ...prev,
            [key]: value,
        }));
    };


    // mettre à jour dynamiquement les champs cytogenetique
    const Cytogenetique = (key, value) => {
        setCytogenetiqueDel17p((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleFacteurChange = (value) => {
        setFacteursPronostiques(value);
        setAfficheChexbox27(value.includes('CRP'));
        setAfficheChexbox28(value.includes('Anomalies cytogénétiques défavorable'));


    };

    // Fonction pour changer l'état d'un facteur
    const toggleFacteurStatus = (key, value) => {
        setFacteurPronostique((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const Biologie = (key, value) => {
        setAddBiolologie((prev) => ({
            ...prev,
            [key]: value,
        }));
    };


    // mettre à jour dynamiquement les champs Medulloblasme
    const Medulloblasme = (key, value) => {
        setMedulloblasmeSelect((prev) => ({
            ...prev,
            [key]: value,
        }));
    };
    // mettre à jour dynamiquement les champs mutation
    const Mutation = (key, value) => {
        setBiologieMoleculaireMutationTp53((prev) => ({
            ...prev,
            [key]: value,
        }));

    };
    //paraclinique

    const handleCheckboxSigneParaclinque = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSignesParacliniques((prev) => [...prev, value]);
        } else {
            setSignesParacliniques((prev) => prev.filter((item) => item !== value));
        }
    };

    const optionTypeTesticles = [
        { value: "Germinale", label: "Germinale" },
        { value: "Cordons sexuels", label: "Cordons sexuels" },

    ]

    const handleChangeType = (value) => {
        setTypeTesticule(value);
        if (data.Specialite === 'Pédiatrie' && data.Organe === 'Testicule/para-testicule' && (value === 'Germinale')) {
            setAfficheChexbox25(true);
            setAfficheChexbox26(false);

        }
        else if (data.Specialite === 'Pédiatrie' && data.Organe === 'Testicule/para-testicule' && (value === 'Cordons sexuels')) {
            setAfficheChexbox26(true);
            setAfficheChexbox25(false);


        } else {
            setAfficheChexbox25(false);
            setAfficheChexbox26(false);
        }


    };

    const handleChangeBiomoleculaire = (value) => {
        setBiologieMoleculaire(value);
        if (data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique') {
            setAfficheChexbox8(true);
        } else {
            setAfficheChexbox8(false);
        };
        if ((data.Specialite === 'Thorax' && data.Organe === 'Poumon') && (value === 'patient naïf da chimiotérapie')) {
            setAfficheChexbox13(true);
        } else {
            setAfficheChexbox13(false);
        };

        if ((data.Specialite === 'Thorax' && data.Organe === 'Poumon') && (value === 'patient ayant reçu une chimiotérapie')) {
            setAfficheChexbox14(true);
        } else {
            setAfficheChexbox14(false);
        }

        if ((data.Specialite === 'Cancers Rares' && (data.Organe === 'Medullosurrenale' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Ganglion parasympathique')) && (value === 'IHC')) {
            setAfficheChexbox32(true);
        } else {
            setAfficheChexbox32(false);
        };

        if ((data.Specialite === 'Cancers Rares' && (data.Organe === 'Parathyroïde')) && (value === 'IHC')) {
            setAfficheChexbox33(true);
        } else {
            setAfficheChexbox33(false);
        };
    };


    const handleFacteurProsnostique = (value) => {
        setFacteurPronostique(value);
        if ((data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire')) && (value === 'Surexpression EGF')) {
            setAfficheChexbox15(true);
        } else {
            setAfficheChexbox15(false);
        };

        if ((data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire')) && (value === 'HPV +')) {
            setAfficheChexbox16(true);
        } else {
            setAfficheChexbox16(false);
        }

        if ((data.Specialite === 'ORL' && (data.Organe === 'Thyroide')) && (value === 'Caractéristiques en rapport avec le patient')) {
            setAfficheChexbox17(true);
        } else {
            setAfficheChexbox17(false);
        };

        if ((data.Specialite === 'ORL' && (data.Organe === 'Thyroide')) && (value === 'Caractéristiques en rapport avec la tumeur')) {
            setAfficheChexbox18(true);
        } else {
            setAfficheChexbox18(false);
        };

        if ((data.Specialite === 'ORL' && (data.Organe === 'Thyroide')) && (value === 'Classification ATA 2015 du risque de rechute')) {
            setAfficheChexbox19(true);
        } else {
            setAfficheChexbox19(false);
        }
    }
    // Fonction de gestion du changement de sélection
    const handleSelectChange = (value) => {
        setMoyenDiagnostic(value);

        // Vérifier si un moyen diagnostique a été sélectionné pour afficher les checkboxes
        if (data.Specialite === 'Hématologie' && data.Organe !== 'Leucémie Lymphoïde Chronique') {
            setAfficheChexbox(value.length > 0);
        }
        if (data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique') {
            setAfficheChexbox6(true);
        } else {
            setAfficheChexbox6(false);
            setAfficheChexbox(false);
        };


        if (data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne') {
            setAfficheChexbox20(value.includes('Biologie'));
            setAfficheChexbox21(value.includes('Chirurgie'));
        } else {
            setAfficheChexbox20(false);
            setAfficheChexbox21(false);
        }
        if (data.Specialite === 'Hématologie' && (data.Organe === 'Syndromes myélodysplasiques' || data.Organe === 'Syndrome Myéloprolifératif Ph1 négative')) {
            setAfficheChexbox29(value.includes('Médullogramme'));
            setAfficheChexbox30(value.includes('Cytogénétique'));
            setAfficheChexbox31(value.includes('Biopsie Ostéomédullaire'));
        } else {
            setAfficheChexbox29(false);
            setAfficheChexbox30(false);
            setAfficheChexbox31(false);
        }

    };


    const handleStadePronosticChange = (value) => {
        setTumeur(value);

        if (data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte' && (value === 'LAM OMS 2022')) {
            setAfficheChexbox4(true);
        } else {
            setAfficheChexbox4(false);
        };
        if (data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte' && (value === 'LAL')) {
            setAfficheChexbox5(true);
        } else {
            setAfficheChexbox5(false);
        }
    };

    const handleChangeCystogénétique = (value) => {
        setTypeHistologies(value);
        if (value) {
            setAfficheChexbox7(true);
        }
    };

    const handleChangeTypeHistologies = (value) => {
        setTypeHistologies(value);
        if (data.Specialite === 'Pédiatrie' && data.Organe === 'prostate' && value === 'Lymphome à cellules du manteau') {
        };

        // Condition pour Lymphome folliculaire
        if (data.Specialite === 'Hématologie' && data.Organe === 'Lymphome non hodgkinien adulte' && value === 'Lymphome folliculaire') {
            setAfficheChexbox9(true);
            setAfficheChexbox10(true);
            setAfficheChexbox11(false);
            setAfficheChexbox12(false);
        }
        // Condition pour Lymphome à cellules du manteau
        else if (data.Specialite === 'Hématologie' && data.Organe === 'Lymphome non hodgkinien adulte' && value === 'Lymphome à cellules du manteau') {
            setAfficheChexbox9(false);
            setAfficheChexbox10(false);
            setAfficheChexbox11(true);
            setAfficheChexbox12(false);
        }
        // Si aucun des types spécifiques n'est sélectionné, cacher tous les checkboxes
        else {
            setAfficheChexbox9(false);
            setAfficheChexbox10(false);
            setAfficheChexbox11(false);
            setAfficheChexbox12(true);
        }
    };

console.log(SignesParacliniques);

    const handleSigneParaClinique = (value) => {
        setSignesParacliniques(value);
      
        if (data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale' && value.includes('GlucoC')) {
            setAfficheChexbox22(true);
        } else {
            setAfficheChexbox22(false);
        }
        if (data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale' && value.includes('minéralloC')) {
            setAfficheChexbox23(true);
        } else {
            setAfficheChexbox3(false);
        }
        if (data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale' && value.includes('Stéroides')) {
            setAfficheChexbox24(true);
        } else {
            setAfficheChexbox24(false);
        }
    };



    const handleStadeChange = (value) => {
        setTumeur(value);
        // console.log(value)
        // Vérifie si 'X' ou 'E' sont présents dans le tableau `value`
        if (
            data.Specialite === 'Hématologie' &&
            data.Organe === 'Lymphome Hodgkin adulte' &&
            (value.includes('X') || value.includes('E'))
        ) {
            setAfficheChexbox1(true);
        } else {
            setAfficheChexbox1(false);
        }

        // Vérifie si 'Stade I' ou 'Stade II' sont présents dans le tableau `value`
        if (
            data.Specialite === 'Hématologie' &&
            data.Organe === 'Lymphome Hodgkin adulte' &&
            (value.includes('Stade I') || value.includes('Stade II'))
        ) {
            setAfficheChexbox2(true);
        } else {
            setAfficheChexbox2(false);
        }

        // Vérifie si 'Stade III' ou 'Stade IV' sont présents dans le tableau `value`
        if (
            data.Specialite === 'Hématologie' &&
            data.Organe === 'Lymphome Hodgkin adulte' &&
            (value.includes('Stade III') || value.includes('Stade IV'))
        ) {
            setAfficheChexbox3(true);
        } else {
            setAfficheChexbox3(false);
        }
    };


    const handleStadeChange1 = (value) => {
        setTumeur(value);
        console.log(value)
        // Vérifie si 'X' ou 'E' sont présents dans le tableau `value`
        if (
            data.Specialite === 'Hématologie' &&
            data.Organe === 'Lymphome non hodgkinien adulte' &&
            (value.includes('X') || value.includes('E'))
        ) {
            setAfficheChexbox1(true);
        } else {
            setAfficheChexbox1(false);
        }


    };



    // Fonction de gestion du changement de checkbox "Oui"
    const handleCheckboxChange = (e) => {
        setCheckOui(e.target.checked);
        setValueStade(e.target.value)
        const { value, checked } = e.target;
        if (checked) {
            setScorePronostic((prev) => [...prev, value]);
        } else {
            setScorePronostic((prev) => prev.filter((item) => item !== value));
        }
    };
    const handleCheckboxChange1 = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setScorePronostic((prev) => [...prev, value]);
        } else {
            setScorePronostic((prev) => prev.filter((item) => item !== value));
        }
    };
    const handleScorePronosticChange = (scoreType, value) => (e) => {
        const checked = e.target.checked;

        // Met à jour les scores pronostiques en fonction de la case cochée ou décochée
        setScorePronostic((prevScores) => {
            const updatedScores = { ...prevScores };

            if (checked) {
                updatedScores[scoreType] = value; // Ajoute la valeur si cochée
            } else {
                delete updatedScores[scoreType]; // Supprime la valeur si décochée
            }

            return updatedScores;
        });
    };

    const optionTumeursGerminaux = [
        { value: "Bon : Testicule primaire sans métastases viscérales non pulmonaires et bons marqueurs : AFP < 1000 ng/mL et hCG < 5000 IU/L et LDH < 1,5 × LSN (Limite Supérieure de Normalité)", label: "Bon : Testicule primaire sans métastases viscérales non pulmonaires et bons marqueurs : AFP < 1000 ng/mL et hCG < 5000 IU/L et LDH < 1,5 × LSN (Limite Supérieure de Normalité)" },
        { value: "Intermédiaire : Testicule primaire sans métastases viscérales non pulmonaires et marqueurs intermédiaires : AFP ≥ 1000 ng/mL et ≤ 10 000 ng/mL ou hCG ≥ 5000 IU/L et ≤ 50 000 IU/L ou LDH ≥ 1,5 × LSN et ≤ 10 × LSN", label: "Intermédiaire : Testicule primaire sans métastases viscérales non pulmonaires et marqueurs intermédiaires : AFP ≥ 1000 ng/mL et ≤ 10 000 ng/mL ou hCG ≥ 5000 IU/L et ≤ 50 000 IU/L ou LDH ≥ 1,5 × LSN et ≤ 10 × LSN" },
        { value: "Mauvais : Primaire médiastinal ou métastases viscérales non pulmonaires ou marqueurs mauvais : AFP > 10 000 ng/mL ou hCG > 50 000 IU/L ou LDH > 10 × LSN", label: "Mauvais : Primaire médiastinal ou métastases viscérales non pulmonaires ou marqueurs mauvais : AFP > 10 000 ng/mL ou hCG > 50 000 IU/L ou LDH > 10 × LSN" },

    ];

    const optionCatecholaminesUrinaires = [
        { value: "HVA", label: "HVA" },
        { value: "VMA", label: "VMA" },
        { value: "Dopamine", label: "Dopamine" },

    ];

    const optionOsteosarcomeSH = [
        { value: "Grade I : >50 % de cellules tumorales identifiables", label: "Grade I : >50 % de cellules tumorales identifiables" },
        { value: "Grade II : 5 % < cellules tumorales identifiables ≤ 50 %", label: "Grade II : 5 % < cellules tumorales identifiables ≤ 50 %" },
        { value: "Grade III : ≤ 5 % de cellules viables ou quelques cellules tumorales résiduelles disséminées sur toute la tranche de section", label: "Grade III : ≤ 5 % de cellules viables ou quelques cellules tumorales résiduelles disséminées sur toute la tranche de section" },
        { value: "Grade IV : aucune cellule viable (absence de cellule tumorale identifiable).", label: "Grade IV : aucune cellule viable (absence de cellule tumorale identifiable)." },
        { value: "Patient bon répondeur : <10 % de cellules tumorales viables (grade III, IV et grade II <10 %)", label: "Patient bon répondeur : <10 % de cellules tumorales viables (grade III, IV et grade II <10 %)" },
        { value: "patient mauvais répondeur : ≥ 10 % de cellules tumorales viables (grade I et grade II ≥ 10%).", label: "patient mauvais répondeur : ≥ 10 % de cellules tumorales viables (grade I et grade II ≥ 10%)." },

    ];

    const optionOsteosarcomeMR = [

        { value: "R0 : la section chirurgicale passe à distance de la tumeur en laissant une marge plus ou moins épaisse de tissu sain.", label: "R0 : la section chirurgicale passe à distance de la tumeur en laissant une marge plus ou moins épaisse de tissu sain." },
        { value: "R1 : la section chirurgicale passe au ras de la tumeur. Il n'y a pas de marge de tissu sain entre l'extension microscopique du cancer (radiaire ou distal) et la section chirurgicale", label: "R1 : la section chirurgicale passe au ras de la tumeur. Il n'y a pas de marge de tissu sain entre l'extension microscopique du cancer (radiaire ou distal) et la section chirurgicale" },
        { value: "R2 : la résection macroscopique est incomplète", label: "R2 : la résection macroscopique est incomplète" },


    ];



    const optionTypeHistologies = [
        { value: "par cancer", label: "par cancer" },
        ...(data.Specialite === 'Hématologie' && data.Organe === 'Lymphome Hodgkin adulte' ? [
            { value: "Lymphome de Hodgkin classique", label: "Lymphome de Hodgkin classique" },
            { value: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire", label: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire" }

        ] : []),


        ...(data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique' ? [
            { value: "Del17p", label: "Del17p" }

        ] : []),
        ...(data.Specialite === 'Urologie' && (data.Organe === 'Prostate' || data.Organe === 'Testicule' || data.Organe === 'Vessie/Arbre urinaire' || data.Organe === 'Penis' || data.Organe === 'Surrenale' || data.Organe === 'Reins') ? [
            { value: "codage OMS", label: "codage OMS" }

        ] : []),

        ...(data.Specialite === 'Hématologie' && data.Organe === 'Lymphome non hodgkinien adulte' ? [
            { value: "Lymphome de Hodgkin classique", label: "Lymphome de Hodgkin classique" },
            { value: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire", label: "Lymphome de Hodgkin nodulaire à prédominance lymphocytaire" },
            { value: "Lymphome de Burkitt", label: "Lymphome de Burkitt" },
            { value: "Lymphome B diffus à grandes cellules type GC", label: "Lymphome B diffus à grandes cellules type GC" },
            { value: "Lymphome B diffus à grandes cellules type non GC", label: "Lymphome B diffus à grandes cellules type non GC" },
            { value: "Lymphome primitif du médiastin", label: "Lymphome primitif du médiastin" },
            { value: "Lymphome anaplasique", label: "Lymphome anaplasique" },
            { value: "Leucémie/lymphome lymphoblastique B", label: "Leucémie/lymphome lymphoblastique B" },
            { value: "Leucémie/lymphome lymphoblastique T", label: "Leucémie/lymphome lymphoblastique T" },
            { value: "Lymphome plasmablastique", label: "Lymphome plasmablastique" },
            { value: "Lymphome folliculaire", label: "Lymphome folliculaire" },
            { value: "Leucémie à tricholeucocytes", label: "Leucémie à tricholeucocytes" },
            { value: "Lymphome de la zone marginale de la rate", label: "Lymphome de la zone marginale de la rate" },
            { value: "Lymphome lymphoplasmocytaire", label: "Lymphome lymphoplasmocytaire" },
            { value: "Lymphome de la zone marginale", label: "Lymphome de la zone marginale" },
            { value: "Lymphome à cellules du manteau", label: "Lymphome à cellules du manteau" },
            { value: "Lymphome T NOS", label: "Lymphome T NOS" },
            { value: "Lymphome T angio-immunoblastique", label: "Lymphome T angio-immunoblastique" },
            { value: "Leucémie/Lymphome T de l’adulte", label: "Leucémie/Lymphome T de l’adulte" },

        ] : []),
        ...(data.Specialite === 'Thorax' && data.Organe === 'Médiastin' ? [
            { value: "Tératomes bénins thymiques", label: "Tératomes bénins thymiques" },
            { value: "Thymomes malins", label: "Thymomes malins" },
            { value: "Lymphomes de hodgkin", label: "Lymphomes de hodgkin" },
            { value: "Lymphomes malins non hodgkiniens", label: "Lymphomes malins non hodgkiniens" },
            { value: "Tumeurs nerveuses", label: "Tumeurs nerveuses" },
            { value: "Tumeurs germinales séminomateuses", label: "Tumeurs germinales séminomateuses" },
            { value: "Tumeurs germinales non séminomateuses", label: "Tumeurs germinales non séminomateuses" },

        ] : []),
        ...(data.Specialite === 'Thorax' && data.Organe === 'Parois thoracique' ? [
            { value: "Sarcome d’Ewing", label: "Sarcome d’Ewing" },
            { value: "Tumeur d’Askin", label: "Tumeur d’Askin" },
            { value: "Tumeur desmoïde", label: "Tumeur desmoïde" },
            { value: "Chondrosarcome myxoïde", label: "Chondrosarcome myxoïde" },
            { value: "Leimyosarcome", label: "Leimyosarcome" },
            { value: "Lymphome pariétal anaplasique", label: "Lymphome pariétal anaplasique" },
            { value: "Autres tumeurs pariétales", label: "Autres tumeurs pariétales" },

        ] : []),
        ...(data.Specialite === 'Thorax' && data.Organe === 'Poumon' ? [
            { value: "Classification anatomo-pathologie des cancers du poumon 2015", label: "Classification anatomo-pathologie des cancers du poumon 2015" },

        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? [
            { value: "par cancer", label: "par cancer" },

        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Thyroide') ? [
            { value: "carcinome papillaire/vésiculaire", label: "carcinome papillaire/vésiculaire" },
            { value: "carcinome médullaire", label: "carcinome médullaire" },
            { value: "carcinome anaplasique", label: "carcinome anaplasique" },

        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs cerebrales') ? [
            { value: "5e édition de la classification OMS des tumeurs cérébrales", label: "5e édition de la classification OMS des tumeurs cérébrales" },


        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Rétropéritoine') ? [
            { value: "Tératome", label: "Tératome" },
            { value: "Tumeur vitelline", label: "Tumeur vitelline" },
            { value: "Paragangliome parasympathique", label: "Paragangliome parasympathique" },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Os') ? [
            { value: "Classification OMS tumeurs osseuses", label: "Classification OMS tumeurs osseuses" },


        ] : []),
        ...(data.Specialite === 'Cancers Rares' ? [
            { value: "codage OMS", label: "codage OMS" },

        ] : []),


    ];
    const optionMalade = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Anus' ? [
            { value: "HIV", label: "HIV" }
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "albuminémie", label: "albuminémie" }
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Oesophage' ? [
            { value: "EFR", label: "EFR" },
            { value: "albuminémie", label: "albuminémie" }

        ] : []),

    ];

    const optionMoyenDiagnostic = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Anus' ? [
            { value: "COLOSCOPIE", label: "COLOSCOPIE" },
            { value: "HISTOLOGIE", label: "HISTOLOGIE" }
        ] : []),

        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "coloscopie totale", label: "coloscopie totale" },
            { value: "Histologie", label: "Histologie" }
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Voies biliaires' ? [
            { value: "TUMEUR BILIAIRE (TDM, IRM)", label: "TUMEUR BILIAIRE (TDM, IRM)" },
            { value: "HISTOLOGIE (BIOPSIE, BROSSAGE)", label: "HISTOLOGIE (BIOPSIE, BROSSAGE)" }
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Pancreas' ? [
            { value: 'TUMEUR RESECABLE (TDM, IRM)', label: "TUMEUR RESECABLE (TDM, IRM" },
            { value: "BIOPSIE (Checkboxlogie, EchoEndoscopie)", label: "BIOPSIE (Checkboxlogie, EchoEndoscopie)" }
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Estomac' ? [
            { value: 'FOGD', label: "FOGD" },
            { value: "Histologie", label: "Histologie" }
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Foie' ? [
            { value: 'Imagerie typique (TDM, IRM)', label: "Imagerie typique (TDM, IRM)" },
            { value: "PBH", label: "PBH" }
        ] : []),

        ...(data.Specialite === 'Digestif' && data.Organe === 'Oesophage' ? [
            { value: "FOGD", label: "FOGD" },
            { value: "Histologie", label: "Histologie" }
        ] : []),

        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Néoplasies trophoblastiques gestationnelles' ? [
            { value: 'imagerie', label: 'imagerie' },
            { value: 'biologie (Dosage beta HCGplasmatique /Histologie)', label: 'biologie (Dosage beta HCGplasmatique /Histologie)' },
        ] : []),
        ...(data.Specialite === 'Gynécologie' && (data.Organe === 'Ovaire' || data.Organe === 'Col' || data.Organe === 'Utérus' || data.Organe === 'Vagin' || data.Organe === 'Vulve') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'biologie(CA 12-5,alpha-foetoprotéine)', label: 'biologie(CA125,alpha-foetoprotéine)' },
        ] : []),
        ...(data.Specialite === 'Gynécologie' && (data.Organe === 'Sein') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'biologie(CA 15-3,alpha-foetoprotéine)', label: 'biologie(CA125,alpha-foetoprotéine)' },
            { value: 'galactographie', label: 'galactographie' },

        ] : []),

        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' || data.Organe === 'Lymphome non hodgkinien adulte') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'Imuunohistochimie', label: 'Imuunohistochimie' },
            { value: 'Immunophénotypage sanguin', label: 'Immunophénotypage sanguin' },
        ] : []),

        ...((data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte') || (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques')) ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'Imuunohistochimie', label: 'Imuunohistochimie' },
        ] : []),

        ...(data.Specialite === 'Hématologie' && (data.Organe === 'LA Adulte') ? [
            { value: 'Médullogramme', label: 'Médullogramme' },
            { value: 'Immunophénotypage', label: 'Immunophénotypage' },
        ] : []),
        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Leucémie Lymphoïde Chronique') ? [
            { value: 'Immunophénotypage sanguin', label: 'Immunophénotypage sanguin' },
        ] : []),
        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Myélome multiple') ? [
            { value: 'Médullogramme', label: 'Médullogramme' },
            { value: 'Immunophénotypage', label: 'Immunophénotypage' },
            { value: 'histologie', label: 'histologie' },
            { value: 'Imuunohistochimie', label: 'Imuunohistochimie' },
        ] : []),
        ...(data.Specialite === 'Urologie' && (data.Organe === 'Prostate') ? [
            { value: 'Biopsie', label: 'Biopsie' },
            { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
            { value: 'Chirurgie', label: 'Chirurgie' },
        ] : []),
        ...(data.Specialite === 'Urologie' && (data.Organe === 'Testicule' || data.Organe === 'Vessie /Arbre urinaire' || data.Organe === 'Penis' || data.Organe === 'Surrenale') ? [
            { value: 'Biopsie', label: 'Biopsie' },
            { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
            { value: 'Chirurgie', label: 'Chirurgie' },
        ] : []),

        ...(data.Specialite === 'Urologie' && (data.Organe === 'Reins') ? [
            { value: 'Biopsie', label: 'Biopsie' },
            { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
            { value: 'Chirurgie', label: 'Chirurgie' },
            { value: 'Imagerie', label: 'Imagerie' },
        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Thyroide' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire' || data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'biologie', label: 'biologie' },
        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs cerebrales') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'Marqueurs tumoraux LCR et/ou sanguins', label: 'Marqueurs tumoraux LCR et/ou sanguins' },
        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs orbitraire' || data.Organe === 'Tumeurs médiastinales') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'Medullogramme', label: 'Medullogramme' },
            { value: 'immunohistochimie', label: 'immunohistochimie' },
            { value: 'Catécholamines urinaires', label: 'Catécholamines urinaires' },
        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs maxillo faciales' || data.Organe === 'Foie/Pédiatrie') ? [
            { value: 'cytologie', label: 'cytologie' },
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'Medullogramme', label: 'Medullogramme' },
            { value: 'immunohistochimie', label: 'immunohistochimie' },
        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'T. Sacro-coccygienne') ? [
            { value: 'Biopsie', label: 'Biopsie' },
            { value: 'Chirurgie', label: 'Chirurgie' },
        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Corticosurrenale') ? [
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'Medullogramme', label: 'Medullogramme' },
            { value: 'Catécholamines urinaires', label: 'Catécholamines urinaires' },


        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Ovaire' || data.Organe === 'Voie excretrice' || data.Organe === 'Prostate' || data.Organe === 'Vessie' || data.Organe === 'Testicule/para-testicule' || data.Organe === 'Os') ? [
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'cytologie', label: 'cytologie' },
            { value: 'biologie', label: 'biologie' },


        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Rétropéritoine') ? [
            { value: 'histologie', label: 'histologie' },
            { value: 'imagerie', label: 'imagerie' },
            { value: 'cytologie', label: 'cytologie' },
            { value: 'biologie', label: 'biologie' },
        ] : []),


        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Oesophage') ? [
            { value: 'Biopsie endoscopique', label: 'Biopsie endoscopique' },
            { value: 'Immunophénotypage', label: 'Immunophénotypage' },
        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Colo-rectum') ? [
            { value: 'Biopsie/Colonoscopie', label: 'Biopsie/Colonoscopie' },
            { value: 'pièce opératoire/urgence', label: 'pièce opératoire/ urgence' },
        ] : []),

        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Syndromes myélodysplasiques') ? [
            { value: 'Médullogramme', label: 'Médullogramme' },
            { value: 'Cytogénétique', label: 'Cytogénétique' },
        ] : []),
        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Syndrome Myéloprolifératif Ph1 négative') ? [
            { value: 'Médullogramme', label: 'Médullogramme' },
            { value: 'Cytogénétique', label: 'Cytogénétique' },
            { value: 'Biopsie Ostéomédullaire', label: 'Biopsie Ostéomédullaire' },
        ] : []),

        ...(data.Specialite === 'Cancers Rares' ? [
            { value: 'Biopsie', label: 'Biopsie' },
            { value: 'Biopsie guidée par l’imagerie', label: 'Biopsie guidée par l’imagerie' },
            { value: 'Chirurgie', label: 'Chirurgie' },

        ] : []),




    ];


    const optionMarqueursTumoraux = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Anus' ? [
            { value: "P16", label: "P16" },
            { value: "SCC", label: "SCC" },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Voies biliaires' ? [
            { value: "ACE", label: "ACE" },
            { value: "CA 19 9", label: "CA 19 9" },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "ACE", label: "ACE" },
        ] : []),

        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine' ? [
            { value: "chromogranine A", label: "chromogranine A" },
            { value: "5-HIAA", label: "5-HIAA" },
            { value: "Echo-doppler", label: "Echo-doppler" },
            { value: "TDM", label: "TDM" },
            { value: "IRM", label: "IRM" },
            { value: "Scintigraphie (MIBG, Tc99)", label: "Scintigraphie (MIBG, Tc99)" },
            { value: "FDG", label: "FDG" },
            { value: "PET SCAN", label: "PET SCAN" },
        ] : []),



    ];


    const optionBiologieMoleculaire = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "MSI", label: "MSI" },
            { value: "Kras", label: "Kras" },
            { value: "BRAF", label: "BRAF" },
        ] : []),

        ...(data.Specialite === 'Digestif' && data.Organe === 'Pancreas' ? [
            { value: "CA 19 9", label: "CA 19 9" },

        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Estomac' ? [
            { value: "HER2", label: "HER2" },
            { value: "MSI", label: "MSI" },
            { value: "CDH1", label: "CDH1" },

        ] : []),
        ...(data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique' ? [
            { value: "Mutation Tp53", label: "Mutation Tp53" },


        ] : []),
        ...(data.Specialite === 'Urologie' && data.Organe === 'Vessie/Arbre urinaire' ? [
            { value: "PDL1 (vessie)", label: "PDL1 (vessie)" },


        ] : []),
        ...(data.Specialite === 'Urologie' && data.Organe === 'Prostate' ? [
            { value: "BRCA (Prostate)", label: "BRCA (Prostate)" },


        ] : []),
        ...(data.Specialite === 'Urologie' && data.Organe === 'Testicule' ? [
            { value: "AFP BHCG (testicule)", label: "AFP BHCG (testicule)" },


        ] : []),
        ...(data.Specialite === 'Thorax' && data.Organe === 'Poumon' ? [
            { value: "patient naïf da chimiotérapie", label: "patient naïf da chimiotérapie)" },
            { value: "patient ayant reçu une chimiotérapie", label: "patient ayant reçu une chimiotérapie" },


        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Thyroide') ? [
            { value: "Mutation (BRAF, RAS)", label: "Mutation (BRAF, RAS)" },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Prostate') ? [
            { value: "Surexpression IGF-2", label: "Surexpression IGF-2" },
            { value: "Translocation PAX(3 ou 7 ) / FOX01 (FKHR)", label: "Translocation PAX(3 ou 7 ) / FOX01 (FKHR)" },
            { value: "Mutation P53", label: "Mutation P53" },
            { value: "DICER1", label: "DICER1" },

        ] : []),


        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Testicule/para-testicule' ? [
            { value: "Gains en 1q, 3, 11q, 20q, and 22", label: "Gains en 1q, 3, 11q, 20q, and 22" },
            { value: "TP53/MDM2", label: "TP53/MDM2" },
            { value: "Mutation KIT et KRAS", label: "Mutation KIT et KRAS" },


        ] : []),

        ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Parathyroïde' || data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale') ? [
            { value: "IHC", label: "IHC" },

        ] : []),


    ];

    const optionNéoplasmesHistiocytesMacrophages = [
        { value: "xanthogranulome juvénile", label: "xanthogranulome juvénile" },
        { value: "Malade d'Erdheim-Chester", label: "Malade d'Erdheim-Chester" },
        { value: "Malade de Rosai-Dorfman", label: "Malade de Rosai-Dorfman" },
        { value: "Histiocytose ALK-positive", label: "Histiocytose ALK-positive" },
        { value: "Sarcome histiocytaire", label: "Sarcome histiocytaire" },

    ];

    const optionCystologie = [
        ...(data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte' ? [
            { value: "Leucémie aigüe lymphoblastique B", label: "Leucémie aigüe lymphoblastique B" },
            { value: "Leucémie aigüe lymphoblastique T", label: "Leucémie aigüe lymphoblastique T" },
            { value: "Leucémie aigue myéloblastique", label: "Leucémie aigue myéloblastique" },
            { value: "Leucémie aigüe bi phénotypique", label: "Leucémie aigüe bi phénotypique" },
            { value: "Forme indifférenciéeT", label: "Forme indifférenciée" }

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne' ? [
            { value: "tératomes", label: "tératomes" },
            { value: "Tumeur vitelline", label: "Tumeur vitelline" },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale' ? [
            { value: "score de Weiss", label: "score de Weiss" },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Oesophage' ? [
            { value: "Carcinome épidermoide", label: "Carcinome épidermoide" },

        ] : []),


    ];


    const optionTumeur = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Anus' ? [
            { value: "Tis: tumeur in situ", label: "Tis: tumeur in situ" },
            { value: "T1: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm) ", label: "T1: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)" },
            { value: "T2: tumeur supérieur à 2cm mais inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)", label: "T2: tumeur supérieur à 2cm mais inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)" },
            { value: "T3: tumeur supérieur à 5cm dans sa plus grande dimmension", label: "T3:tumeur supérieur à 5cm dans sa plus grande dimmension" },
            { value: "T4: tumeur,quelle que soit sa taille,qui envahit un ou plusieurs organes adjacents (vagin,urètre,vessie) à l'exception du rectum,de la peau périnéale,du tissu cellulaire sous-cutanéet du sphincter. ", label: "Tis: tumeur,quelle que soit sa taille,qui envahit un ou plusieurs organes adjacents (vagin,urètre,vessie) à l'exception du rectum,de la peau périnéale,du tissu cellulaire sous-cutanéet du sphincter." },
            { value: "Tx: non évalué", label: "Tx: non évalué" },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "TX: Renseignement insuffisant pour classer la tumeur primitif", label: "TX: Renseignement insuffisant pour classer la tumeur primitif" },
            { value: "T0: Pas de signe de tumeur primitive: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)", label: "T0 Pas de signe de tumeur primitive: tumeur inférieur ou égale à 5cm dans sa plus grande dimmension (de 21mm à 50 mm)" },
            { value: "Tis: Carcinome in situ :intra-épithélial ou envahissant la lamina propria", label: "Tis: Carcinome in situ :intra-épithélial ou envahissant la lamina propria" },
            { value: "T1: Tumeur envahissant la sous-muqueuse", label: "T1: Tumeur envahissant la sous-muqueuse" },
            { value: "T2: Tumeur envahissant la musculeuse", label: "T2: Tumeur envahissant la musculeuse" },
            { value: "T3: Tumeur envahissant la sous-séreuse ou les tissus péri-rectaux non péritonisés", label: "T3: Tumeur envahissant la sous-séreuse ou les tissus péri-rectaux non péritonisés" },
            { value: "T4: Tumeur envahissant directement les autres organes ou structures evou perforant|le péritoine viscéral", label: "T4: Tumeur envahissant directement les autres organes ou structures evou perforant|le péritoine viscéral" },
            { value: "T4a: Tumeur perforant le péritoine viscéral", label: "T4a: Tumeur perforant le péritoine viscéral" },
            { value: "T4b: Tumeur envahissant directement les autres organes ou structures?", label: "T4b: Tumeur envahissant directement les autres organes ou structures?" },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Voies biliaires' ? [
            { value: "TX: non évalué", label: "TX: non évalué" },
            { value: "T0: pas de tumeur primitive", label: "T0: pas de tumeur primitive" },
            { value: "TIS: CIS (intra canalaire)  ", label: "TIS: CIS (intra canalaire)" },
            { value: "Tis: CIS/DHG", label: "Tis: CIS/DHG" },
            { value: "Tis: CIS", label: "Tis: CIS" },
            { value: "T1: Unique(grand axe ≤5cm:T1a;≥ 5mm T1b sans invasion vasculaire", label: "T1: Unique(grand axe ≤5cm:T1a;≥5cm T1b sans invension vasculaire" },
            { value: "T1: Envahit parois biliaire(→ muscle ou tissu fibreux)", label: "T1: Envahit parois biliaire(→ muscle ou tissu fibreux)" },
            { value: "T1: Envahit parois biliaire(< 5mm en profondeur)", label: "T1: Envahit parois biliaire(< 5mm en profondeur)" },
            { value: "T1: Envahit parois biliaire(→ lamina propria (T1a) ou musculeuse (T1b))", label: "T1: Envahit parois biliaire(→ lamina propria (T1a) ou musculeuse (T1b))" },
            { value: "T2: Unique + invasion vasculaire intra-hépatique ou multiple (± invasion vasculaire) ", label: "Unique + invasion vasculaire intra-hépatique ou multiple (± invasion vasculaire" },
            { value: "T2: Dépasse parois biliaire(→ tissu adipeux(T2a) ou fooie adjacente (T2b))", label: "T2: Dépasse parois biliaire(→ tissu adipeux(T2a) ou fooie adjacente (T2b))" },
            { value: "T2: Envahit parois biliaire (> 5mm  et <12mm en profondeur)", label: "T2: Envahit parois biliaire(> 5mm  et <12mm en profondeur)" },
            { value: "T2: Envahit tissu périmusculaire sans extensionà la séreuse (face péritonéale) (T2a) ou au fie face hépatique (T2b)", label: "T2: Envahit tissu périmusculaire sans extensionà la séreuse (face péritonéale) (T2a) ou au fie face hépatique (T2b)" },
            { value: "T3: Perfore le péritoine viscéral", label: "T3: Perfore le péritoine viscéral" },
            { value: "T3: Atteinte unilatéral BP ou AH", label: "T3: Atteinte unilatéral BP ou AH" },
            { value: "T3: Envahit parois biliaire(> 12mm en profondeur)", label: "T3: Envahit parois biliaire(> 12mm en profondeur" },
            { value: "T3: Perfore le péritoine viscéral et/ou envahit foie et/ou organe structure extra-hépatique * par contiguité", label: "T3: Perfore le péritoine viscéral et/ou envahit foie et/ou organe structure extra-hépatique * par contiguité" },
            { value: "T4: Envahit les structures extra-hépatique par contiguité hépatique", label: "T4: Envahit les structures extra-hépatique par contiguité hépatique" },
            { value: "T4: Atteinte TP,BP bilatérale AH commune ou CBS bilatérale ou CBS unilatérale VP ou AH controlatérale", label: "T4: Atteinte TP,BP bilatérale AH commune ou CBS bilatérale ou CBS unilatérale VP ou AH controlatérale" },
            { value: "T4: Atteinte TC,AMS et/ou AH commune", label: "T4: Atteinte TC,AMS et/ou AH commune" },
            { value: "T4: Atteinte TP,AH commune ou >2 organes / structures extra-hépatique (par contiguité)", label: "T4: Atteinte TP,AH commune ou >2 organes / structures extra-hépatique (par contiguité)" },
        ] : []),

        ...(data.Specialite === 'Digestif' && data.Organe === 'Pancreas' ? [
            { value: "RESECABLE", label: "RESECABLE" },
            { value: "LOCALEMENT AVANCEE", label: "LOCALEMENT AVANCEE" },
            { value: "BORDELINE", label: "BORDELINE" },
            { value: "METASTATIQUE", label: "METASTATIQUE" },

        ] : []),

        ...(data.Specialite === 'Digestif' && data.Organe === 'Estomac' ? [
            { value: "T1 : Tumeur limitée à la muqueuse ou à la sous-muqueuse (cancer superficiel)", label: "T1 : Tumeur limitée à la muqueuse ou à la sous-muqueuse (cancer superficiel)" },
            { value: "Tia m1(Tis) : Tumeur intra-Gplthélale sans invasion de la lamina propria, dysplasie de haut grade ou carcinome in situ", label: "Tia m1(Tis) : Tumeur intra-Gplthélale sans invasion de la lamina propria, dysplasie de haut grade ou carcinome in situ" },
            { value: "Tia m2 : tumeur envahissant la lamina propria", label: "Tia m2 : tumeur envahissant la lamina propria" },
            { value: "Tia m3: tumeur envahissant la musculaire muqueuse", label: "Tia m3: tumeur envahissant la musculaire muqueuse" },
            { value: "T1 smi : tumeur envahissant la sous-muqueuse sur moins de 500 microns", label: "T1 smi : tumeur envahissant la sous-muqueuse sur moins de 500 microns" },
            { value: "T1 sm2 : tumeur envahissant a sous-muqueuse sur plus de 500 microns", label: "T1 sm2 : tumeur envahissant a sous-muqueuse sur plus de 500 microns" },
            { value: "T2 : Tumeur étendue à la musculeuse", label: "T2 : Tumeur étendue à la musculeuse" },
            { value: "T3 : Tumeur envahissant la sous séreuse (y compris ligament gastro-colique ou gastro-hépatique ou grand épiploon)", label: "T3 : Tumeur envahissant la sous séreuse (y compris ligament gastro-colique ou gastro-hépatique ou grand épiploon)" },
            { value: "T4 : Tumeur envahissant la séreuse ou les organes adjacents", label: "T4 : Tumeur envahissant la séreuse ou les organes adjacents" },
            { value: "T4a : Tumeur envahissant la séreuse (pértoine viscéral)", label: "T4a : Tumeur envahissant la séreuse (pértoine viscéral)" },
            { value: "T4b : Tumeur envahissant un organe ou une structure de voisinage (rate, côlon transverse, foe, diaphragme, pancréas, paroi abdominale, surrénale, in, inestin grêle,rétropéritoine)L'envahissoment de l'æysophage ou du duodénum n'est pas considéré comme l'envahissement d'un organe adjacent.", label: "T4b : Tumeur envahissant un organe ou une structure de voisinage (rate, côlon transverse, foe, diaphragme, pancréas, paroi abdominale, surrénale, in, inestin grêle,rétropéritoine)L'envahissoment de l'æysophage ou du duodénum n'est pas considéré comme l'envahissement d'un organe adjacent." },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Oesophage' ? [
            { value: "Tx: non évalué", label: "Tx: non évalué" },
            { value: "TO: Pas de signe de tumeur primitive", label: "TO: Pas de signe de tumeur primitive" },
            { value: "Tis: Carcinome in situ", label: "Tis: Carcinome in situ" },
            { value: "T1: Tumeur envahissant la muqueuse ou la sous-muqueuse", label: "T1: Tumeur envahissant la muqueuse ou la sous-muqueuse" },
            { value: "Tia: tumeur envahissant la muqueuse ou la musculaire muqueuse", label: "Tia: tumeur envahissant la muqueuse ou la musculaire muqueuse" },
            { value: "Tb: tumeur envahissant la sous-muqueuse", label: "Tb: tumeur envahissant la sous-muqueuse" },
            { value: "T2: Tumeur envahissant la musculeuse", label: "T2: Tumeur envahissant la musculeuse" },
            { value: "T3: Tumeur envahissant l'adventice", label: "T3: Tumeur envahissant l'adventice" },
            { value: "T4: Tumeur envahissant les structures adjacentes.", label: "T4: Tumeur envahissant les structures adjacentes." },
            { value: "T4a: Tumeur résécable envahissant la plèvre, le péricarde, la veine azygos, le diaphragme ou le péritoine", label: "T4a: Tumeur résécable envahissant la plèvre, le péricarde, la veine azygos, le diaphragme ou le péritoine" },
            { value: "T4b: Tumeur non résécable envahissant les autres structures de voisinage telles que l'aorte, les corps vertébraux, la trachée", label: "T4b Tumeur non résécable envahissant les autres structures de voisinage telles que l'aorte, les corps vertébraux, la trachée" },
        ] : []),
        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Néoplasies trophoblastiques gestationnelles' ? [
            { value: "Stade I: Malade limitée à l'utérus", label: "Stade I: Malade limitée à l'utérus" },
            { value: "Stade II: Néoplasies trophoblastiques gestationnelles étendue en dehors de l'utérus mais limitée aus structures génitales(annexe,vagin,ligaments larges)", label: "Stade II: Néoplasies trophoblastiques gestationnelles étendue en dehors de l'utérus mais limitée aus structures génitales(annexe,vagin,ligaments larges)" },
            { value: "Stade III: Néoplasies trophoblastiques gestationnelles étendue aux poumons avec ou sans atteinte connue du tractus génital", label: "Stade III: Néoplasies trophoblastiques gestationnelles étendue aux poumons avec ou sans atteinte connue du tractus génital" },
            { value: "StadeIV: Tout autre site m:étastatique", label: "StadeIV: Tout autre site m:étastatique" },

        ] : []),

        ...(data.Specialite === 'Gynécologie' && (data.Organe === 'Ovaire' || data.Organe === 'Col' || data.Organe === 'Utérus' || data.Organe === 'Vagin' || data.Organe === 'Vulve') ? [
            { value: "TX: tumeur primitive non évaluable", label: "TX: tumeur primitive non évaluable" },
            { value: "T0: pas de lésion du col", label: "T0: pas de lésion du col" },
            { value: "Tis: Stade 0: Cancer in situ", label: "TIS:  Cancer in situ" },
            { value: "T1: Stade I: tumeur limité du col", label: "T1: tumeur limité du col" },
            { value: "T1a: Stade IA: tumeur non visible diagniostiqué par histologie", label: "T1a: Stade IA: tumeur non visible diagniostiqué par histologie" },
            { value: "T1a1: StadeIA1: profondeur d'invansion ≤  3 mm et extension horizonale ≤  7mm ", label: "T1a1: Stade1A1: profondeur d'invansion ≤  3 mm et extension horizonale ≤  7mm" },
            { value: "T1a2: StadeIA2: invansion ≥  3 mm et ≤  5mm et  extension horizonale ≤  7mm", label: "T1a2: Stade1A2: invansion ≥  3 mm et ≤  5mm et  extension horizonale ≤  7mm" },
            { value: "T1b: StadeIB: tumeur clinique ou tumeur > stade Ia", label: "T1b: StadeIB: tumeur clinique ou tumeur > stade Ia" },
            { value: "T1b1: StadeIB1: Diamètre maximale ≤ 4cm mesuré à l'IRM", label: "T1b1: StadeIB1: Diamètre maximale ≤ 4cm mesuré à l'IRM" },
            { value: "T1b2: StadeIB2: Diamètre maximale ≤ 4cm", label: "T1b2: StadeIB2: Diamètre maximale ≤ 4cm" },
            { value: "T2: StadeII: Invansion en dehors du col mais ne touchant pas le paroi pelvienne ou le tiers inférieur du vagin", label: "T2: StadeII: Invansion en dehors du col mais ne touchant pas le paroi pelvienne ou le tiers inférieur du vagin" },
            { value: "T2a: StadeIIA : Dome vaginal (2/3) supérieur sans extension paramétriale", label: "T2a: StadeIIA : Dome vaginal (2/3) supérieur sans extension paramétriale" },
            { value: "StadeIIA 1:  ≤ 4cm", label: "StadeIIA 1:  ≤ 4cm" },
            { value: "StadeIIA 2:  ≥  4cm", label: "StadeIIA 2:  ≥  4cm" },
            { value: "T2b: StadeIIB: Attente paramétriale évidente", label: "T2b: StadeIIB: Attente paramétriale évidente" },
            { value: "T3: StadeIII: invansion du tiers inférieur du vagin et/ou juqu à la paroi pelvienne et/ou obstruction urétrale", label: "T3: StadeIII: invansion du tiers inférieur du vagin et/ou juqu à la paroi pelvienne et/ou obstruction urétrale" },
            { value: "T3a: StadeIIIA: Fourreau vaginale (1/3) inférieur ", label: "T3a: StadeIIIA: Fourreau vaginale (1/3) inférieur " },
            { value: "T3b: StadeIIB:paramétre jusqu'à la paroi ou obst", label: "T2b: StadeIIB: Attente paramétriale évidente" },
            { value: "StadeIVA: Envahissment base vésicale et/ou paroi rectal", label: "StadeIVA: Envahissment base vésicale et/ou paroi rectal" },
            { value: "T2b: StadeIIB: Attente paramétriale évidente", label: "T2b: StadeIIB: Attente paramétriale évidente" },
        ] : []),
        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Sein' ? [
            { value: "TX: Détermination de la tumeur primitive impossible", label: "TX: Détermination de la tumeur primitive impossible" },
            { value: "T0: Pas de signes de tumeur primitive", label: "T0: Pas de signes de tumeur primitive" },
            { value: "Tis: Carcinome in situ", label: "Tis: Carcinome in situ" },
            { value: "T1a:  Dimension inférieure à 0,5 cm", label: "T1a:  Dimension inférieure à 0,5 cm" },
            { value: "T1b: Dimension supérieure à 0.5cm et inférieure à 1cm", label: "T1b: Dimension supérieure à 0.5cm et inférieure à 1cm" },
            { value: "T1c: Dimension supérieure à 1cm et inférieure à 2cm", label: "T1c: Dimension supérieure à 1cm et inférieure à 2cm" },
            { value: "T2:  Tumeur supérieure ou égale à 2cm et inférieure à 5cm dans sa plus grande dimension", label: "T2:  Tumeur supérieure ou égale à 2cm et inférieure à 5cm dans sa plus grande dimension" },
            { value: "T3: Tumeur supérieure à Scm dans sa plus grande dimension", label: "T3: Tumeur supérieure à Scm dans sa plus grande dimension" },
            { value: "T4a: Tumeur de toute taille avec extension directe à la paroi thoracique", label: "T4a: Tumeur de toute taille avec extension directe à la paroi thoracique" },
            { value: "T4b: Tumeur de toute taille avec extension directe à la peau", label: "T4b: Tumeur de toute taille avec extension directe à la peau" },
            { value: "T4c: Tumeur inflammatoire", label: "T4c: Tumeur inflammatoire" },



        ] : []),

        ...(data.Specialite === 'Hématologie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' ? [
            { value: "Histiocytose à cellules de Langerhans", label: "Histiocytose à cellules de Langerhans" },
            { value: "Sarcome à cellules de Langerhans", label: "Sarcome à cellules de Langerhans" },


        ] : []),
        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'Lymphome non hodgkinien adulte') ? [
            { value: "Stade I", label: "Stade I" },
            { value: "Stade II", label: "Stade II" },
            { value: "Stade III", label: "Stade III" },
            { value: "Stade IV", label: "Stade IV" },
            { value: "A ou B", label: "A ou B" },
            { value: "X", label: "X" },
            { value: "E", label: "E" },


        ] : []),

        ...(data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte' ? [
            { value: "LAM OMS 2022", label: "LAM OMS 2022" },
            { value: "LAL", label: "LAL" },


        ] : []),

        ...(data.Specialite === 'Urologie' && data.Organe === 'Vessie/Arbre urinaire' ? [
            { value: "TNM", label: "TNM" },
            { value: "TVNIM", label: "TVNIM" },
            { value: "TVIM", label: "TVIM" },
            { value: "M1", label: "M1" },


        ] : []),

        ...(data.Specialite === 'Thorax' && data.Organe === 'Poumon' ? [

            { value: "Tx:Tumeur primutive non connu ou tumeur prouvé par la présence de cellules malignes dans les secrétions btoncho-pulmonaire mais non visible aus exemens Checkboxlogiques et endoscopiques", label: "Tx:Tumeur primutive non connu ou tumeur prouvé par la présence de cellules malignes dans les secrétions btoncho-pulmonaire mais non visible aus exemens Checkboxlogiques et endoscopiques" },
            { value: "T0:Absence de tumeur identifiable", label: "Absence de tumeur identifiable" },
            { value: "Tis: Carcinome in situ", label: "Tis: Carcinome in situ" },
            { value: "T1: Tumeur de 3cm ou moins dans ses plus grands dimensions,entouré par du poumon et de la plèvre viscérale, sans évidence évansion plus proximale que les bronches lobaires à bronchoscopies", label: "T1: Tumeur de 3cm ou moins dans ses plus grands dimensions,entouré par du poumon et de la plèvre viscérale, sans évidence évansion plus proximale que les bronches lobaires à bronchoscopies" },
            { value: "T1a(mi) : Adénocarcinome minimalement-invasif", label: "T1a(mi) : Adénocarcinome minimalement-invasif" },
            { value: "T1a : ≤ 1cm", label: "T1a : ≤ 1cm" },
            { value: "T1b : ≥1cm et ≤ 2cm", label: "T1b : ≥1cm et ≤ 2cm" },
            { value: "T1c : > 2cm et ≤ 3cm", label: "T1c : > 2cm et ≤ 3cm" },
            { value: "T2: Tumeur de plus de 3cm, mais de 5cm au moins,avec quelconques des élements", label: "T2: Tumeur de plus de 3cm, mais de 5cm au moins,avec quelconques des élements" },
            { value: "T2a : > 3cm mais ≤ 4cm", label: "T2a : > 3cm mais ≤ 4cm" },
            { value: "T2b : > 4cm mais ≤ 5cm", label: "T2b : > 4cm mais ≤ 5cm" },
            { value: "T3 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) tumoral(aux) distinct(s) et dans le meme lobe,ou ayant au moins l'un caractère invasif", label: "T3 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) tumoral(aux) distinct(s) et dans le meme lobe,ou ayant au moins l'un caractère invasif" },
            { value: "T4 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) pulmonaire(s) distinct(s) comportant un envahissement quelconques", label: "T4 : Tumeur de plus de 7cm ou associé a un (des) nodules(s) pulmonaire(s) distinct(s) comportant un envahissement quelconques" },

        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? [
            { value: "T1 : Tumeur ≤ 2cm dans son plus grand axe", label: "T1 : Tumeur ≤ 2cm dans son plus grand axe" },
            { value: "T2 : Tumeur entre 2 et 4 dans son plus grand axe", label: "T1 : Tumeur ≤ 2cm dans son plus grand axe" },
            { value: "T3 : Tumeur > 4cm ou extension à la face lingale de l'épiglotte", label: "T3 : Tumeur > 4cm ou extension à la face lingale de l'épiglotte" },
            { value: "T4 : Invasion de larynx,des muscles extrincsèque de la langue(géniglosse,hyoglosse,palatoglosse et styloglosse),du palais de dur,de la mandibule,des muscles ptérygoidiens,du nasopharyncs,de la base du crane ou de la carotide", label: "T1 : Tumeur ≤ 2cm dans son plus grand axe" },

        ] : []),

        ...(data.Specialite === 'ORL' && data.Organe === 'Thyroide' ? [
            { value: "Tx, pTx : tumeur primitive non retrouvée", label: "Tx, pTx : tumeur primitive non retrouvée" },
            { value: "T1, pT1 : tumeur ≤ 20 mm, limitée à la thyroïde", label: "T1, pT1 : tumeur ≤ 20 mm, limitée à la thyroïde" },
            { value: "T1a : ≤10mm", label: "T1a : ≤10mm" },
            { value: "T1b : >10mm et ≤20mm", label: "T1b : >10mm et ≤20mm" },
            { value: "T2, pT2 : tumeur > 20 mm et < 40 mm, limitée à la thyroïde", label: "T2, pT2 : tumeur > 20 mm et < 40 mm, limitée à la thyroïde" },
            { value: "T3, pT3 : tumeur > 40 mm limitée à la thyroïde ou avec extension extrathryoïdienne minime (muscle sternothyroïdien ou tissus périthyroïdiens)", label: "T3, pT3 : tumeur > 40 mm limitée à la thyroïde ou avec extension extrathryoïdienne minime (muscle sternothyroïdien ou tissus périthyroïdiens)" },
            { value: "T3a : >4cm limitée à la thyroide", label: "T3a : >4cm limitée à la thyroide" },
            { value: "T3b : extension extrathryoïdienne minime", label: "T3b : extension extrathryoïdienne minime" },
            { value: "T4, pT4 : tumeur qui s’étend au-delà de la capsule thyroïdienne", label: "T4, pT4 : tumeur qui s’étend au-delà de la capsule thyroïdienne" },
            { value: "T4a : envahi les tissus sous cutanés, le larynx, la trachée, l’œsophage ou le récurrent.", label: "T4a : envahi les tissus sous cutanés, le larynx, la trachée, l’œsophage ou le récurrent." },
            { value: "T4b : envahi le fascia para-vertébral, le médiastin ou la carotide.", label: "T4b : envahi le fascia para-vertébral, le médiastin ou la carotide." },

        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? [
            { value: "T1: tumeur confiné au nasopharynx ou s'étendant à l'oropharynx et/ou à la cavité nasale sans extension parapharyngée", label: "T1: tumeur confiné au nasopharynx ou s'étendant à l'oropharynx et/ou à la cavité nasale sans extension parapharyngée" },
            { value: "T2 : Parapharyngée et/ou infiltration des muscles ptérigoydiens médial et/ou latérale et/ou prévertébraux", label: "T2 : Parapharyngée et/ou infiltration des muscles ptérigoydiens médial et/ou latérale et/ou prévertébraux" },
            { value: "T3 : Envahissement des structures osseuses de la base du crâne, des sinus paranasaux des vertèbres crébrales et/ou des apophytes ptérygoydiennes", label: "T3 : Envahissement des structures osseuses de la base du crâne, des sinus paranasaux des vertèbres crébrales et/ou des apophytes ptérygoydiennes" },
            { value: "T4: Extension intracranienne et/ou atteinte des nerf craniennes,de l'hypopharynx", label: "T4: Extension intracranienne et/ou atteinte des nerf craniennes,de l'hypopharynx" },


        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs orbitaires' || data.Organe === 'Tumeurs médiastinales' || data.Organe === 'Corticosurrenale') ? [
            { value: "Stade L1 – La tumeur se trouve seulement dans la région du corps où elle a pris naissance, comme le cou, le thorax, l’abdomen ou le bassin, et il n’y a aucun facteur de risque défini par l’imagerie.", label: "Stade L1 – La tumeur se trouve seulement dans la région du corps où elle a pris naissance, comme le cou, le thorax, l’abdomen ou le bassin, et il n’y a aucun facteur de risque défini par l’imagerie." },
            { value: "Stade L2 – La tumeur s’est propagée à une région voisine et on observe 1 ou plusieurs facteurs de risque définis par l’imagerie.", label: "Stade L2 – La tumeur s’est propagée à une région voisine et on observe 1 ou plusieurs facteurs de risque définis par l’imagerie." },
            { value: "Stade M – Le cancer s’est propagé à des parties du corps éloignées de la tumeur (métastases à distance), mais ce stade ne comprend pas les tumeurs de stade MS.", label: "Stade M – Le cancer s’est propagé à des parties du corps éloignées de la tumeur (métastases à distance), mais ce stade ne comprend pas les tumeurs de stade MS." },
            { value: "Stade MS – Ce stade est réservé aux enfants de moins de 18 mois qui présentent des métastases à distance. Le cancer s’est propagé seulement à la peau, au foie ou à la moelle osseuse. S’il s’est propagé à la moelle osseuse, moins de 10 % des cellules dans la moelle sont cancéreuses.", label: "Stade MS – Ce stade est réservé aux enfants de moins de 18 mois qui présentent des métastases à distance. Le cancer s’est propagé seulement à la peau, au foie ou à la moelle osseuse. S’il s’est propagé à la moelle osseuse, moins de 10 % des cellules dans la moelle sont cancéreuses." },

        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs maxillo faciales') ? [
            { value: "Stade 1 – Le cancer est dans une région favorable. Il ne s’est pas propagé à une partie du corps plus éloignée de son lieu d’origine", label: "Stade 1 – Le cancer est dans une région favorable. Il ne s’est pas propagé à une partie du corps plus éloignée de son lieu d’origine" },
            { value: "Stade 2 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle ne s’est pas propagée aux ganglions lymphatiques voisins ou à d’autres parties du corps.", label: "Stade 2 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle ne s’est pas propagée aux ganglions lymphatiques voisins ou à d’autres parties du corps." },
            { value: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle peut avoir envahi les régions voisines. Le cancer s’est propagé aux ganglions lymphatiques voisins.", label: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure 5 cm ou moins. Elle peut avoir envahi les régions voisines. Le cancer s’est propagé aux ganglions lymphatiques voisins." },
            { value: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure plus de 5 cm. Elle peut avoir envahi les régions voisines. Le cancer peut s’être propagé aux ganglions lymphatiques voisins.", label: "Stade 3 – Le cancer est dans une région défavorable. La tumeur mesure plus de 5 cm. Elle peut avoir envahi les régions voisines. Le cancer peut s’être propagé aux ganglions lymphatiques voisins." },
            { value: "Stade 4 – Le cancer est dans n’importe quelle région et de n’importe quelle taille. Il s’est propagé à d’autres parties du corps (métastases à distance), comme aux poumons, au foie ou à la moelle osseuse. On parle aussi de RMS métastatique.", label: "Stade 4 – Le cancer est dans n’importe quelle région et de n’importe quelle taille. Il s’est propagé à d’autres parties du corps (métastases à distance), comme aux poumons, au foie ou à la moelle osseuse. On parle aussi de RMS métastatique." },


        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Foie/Pédiatrie') ? [
            { value: "Pretext 1: Une section est concernée ; trois sections adjacentes sont exemptes de tumeurs.", label: "Pretext 1: Une section est concernée ; trois sections adjacentes sont exemptes de tumeurs." },
            { value: "Pretext 2: Une ou deux sections concernées ; deux sections adjacentes sont exemptes de tumeurs.", label: "Pretext 2: Une ou deux sections concernées ; deux sections adjacentes sont exemptes de tumeurs." },
            { value: "Pretext 3: Deux ou trois sections concernées ; une section adjacente est exempte de tumeur.", label: "Pretext 3: Deux ou trois sections concernées ; une section adjacente est exempte de tumeur." },
            { value: "Pretext 4: Quatre sections impliquées.", label: "Pretext 4: Quatre sections impliquées." },


        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'T. Sacro-coccygienne') ? [
            { value: "I: tumeur du sillon interfessier", label: "I: tumeur du sillon interfessier" },
            { value: "II: tumeur exo-pelvienne", label: "II: tumeur exo-pelvienne" },
            { value: "III: tumeur endo et exo-pelvienne", label: "III: tumeur endo et exo-pelvienne" },
            { value: "IV: tumeur endopelvienne", label: "IV: tumeur endopelvienne" },

        ] : []),



        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Ovaire') ? [
            { value: " IA : Cancer limité à un ovaire ou une trompe de Fallope.", label: "IA : Cancer limité à un ovaire ou une trompe de Fallope." },
            { value: " IB : Cancer présent dans les deux ovaires ou trompes de Fallope.", label: "IB : Cancer présent dans les deux ovaires ou trompes de Fallope." },
            { value: " IC : Cancer dans un ou les deux ovaires ou trompes de Fallope avec des caractéristiques supplémentaires :", label: "IC : Cancer dans un ou les deux ovaires ou trompes de Fallope avec des caractéristiques supplémentaires :" },
            { value: " IC1 : Rupture chirurgicale.", label: "IC1 : Rupture chirurgicale." },
            { value: "IC2 : Rupture de la capsule avant la chirurgie ou tumeur à la surface de l'ovaire ou de la trompe de Fallope.", label: "IC2 : Rupture de la capsule avant la chirurgie ou tumeur à la surface de l'ovaire ou de la trompe de Fallope." },
            { value: "IC3 : Cellules malignes dans les ascites ou les prélèvements péritonéaux.", label: "IC3 : Cellules malignes dans les ascites ou les prélèvements péritonéaux." },

        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Voie excretrice' || data.Organe === 'Prostate') ? [
            { value: "TX : La tumeur primaire ne peut pas être évaluée.", label: "TX : La tumeur primaire ne peut pas être évaluée." },
            { value: "T0 : Pas de signe de tumeur primaire.", label: "T0 : Pas de signe de tumeur primaire." },
            { value: "T1 : Tumeur limitée à la muqueuse urothéliale ou sous-muqueuse.", label: "T1 : Tumeur limitée à la muqueuse urothéliale ou sous-muqueuse." },
            { value: "T2 : Tumeur envahit la musculeuse.", label: "T2 : Tumeur envahit la musculeuse." },
            { value: "T3 : Tumeur envahit la graisse péri-urétérale.", label: "T3 : Tumeur envahit la graisse péri-urétérale." },
            { value: "T4 : Tumeur envahit les organes adjacents (par exemple, le rein ou la paroi pelvienne).", label: "T4 : Tumeur envahit les organes adjacents (par exemple, le rein ou la paroi pelvienne)." },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Voie excretrice') ? [

            { value: "I : Résection complète, marges négatives", label: "I : Résection complète, marges négatives" },
            { value: "IIa : Résection complète, marges négatives", label: "IIa : Résection complète, marges négatives" },
            { value: "IIb : Résection complète, marges négatives, ganglions réséqués positifs", label: "IIb : Résection complète, marges négatives, ganglions réséqués positifs" },
            { value: "IIc : Résection complète, marges négatives, ganglions réséqués positifs", label: "IIc : Résection complète, marges négatives, ganglions réséqués positifs" },
            { value: "III : Reste de tumeur visible (inclut les ganglions régionaux non réséqués)", label: "III : Reste de tumeur visible (inclut les ganglions régionaux non réséqués)" },
            { value: "IV : Métastases distantes", label: "IV : Métastases distantes" },


        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Os') ? [
            { value: "Localisé", label: "Localisé" },
            { value: "Métastatique pulmonaire et/ou osseux", label: "Métastatique pulmonaire et/ou osseux" },
            { value: "Classification TNM UICC 7e edition", label: "Classification TNM UICC 7e edition" },

        ] : []),




    ];


    const optionClassificationOMS = [
        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Syndromes myélodysplasiques') ? [

            { value: "SMD avec dysplasie unilignée", label: "SMD avec dysplasie unilignée" },
            { value: "SMD avec dysplasie multilignées", label: "SMD avec dysplasie multilignées" },
            { value: "SMD avec sidéroblaste en couronne (SMD-RS)", label: "SMD avec sidéroblaste en couronne (SMD-RS)" },
            { value: "SMD – RS avec dysplasie unilignée", label: "SMD – RS avec dysplasie unilignée" },
            { value: "SMD – RS avec dysplasie multilignées", label: "SMD – RS avec dysplasie multilignées" },
            { value: "SMD avec délétion 5q isolée", label: "SMD avec délétion 5q isolée" },
            { value: "SMD avec Excès de Blastes de type 1", label: "SMD avec Excès de Blastes de type 1" },
            { value: "SMD avec Excès de Blastes de type 2", label: "SMD avec Excès de Blastes de type 2" },
            { value: "SMD inclassables", label: "SMD inclassables" },

        ] : []),

        ...(data.Specialite === 'Hématologie' && (data.Organe === 'Syndrome Myéloprolifératif Ph1 négative') ? [
            { value: "Polyglobulie de Vaquez", label: "Polyglobulie de Vaquez" },
            { value: "Thrombocytémie essentielle", label: "Thrombocytémie essentielle" },
            { value: "Myélofibrose primitive", label: "Myélofibrose primitive" },
            { value: "Leucémie chronique à neutrophiles", label: "Leucémie chronique à neutrophiles" },
            { value: "Leucémie chronique à éosinophiles", label: "Leucémie chronique à éosinophiles" },

        ] : []),


    ];
    const optionAdenophatie = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Anus' ? [
            { value: "Nx: ganglions non évalués", label: "Nx: ganglions non évalués" },
            { value: "N0: absence de ganglion métastatique", label: "N0. absence de ganglion Métastatique" },
            { value: "N1a: Métas ganglionnaires inguinales et/ou dans le mesorectum et/ou iliaque interne", label: "N1a: Métas ganglionnaires inguinales et/ou dans le mesorectum et/ou iliaque interne" },
            { value: "N1b: Métas ganglionnaires iliaques externes", label: "N1b: Métas ganglionnaires iliaques externes" },
            { value: "N1c: Métas ganglionnaires iliaques externes et inguinales et/ou dans le mesorectum et/ou iliaque interne", label: "N1c: Métas ganglionnaires iliaques externes et inguinales et/ou dans le mesorectum et/ou iliaque interne" },

        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "NX: Renseignements insuffisants pour classer les adénopathies régionales", label: "NX: Renseignements insuffisants pour classer les adénopathies régionales" },
            { value: "NO: Pas de métastase ganglionnaire régionale", label: "NO: Pas de métastase ganglionnaire régionale" },
            { value: "NI: Métastase dans 1 à 3 ganglions lymphatiques régionaux", label: "NI: Métastase dans 1 à 3 ganglions lymphatiques régionaux" },
            { value: "Nta: Métastases dans 1 ganglion lymphatique régional", label: "Nta: Métastases dans 1 ganglion Iymphatique régional" },
            { value: "Nib: Métastases dans 2-3 ganglions lymphatiques régionau)", label: "Nib: Métastases dans 2-3 ganglions lymphatiques régionau)" },
            { value: "Nic: Noduie(s) Lmoral, satellt(s} dans la sous-séreuse, ou dans le tissus non-péritonisés péri-coliques ou péri-rectaux sans métastase ganglionnaire régionale", label: "Nic: Noduie(s) Lmoral, satellt(s} dans la sous-séreuse, ou dans le tissus non-péritonisés péri-coliques ou péri-rectaux sans métastase ganglionnaire régionale" },
            { value: "N2: Métastase dans 2 4 ganglions lymphatiques régionaux", label: "N2: Métastase dans 2 4 ganglions lymphatiques régionaux" },
            { value: "N2a: Métastase dans 4-6 ganglions lymphatiques régionaux", label: "N2a: Métastase dans 4-6 ganglions lymphatiques régionaux" },
            { value: "N2b: _Métastase dans z 7 ganglions lymphatiques régionaux", label: "N2b: _Métastase dans z 7 ganglions lymphatiques régionaux" },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Voies biliaires' ? [
            { value: "Nx: non évalués", label: "Nx: non évalués" },
            { value: "N0: pas de ganglion métastatique", label: "N0. pas de ganglion Métastatique" },
            { value: "N1: Métastase (s) ganglionnaire (s) régionale (s)", label: "N1: Métastase (s) ganglionnaire (s) régionale (s)" },
            { value: "N1: 1 à 3 Métastases ganglionnaires régionnales", label: "N1: 1 à 3 Métastases ganglionnaires régionnales" },
            { value: "N2: ≥4 Métastases ganglionnaires régionnales", label: "N2: ≥4 Métastases ganglionnaires régionnales" },

        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Estomac' ? [
            { value: "Nx: non évalués", label: "Nx: non évalués" },
            { value: "N0: pas d'envahissement ganglionnaire (noter combien ganglions ont été examinés)", label: "N0: pas d'envahissement ganglionnaire (noter combien ganglions ont été examinés)" },
            { value: "N1: 1 ou 2 plus ganglions régionaux métastatiques", label: "N1: 1 àu 2 plus ganglions régionaux métastatiques" },
            { value: "N2: 3 à 6 plus ganglions régionaux métastatiques", label: "N2: 3 à 6 plus ganglions régionaux métastatiques" },
            { value: "N3: 7 ou plus ganglions régionaux métastatiques", label: "N3: 7 ou plus ganglions régionaux métastatiques" },
            { value: "N3a : 7 à 15 ganglions régionaux métastatiques", label: "N3a : 7 à 15 ganglions régionaux métastatiques" },
            { value: "N3b : 16 ou plus ganglions régionaux métastatiques", label: "N3b : 16 ou plus ganglions régionaux métastatiques" },


        ] : []),

        ...(data.Specialite === 'Digestif' && data.Organe === 'Oesophage' ? [
            { value: "Nx: ganglions non évalués", label: "Nx: ganglions non évalués" },
            { value: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux", label: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux" },
            { value: "N1: 1 ou 2 adénopaties envahies", label: "N1: 1 ou 2 adénopaties envahies" },
            { value: "N2: 3 à 6 adénopaties envahies", label: "N2: 3 à 6 adénopaties envahies" },
            { value: "N3: 7 adénopaties ou plus envahies", label: "N3: 7 adénopaties ou plus envahies" },

        ] : []),
        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Sein' ? [
            { value: "Nx: Appréciation impossible de atteinte ganglionnaire", label: "Nx: Appréciation impossible de atteinte ganglionnaire" },
            { value: "N0:  Absence de signes d'envahissement ganglionnaire régional.", label: " N0: Absence de signes d'envahissement ganglionnaire régional." },
            { value: "N1:  Ganglions axilaires homolatéraux mobiles", label: "N1:  Ganglions axilaires homolatéraux mobiles" },
            { value: "N2:   Ganglions axilaires homolatéraux fixés entre eux ou à d'autres structures où ganglions mammaires internes sans ganglion axillaire", label: "N2:  Ganglions axilaires homolatéraux fixés entre eux ou à d'autres structures où ganglions mammaires internes sans ganglion axillaire" },
            { value: "N3:  Ganglions sous ou sus claviculaires ou axilaires et mammaires internes", label: "N1:  Ganglions sous ou sus claviculaires ou axilaires et mammaires internes" },


        ] : []),
        ...(data.Specialite === 'Hématologie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' ? [
            { value: "Tumeur indéterminée des cellules dendritiques", label: "Tumeur indéterminée des cellules dendritiques" },
            { value: "Sarcome à cellules dendritiques interdigitées", label: "Sarcome à cellules dendritiques interdigitées" },

        ] : []),

        ...(data.Specialite === 'Thorax' && data.Organe === 'Poumon' ? [
            { value: "Nx: Envahissement locorégionale inconnu", label: "Nx: Envahissement locorégionale inconnu" },
            { value: "N0: Absence de métastase dans les ganglions lymphatiques régionnaux", label: "N0: Absence de métastase dans les ganglions lymphatiques régionnaux" },
            { value: "N1: Métastases ganglionnaires péri-rochiques homolatérales et/ou hillaires homolatérales incluant une extensions directes", label: "N1: Métastases ganglionnaires péri-rochiques homolatérales et/ou hillaires homolatérales incluant une extensions directes" },
            { value: "N2: Métastases dans les ganglions médiastinaux homolatéraux ou dans les ganglions sous-carénaires", label: "N2: Métastases dans les ganglions médiastinaux homolatéraux ou dans les ganglions sous-carénaires" },
            { value: "N3: Métastases ganglionnaires médiastinales controlatérales ou hilaires controlatérals ou scaléniques sus-caviculaires homo ou controlatérales", label: "N3: Métastases ganglionnaires médiastinales controlatérales ou hilaires controlatérals ou scaléniques sus-caviculaires homo ou controlatérales" },

        ] : []),
        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? [
            { value: "Nx: Ne peut pas être déterminé", label: "Nx:  Ne peut pas être déterminé" },
            { value: "N1: Une ou plusieurs adénopathies ipsilatérales < 6cm", label: "N1: Une ou plusieurs adénopathies ipsilatérales < 6cm" },
            { value: "N2: Adénopathies controlatérales ou bilatérales < 6cm", label: "N2: Adénopathies controlatérales ou bilatérales < 6cm" },
            { value: "N3: Adénopathies < 6cm", label: "N3: Adénopathies < 6cm" },

        ] : []),

        ...(data.Specialite === 'ORL' && data.Organe === 'Thyroide' ? [
            { value: "Nx, pNx : envahissement ganglionnaire non précisé", label: "Nx, pNx : envahissement ganglionnaire non précisé" },
            { value: "N0, pN0 : pas d’envahissement ganglionnaire", label: "N0, pN0 : pas d’envahissement ganglionnaire" },
            { value: "N0a : Ganglions bénins avec preuve histologique", label: "N0a : Ganglions bénins avec preuve histologique" },
            { value: "N0b : Pas d’atteinte gg clinique ou Checkboxlogique", label: "N0b : Pas d’atteinte gg clinique ou Checkboxlogique" },
            { value: "N1, pN1 : envahissement ganglionnaire", label: "N1, pN1 : envahissement ganglionnaire" },
            { value: "N1a, pN1a : métastases ganglionnaires homolatérales du groupe VI ou VII", label: "N1a, pN1a : métastases ganglionnaires homolatérales du groupe VI ou VII" },
            { value: "N1b, pN1b : autres métastases ganglionnaires cervicales (zones I à V) homolatérales controlatérales ou métastases rétropharyngées ou médiastinales supérieures.", label: "N1b, pN1b : autres métastases ganglionnaires cervicales (zones I à V) homolatérales controlatérales ou métastases rétropharyngées ou médiastinales supérieures." },


        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? [
            { value: "Nx:  Atteinte ganglionnaire non évaluable", label: "Nx: Atteinte ganglionnaire non évaluable" },
            { value: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux", label: "N0: pas de signe d'atteinte des ganglions lymphatiques régionnaux" },
            { value: "N1: Métastases unilatérale et/ou métatases uni/bilatérales retropharynguées, ≤ 6cm, au dessus du rebord inférieur du cartillage coricode ", label: "N1: Métastases unilatérale et/ou métatases uni/bilatérales retropharynguées, ≤ 6cm, au dessus du rebord inférieur du cartillage coricode" },
            { value: "N2: Métastases , ≤ 6cm, au dessus du rebord inférieur du cartillage coricode", label: "N2: Métastases , ≤ 6cm, au dessus du rebord inférieur du cartillage coricode" },
            { value: "N3: Métastases > 6cm et/ou extension jusqu'à calvicule", label: "N3: Métastases > 6cm et/ou extension jusqu'à calvicule" },


        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Ovaire') ? [
            { value: "IIA : Cancer étendu à l'utérus ou aux trompes de Fallope ou aux ovaires.", label: "IIA : Cancer étendu à l'utérus ou aux trompes de Fallope ou aux ovaires.." },
            { value: " IIB : Cancer étendu à d'autres organes pelviens tels que la vessie ou le rectum.", label: "IIB : Cancer étendu à d'autres organes pelviens tels que la vessie ou le rectum." },

        ] : []),


        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Voie excretrice') ? [
            { value: "NX : Les ganglions lymphatiques régionaux ne peuvent pas être évalués.", label: "NX : Les ganglions lymphatiques régionaux ne peuvent pas être évalués." },
            { value: "N0 : Pas de métastase dans les ganglions lymphatiques régionaux.", label: "N0 : Pas de métastase dans les ganglions lymphatiques régionaux." },
            { value: "N1 : Métastase dans un ganglion lymphatique régional.", label: "N1 : Métastase dans un ganglion lymphatique régional." },

        ] : []),


    ];
    const optionMétastase = [
        ...(data.Specialite === 'Digestif' && data.Organe === 'Anus' ? [
            { value: "Mx: non évaluées", label: "Mx; non évaluées" },
            { value: "M0: pas de localisation secondaire à distance de la tumeur primitive", label: "M0: pas de localisation secondaire à distance de la tumeur primitive" },
            { value: "M1: métastases à distance", label: "M1: métastases à distance" },
        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum' ? [
            { value: "MO : pas de métastase", label: "MO : pas de métastase" },
            { value: "M1: présence de métastase(s) à distance", label: "M1: présence de métastase(s) à distance" },
            { value: "Mia: Métastase(s) localisée(s) à un seul organe (foie, poumon, ovaire, ganglion(s) Iymphatique(s) autre que régional) sans métastase péritonéale", label: "Mia_Métastase(s) localisée(s) à un seul organe (foie, poumon, ovaire, ganglion(s) Iymphatique(s) autre que régional) sans métastase péritonéale" },
            { value: "Mib: Métastases dans plusieurs organes", label: "Mib: Métastases dans plusieurs organes" },
            { value: "Mic: Métastases péritonéales avec ou sans métastases dans les autres organes", label: "Mic: Métastases péritonéales avec ou sans métastases dans les autres organes" },

        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Voies biliaires' ? [
            { value: "MO : pas de métastase", label: "MO : pas de métastase" },
            { value: "M1 : métastase (s) à distance", label: "MO : métastase (s) à distance" },

        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Estomac' ? [
            { value: "MO : pas de métastase", label: "MO : pas de métastase" },
            { value: "M1 : métastase (s) à distance (dont ganglions rétro-pancréatiques, mésentériques, para-aortiques, sus-<laviculaires)", label: "MO : métastase à distance(dont ganglions rétro-pancréatiques, mésentériques, para-aortiques, sus-<laviculaires)" },

        ] : []),
        ...(data.Specialite === 'Digestif' && data.Organe === 'Oesophage' ? [
            { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
            { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },

        ] : []),

        ...(data.Specialite === 'Gynécologie' && (data.Organe === 'Ovaire' || data.Organe === 'Col' || data.Organe === 'Utérus' || data.Organe === 'Vagin' || data.Organe === 'Vulve') ? [
            { value: "M1  StadeIVB: métastase (s) à distance", label: "M1  StadeIVB : métastase (s) à distance" },

        ] : []),
        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Sein' ? [
            { value: "Mx : Détermination impossible de l'extension métastatique", label: "Mx : Détermination impossible de l'extension métastatique" },
            { value: "MO : absence de métastase à distance", label: "MO :  absence de métastase à distance" },
            { value: "M1 :Présence de métastase (s) à distance (comprenant des métastases ganglionnaires sus claviculaires)", label: "MO :Présence de  métastase (s) à distance (comprenant des métastases ganglionnaires sus claviculaires)" },


        ] : []),
        ...(data.Specialite === 'Thorax' && data.Organe === 'Poumon' ? [
            { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
            { value: "M1: Existence de métastase(s) ", label: "M1: Existence de métastase(s) " },
            { value: "M1a: Nodules tumoraux séparés dans un lobe controlatérale ou nodules pleuraux ou pleurésie maligne ou péricardite maligne", label: "M1a: Nodules tumoraux séparés dans un lobe controlatérale ou nodules pleuraux ou pleurésie maligne ou péricardite maligne" },
            { value: "M1b: 1 seule métastase dans un seul site métastatique ", label: "M1b: 1 seule métastase dans un seul site métastatique" },
            { value: "M1c: plusieurs métastases dans un seul ou plusieurs site atteints ", label: "M1c: plusieurs métastases dans un seul ou plusieurs site atteints" },

        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? [
            { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
            { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },

        ] : []),

        ...(data.Specialite === 'ORL' && data.Organe === 'Thyroide' ? [
            { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
            { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },
        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs cerebrales') ? [
            { value: "M0: Pas de dissémination", label: "M0: Pas de dissémination" },
            { value: "M1: cytologie positive du LCR.", label: "M1: cytologie positive du LCR." },
            { value: "M2: Ensemencement nodulaire macroscopique dans l'espace sous-arachnoïdien cérébelleux-cérébral et/ou dans le ventricule latéral ou le troisième ventricule.", label: "M2: Ensemencement nodulaire macroscopique dans l'espace sous-arachnoïdien cérébelleux-cérébral et/ou dans le ventricule latéral ou le troisième ventricule." },
            { value: "M3: Ensemencement nodulaire dans l'espace sous-arachnoïdien de la colonne vertébrale", label: "M3: Ensemencement nodulaire dans l'espace sous-arachnoïdien de la colonne vertébrale" },
            { value: "M4: Métastases extraneurales.", label: "M4: Métastases extraneurales." },

        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Ovaire') ? [
            { value: "IIIA1 : Cancer étendu uniquement aux ganglions lymphatiques rétropéritonéaux.", label: "IIIA1 : Cancer étendu uniquement aux ganglions lymphatiques rétropéritonéaux." },
            { value: "IIIA1(i) : Métastase ≤10 mm dans sa plus grande dimension.", label: "IIIA1(i) : Métastase ≤10 mm dans sa plus grande dimension." },
            { value: "IIIA1(ii) : Métastase >10 mm dans sa plus grande dimension.", label: "IIIA1(ii) : Métastase >10 mm dans sa plus grande dimension." },
            { value: "IIIA2 : Cancer microscopique en dehors du pelvis avec ou sans ganglions lymphatiques rétropéritonéaux positifs.", label: "IIIA2 : Cancer microscopique en dehors du pelvis avec ou sans ganglions lymphatiques rétropéritonéaux positifs." },
            { value: "IIIB : Métastase péritonéale macroscopique au-delà du pelvis ≤2 cm dans sa plus grande dimension.", label: "IIIB : Métastase péritonéale macroscopique au-delà du pelvis ≤2 cm dans sa plus grande dimension." },
            { value: "IIIC : Métastase péritonéale au-delà du pelvis >2 cm dans sa plus grande dimension, pouvant impliquer la capsule du foie ou de la rate mais non leur parenchyme.", label: "IIIC : Métastase péritonéale au-delà du pelvis >2 cm dans sa plus grande dimension, pouvant impliquer la capsule du foie ou de la rate mais non leur parenchyme." },

        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Voie excretrice') ? [
            ...(data.Specialite === 'ORL' && data.Organe === 'Thyroide' ? [
                { value: "MO : pas de métastase à distance", label: "MO : pas de métastase à distance" },
                { value: "M1 :Présence de métastase (s) à distance", label: "MO :Présence de  métastase (s) à distance" },
            ] : []),

        ] : []),


    ];

    const optionClassification = [
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Ovaire') ? [
            { value: "IVA : Cellules cancéreuses dans le liquide pleural.", label: "IVA : Cellules cancéreuses dans le liquide pleural." },
            { value: "· IVB : Métastases à distance incluant les métastases parenchymateuses au foie ou à la rate, métastases à des organes extra-abdominaux (incluant les ganglions lymphatiques inguinaux et les ganglions lymphatiques hors de la cavité abdominale).", label: "· IVB : Métastases à distance incluant les métastases parenchymateuses au foie ou à la rate, métastases à des organes extra-abdominaux (incluant les ganglions lymphatiques inguinaux et les ganglions lymphatiques hors de la cavité abdominale)." },

        ] : []),
    ]

    const optionSignesPhysiques = [

        ...(data.Specialite === 'Digestif' && data.Organe === 'Oesophage' ? [
            { value: "Adénopathie cervicale", label: "Adénopathie cervicale" },
            { value: "sus claviculaire", label: "sus claviculaire" },


        ] : []),

    ];

    const optionFacteurPronostique = [
        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? [
            { value: 'Stades III et IV', label: 'Stades III et IV' },
            { value: 'Envahissement GG et rupture capsulaire', label: 'Envahissement GG et rupture capsulaire' },
            { value: 'Emboles vasculaires', label: 'Emboles vasculaires' },
            { value: 'Marges positives après chirurgie', label: 'Marges positives après chirurgie' },
            { value: 'Surexpression EGFR : mauvais pronostic', label: 'Surexpression EGFR' },
            { value: 'HPV +', label: 'HPV +' },
        ] : []),
        ...(data.Specialite === 'ORL' && (data.Organe === 'Thyroide') ? [
            { value: 'Caractéristiques en rapport avec le patient', label: 'Caractéristiques en rapport avec le patient' },
            { value: 'Caractéristiques en rapport avec la tumeur', label: 'Caractéristiques en rapport avec la tumeur' },
            { value: 'Classification ATA 2015 du risque de rechute', label: 'Classification ATA 2015 du risque de rechute' },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Ovaire') ? [
            { value: 'Préménarche', label: 'Préménarche' },
            { value: 'Stade > I', label: 'Stade > I' },
            { value: 'résection chirurgicale incomplète', label: 'résection chirurgicale incomplète' },
            { value: 'histologie de tumeur vitelline', label: 'histologie de tumeur vitelline' },
            { value: 'Taille > 5cm', label: 'Taille > 5cm' },

        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Prostate') ? [
            { value: 'Forme alvéolaire', label: 'Forme alvéolaire' },
            { value: 'Exérèse incomplète', label: 'Exérèse incomplète' },
            { value: 'Taille > 5 cm', label: 'Taille > 5 cm' },
            { value: 'âge < 1an ou > 10 ans', label: 'âge < 1an ou > 10 ans' },
            { value: 'métastases', label: 'métastases' },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Rein') ? [
            { value: 'Type histologique', label: 'Type histologique' },
            { value: 'poids de la tumeur', label: 'poids de la tumeur' },
            { value: 'volume tumorale', label: 'volume tumorale' },
            { value: 'stade chirurgicale', label: 'stade chirurgicale' },

        ] : []),


    ];

    const optionSignesParacliniques = [
        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? [
            { value: "Endoscopie ORL", label: "Endoscopie ORL" },
            { value: "Pan-endoscopique", label: "Pan-endoscopique" },
            { value: "FOGD", label: "FOGD" },
            { value: "TDM", label: "TDM" },
            { value: "IRM", label: "IRM" },
            { value: "Pet-scan", label: "Pet-scan" },
            { value: "HPV", label: "HPV" },
            { value: "P16", label: "P16" },
            { value: "EBV", label: "EBV" },
            { value: "Panoramique dentaire", label: "Panoramique dentaire" },
        ] : []),
        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? [
            { value: "Checkbox thorax", label: "Checkbox thorax" },
            { value: "TDM", label: "TDM" },
            { value: "IRM", label: "IRM" },
            { value: "Scintigraphie", label: "Scintigraphie" },
            { value: "Pet-scan", label: "Pet-scan" },
            { value: "BB", label: "BB" },
            { value: "EVB", label: "EVB" },
            { value: "HLA", label: "HLA" },
            { value: "Nasofibroscopie", label: "Nasofibroscopie" },
            { value: "bilan cardiaque", label: "bilan cardiaque" },

        ] : []),
        ...(data.Specialite === 'ORL' && (data.Organe === 'Thyroide') ? [
            { value: "Endoscopie", label: "Endoscopie" },
            { value: "TDM", label: "TDM" },
            { value: "IRM", label: "IRM" },
            { value: "Pet-scan", label: "Pet-scan" },
            { value: "Scintigraphie", label: "Scintigraphie" },
            { value: "TSH", label: "TSH" },
            { value: "T3", label: "T3" },
            { value: "T4", label: "T4" },
            { value: "NEM 2", label: "NEM 2" },
            { value: "Calcitonine", label: "Calcitonine" },
            { value: "Thyréoglobuline", label: "Thyréoglobuline" },
        ] : []),

        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs orbitaires' || data.Organe === 'Tumeurs maxillo faciales' || data.Organe === 'Tumeurs médiastinales') ? [
            { value: "TDM CTAP", label: "TDM CTAP" },
            { value: "TD", label: "TD" },
            { value: "IRM Massif facial", label: "IRM Massif facial" },
            { value: "orbitocerebrale", label: "orbitocerebrale" },
            { value: "Examen anatomopathlogique", label: "Examen anatomopathlogique" },
            { value: "Medullogramme", label: "Medullogramme" },
            { value: "BOM", label: "BOM" },
            { value: "Scintigraphie osseuse", label: "Scintigraphie osseuse" },
            { value: "LCR", label: "LCR" },
            { value: "Scintigraphie au MIBG", label: "Scintigraphie au MIBG" },
            { value: "PET Scan", label: "PET Scan" },

        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Foie/Pédiatrie') ? [

            { value: "TDM", label: "TDM" },
            { value: "IRM Abdominale", label: "IRM Abdominale" },
            { value: "TDM thoracique", label: "TDM thoracique" },
            { value: "Scintigraphie osseuse", label: "Scintigraphie osseuse" },
            { value: "Examen anatomopathlogique", label: "Examen anatomopathlogique" },
            { value: "Medullogramme", label: "Medullogramme" },
            { value: "BOM", label: "BOM" },
        ] : []),
        ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'T. Sacro-coccygienne') ? [
            { value: "TDM", label: "TDM" },
            { value: "IRM ", label: "IRM " },
        ] : []),

        ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale' ? [
            { value: "GlucoC", label: "GlucoC" },
            { value: "minéralloC", label: "minéralloC" },
            { value: "Stéroides", label: "Stéroides" },
            { value: "TDM", label: "TDM" },
            { value: "IRM", label: "IRM" },
            { value: "Examen anatomopathlogique", label: "Examen anatomopathlogique" },
            { value: "Medullogramme", label: "Medullogramme" },
            { value: "Scintigraphie au MIBG", label: "Scintigraphie au MIBG" },
            { value: "PET Scan", label: "PET Scan" },
        ] : []),
        ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Parathyroïde ') ? [
            { value: "GlucoC", label: "GlucoC" },

        ] : []),



    ];

    const optionGradeHistopronostique = [

        ...(data.Specialite === 'Urologie' && data.Organe === 'Prostate' ? [
            { value: "Gleason score", label: "Gleason score" },

        ] : []),

        ...(data.Specialite === 'ORL' && data.Organe === 'Thyroide' ? [
            { value: "IHC (différenciation et Ki 67)", label: "IHC (différenciation et Ki 67)" },

        ] : []),

        ...(data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? [
            { value: "IHC", label: "IHC" },

        ] : []),

        ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Cortex de la glande surrénale') ? [
            { value: "score de Weiss", label: "score de Weiss" },

        ] : []),

    ];

    const [selectedCheckbox, setSelectedCheckbox] = useState([]);

    const handleChangeOption = (e) => {
        const value = e.target.value;
        setSelectedCheckbox((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value) // Retire la valeur si elle est déjà sélectionnée
                : [...prev, value] // Ajoute la valeur si elle n'est pas sélectionnée
        );
    };

    const allOptions = () => {
        let options = [];
        if (selectedCheckbox.includes('Rhabdomyosarcome')) {
            options = [
                { value: "Alvéolaire", label: "Alvéolaire" },
                { value: "Embryonnaire (fusiformes, botryoïde)", label: "Embryonnaire (fusiformes, botryoïde)" },
            ];
        };
        if (selectedCheckbox.includes('Carcinome')) {
            options = [
                { value: 'Urothélial', label: 'Urothélial' },
                { value: 'Transitionnel', label: 'Transitionnel' },
            ]
        };
        return options;
    };

    // Filtrer les options en fonction des cases cochées
    const filteredOptions = allOptions();
    const optionImmuno = [
        ...(data.Specialite === 'Gynécologie' && (data.Organe === 'Ovaire' || data.Organe === 'Col' || data.Organe === 'Utérus' || data.Organe === 'Vagin' || data.Organe === 'Vulve') ? [
            { value: 'Ovaire', label: 'Ovaire' },
            { value: 'Endométre', label: 'Endométre' },
            { value: 'Col', label: 'Col' },
            { value: 'Vagin', label: 'Vagin' },
            { value: 'Utérus', label: 'Utérus' },
        ] : []),
        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Sein' ? [
            { value: 'RH', label: 'RH' },
            { value: 'Her 2', label: 'Her 2' },
            { value: 'Ki 67', label: 'Ki 67' }

        ] : []),

        ...(data.Specialite === 'Thorax' && data.Organe === 'Plèvre' ? [
            { value: 'Calrétinine', label: 'Calrétinine' },
            { value: 'Antigène 1 de la tumeur de Wilms (WT1)', label: 'Antigène 1 de la tumeur de Wilms (WT1)' },
            { value: 'Antigène membranaire anti-épithélial (EMA)', label: 'Antigène membranaire anti-épithélial (EMA)' },
            { value: 'Cytokératine 5/6 (CK 5/6)', label: 'Cytokératine 5/6 (CK 5/6)' },
            { value: 'TTF1', label: 'TTF1' },
            { value: 'ACE', label: 'ACE' },
            { value: 'Anti-récepteurs œstrogène et progestérone (sexe féminin).', label: 'Anti-récepteurs œstrogène et progestérone (sexe féminin).' },


        ] : []),

        ...(data.Specialite === 'Thorax' && data.Organe === 'Poumon' ? [
            { value: 'TTF1', label: 'TTF1' },
            { value: 'P40', label: 'P40' },
            { value: 'Expression de PD-L1', label: 'Expression de PD-L1' }

        ] : []),


    ];

    const optionMedulloblasme = [
        { value: 'Médulloblasme classique', label: 'Medulloblasme classique' },
        { value: 'Médulloblastopme nodulaire/desmoplastique', label: 'Medulloblastopme nodulaire/desmoplastique' },
        { value: 'Médulloblasme à nodularité étendue', label: 'Médulloblasme à nodularité étendue' },
        { value: 'Médulloblasme à grandes cellule/anaplastique', label: 'Médulloblasme à grandes cellule/anaplastique' },

    ];


    const optionMutation = [
        ...(data.Specialite === 'Gynécologie' && data.Organe === 'Sein' ? [
            { value: 'BRCA1', label: 'BRCA1' },
            { value: 'BRCA2', label: 'BRCA2' },


        ] : []),

    ];
    const optionInvasion = [
        { value: '(nerfs crâniens, base du crâne) FDR de persistance tumorale', label: '(nerfs crâniens, base du crâne) FDR de persistance tumorale' },

    ];
    const optionEnvahissement = [

    ];
    const optionTailleTumoral = [

    ];
    const optionSexe = [
        { value: 'femme meilleur pronostic', label: 'femme meilleur pronostic' },

    ];
    const optionStade = [
        { value: 'stade I : tumeur totalement réséquée, de petite taille (< 100 g et < 200 cm3), et normalisation des dosages hormonaux en postopératoire ;', label: 'stade I : tumeur totalement réséquée, de petite taille (< 100 g et < 200 cm3), et normalisation des dosages hormonaux en postopératoire ;' },
        { value: 'stade II : tumeur totalement réséquée, de taille plus importante (≥ 100 g et ≥ 200 cm3), et', label: 'stade II : tumeur totalement réséquée, de taille plus importante (≥ 100 g et ≥ 200 cm3), et' },

    ];
    const optionAgeJeune = [
        { value: 'controversé mais semble être de bon pronostic', label: 'controversé mais semble être de bon pronostic' },

    ];
    const optionTaux = [];
    const optionHemoglobine = [
        { value: 'chute de l’Hb en cours de RT de mauvais pronostic', label: 'chute de l’Hb en cours de RT de mauvais pronostic' },

    ];
    const optionFacteurMoleculaire = [];

    const [inputData, setInputData] = useState({});
    const handleChange = (value, name) => {
        setInputData((prevInput) => ({
            ...prevInput,
            [name]: value,
        }));

    };
    const dispatch = useDispatch();


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const inputData = {
    //         DateDiagnostic: dateDiagnostic,
    //         SignesParacliniques: SignesParacliniques,

    //     };

    //     if (!(data.Specialite === 'Hématologie' || (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques'))) {
    //         inputData.DatePrelevement = datePrelevement;
    //     };
    //     if (data.Specialite === 'Thorax') {
    //         inputData.TechniquePrelevement = TechniquePrelevement;
    //         inputData.EtatGeneralPatient = EtatGeneralPatient;
    //     };

    //     if (data.Specialite === 'Thorax') {
    //     } else {
    //         inputData.MoyenDiagnostic = MoyenDiagnostic;
    //     }

    //     // Ajout d'autres champs en fonction des spécialités
    //     if (data.Specialite === 'Thorax') {
    //         // Ne rien ajouter pour 'Thorax'
    //     } else {
    //         inputData.PsOms = psOms;

    //         if (data.Specialite === 'Digestif' && data.Organe === 'Estomac') {
    //             // Logique spécifique pour Digestif et Estomac
    //             inputData.Poids = poids;
    //             inputData.Taille = taille;
    //         } else if (data.Specialite === 'Gynécologie' || data.Specialite === 'Urologie') {
    //             inputData.Poids = poids;
    //             inputData.Taille = taille;
    //         }

    //         if (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales') {
    //             inputData.PoidsAge = poidsAge;
    //             inputData.TailleAge = tailleAge;
    //         } else {
    //             inputData.IMC_SC = imcSc;
    //         }

    //         if (data.Specialite === 'Urologie' || (data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'LA Adulte' || data.Organe === 'Lymphome non hodgkinien adulte'))) {
    //             inputData.RapportPoidsTaille = poidsTaille;
    //             inputData.IMC_Age = imcAge;
    //         }

    //         if (data.Specialite === 'Pédiatrie') {
    //         } else if (data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'LA Adulte')) {
    //             inputData.SC = Sc;
    //         }
    //     };

    //     if (
    //         (data.Specialite === 'Digestif' && (data.Organe === 'Estomac' || data.Organe === 'Oesophage')) ||
    //         data.Specialite === 'Pédiatrie' ||
    //         data.Specialite === 'Gynécologie' ||
    //         data.Specialite === 'Hématologie' ||
    //         data.Specialite === 'Urologie' ||
    //         data.Specialite === 'Thorax' ||
    //         data.Specialite === 'ORL'
    //     ) {
    //         inputData.SignesPhysiques = SignesPhysiques;
    //     };

    //     // Ajoutez Siege et Taille si les conditions sont remplies
    //     if (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales') {
    //         inputData.Siege = siege;
    //         inputData.Taille = taille;
    //     }

    //     // Ajoutez les signes cliniques selon les conditions spécifiées
    //     if (
    //         (data.Specialite === 'Digestif' && (data.Organe === 'Estomac' || data.Organe === 'Oesophage')) ||
    //         data.Specialite === 'Pédiatrie' ||
    //         data.Specialite === 'Gynécologie' ||
    //         data.Specialite === 'Hématologie' ||
    //         data.Specialite === 'Urologie' ||
    //         data.Specialite === 'Thorax' ||
    //         data.Specialite === 'ORL'
    //     ) {
    //         // Ne rien ajouter si cette condition est remplie
    //     } else {
    //         // Ajoutez les signes cliniques basés sur la spécialité et l'organe
    //         if (data.Specialite === 'Digestif' && (data.Organe === 'Anus' || data.Organe === 'Voies biliaires' || data.Organe === 'Colon et rectum' || data.Organe === 'Pancreas' || data.Organe === 'Foie')) {
    //             inputData.SignesCliniques = SignesCliniques
    //         }
    //     };

    //     if (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales') {
    //         inputData.IRMCP = IRMCP;
    //         inputData.IRMMP = IRMMP;
    //         inputData.IRMCPO = IRMCPO;
    //         inputData.IRMMPO = IRMMPO;
    //         inputData.LCR = LCR;
    //         inputData.ExamenAnatomopathlogique = ExamenAnatomopathlogique;
    //         inputData.MargesResection = MargesResection;
    //         inputData.Medullogramme = Medullogramme;
    //     };
    //     // Ajout pour Urologie
    //     if (data.Specialite === 'Urologie' && (data.Organe === 'Prostate' || data.Organe === 'Testicule' || data.Organe === 'Penis')) {
    //         inputData.BilanPreTherapeutique = bilanPreTherapeutique;

    //     }

    //     // Ajout pour Media +++
    //     if (((data.Specialite === 'Digestif' && data.Organe === 'Estomac') ||
    //         data.Specialite === 'Gynécologie' ||
    //         data.Specialite === 'Hématologie' ||
    //         (data.Specialite === 'Urologie' && data.Organe === 'Penis') ||
    //         data.Specialite === 'Thorax' ||
    //         data.Specialite === 'ORL' ||
    //         data.Specialite === 'Pédiatrie')) {

    //         inputData.Media = media;
    //     };

    //     if ((data.Specialite === 'Hématologie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') || 
    //     (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques')) {

    //     inputData.NéoplasmesCellulesLangerhansAutresCellulesDendritiques = tumeur;
    //     inputData.NéoplasmesHistiocytesMacrophages = NéoplasmesHistiocytesMacrophages;
    // }else{
    //     if (!(data.Specialite === 'Gynécologie' || data.Specialite === 'Hématologie' || data.Specialite === 'Urologie' || data.Specialite === 'Pédiatrie' || data.Specialite === "Thorax" || data.Specialite === 'ORL')) {
    //         inputData.Malade = Malade;
    //     }

    //     if (!(data.Specialite === 'Hématologie' && (data.Organe === 'LA Adulte' || data.Organe === 'Leucémie Lymphoïde Chronique' || data.Organe === 'Leucémie myéloïde Chronique')) &&
    //         !(data.Specialite === 'Thorax' && data.Organe === 'Plèvre')) {
    //         inputData.TypeHistologique = TypeHistologies;
    //     }

    //     if (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales') {
    //         inputData.Medulloblasme = medulloblasme;
    //     }

    //     if (data.Specialite === 'Thorax' && data.Organe === 'Plèvre') {
    //         inputData.TypeHistologies = TypeHistologies; // Mésothéliome pleural malin
    //     }

    //     if (data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte') {
    //         inputData.TypeCistologie = TypeCistologie; // Type cistologique
    //     }

    //     if (data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique') {
    //         inputData.Cytogenetique = TypeHistologies; // Cytogénétique
    //         inputData.CytogenetiqueDel17p = CytogenetiqueDel17p; // Valeurs des checkboxes
    //     }
    //     if (
    //         !(data.Specialite === 'Gynécologie' && (data.Organe === 'Néoplasies trophoblastiques gestationnelles' || data.Organe === 'Sein') ||
    //           data.Specialite === 'Hématologie' ||
    //           data.Specialite === 'Pédiatrie' ||
    //           (data.Specialite === 'Urologie' && 
    //             (data.Organe === 'Vessie/Arbre urinaire' || 
    //              data.Organe === 'Testicule' || 
    //              data.Organe === 'Prostate' || 
    //              data.Organe === 'Penis' || 
    //              data.Organe === 'Surrenale' || 
    //              data.Organe === 'Reins')) || 
    //           data.Specialite === 'Thorax')
    //     ) {
    //         inputData.GradeHistopronostique = GradeHistopronostique; // Ajout de GradeHistopronostique
    //     }

    //     // Ajout des conditions pour Prostate et Thyroïde
    //     if ((data.Specialite === 'Urologie' && data.Organe === 'Prostate') || 
    //         (data.Specialite === 'ORL' && data.Organe === 'Thyroide')) {
    //         inputData.GradeHistopronostique = GradeHistopronostique; // Ajout de GradeHistopronostique
    //     }


    // }


    const handleCheckboxMedullogramme = (value) => {
        setMoyenDiagnosticMedullogramme(value);
    };

    const handleCheckboxCytogenetique = (value) => {
        setMoyenDiagnosticCytogenetique(value);
    };
    const handleCheckboxBiopsie = (value) => {
        setMoyenDiagnosticBiopsieOsteomedullaire(value);
    };

    const handleChangeResultat = (e) => {
        setMoyenDiagnosticCytogenetiqueResultats(e.target.value);
        setMoyenDiagnosticMedullogrammeResultats(e.target.value);
        setMoyenDiagnosticBiopsieOsteomedullaireResultats(e.target.value)
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Vérifiez si tous les champs sont vides

        if (!datePrelevement &&
            !dateDiagnostic &&
            !MoyenDiagnostic.length &&
            !psOms &&
            !poids &&
            !poidsAge &&
            !poidsTaille &&
            !taille &&
            !tailleAge &&
            !media &&
            !bilanPreTherapeutique.length &&
            !SignesPhysiques.length &&
            !SignesCliniques.length &&
            !SignesParacliniques.length &&
            !TypeHistologies.length &&
            !GradeHistopronostique.length &&
            !biologieMoleculaire.length) {
            notification.warning({
                message: 'Saisir d\'abord les informations requises',
                description: 'Veuillez remplir tous les champs obligatoires avant de soumettre.',
                duration: 6,
            });
            return;
        }



        inputData.DatePrelevement = datePrelevement;
        inputData.DateDiagnostic = dateDiagnostic;
        inputData.TechniquePrelevement = TechniquePrelevement;
        inputData.EtatGeneralPatient = EtatGeneralPatient;
        inputData.MoyenDiagnostic = MoyenDiagnostic;
        inputData.MoyenDiagnosticCytologie = moyenDiagnosticCytologie === true ? 'Oui' : 'Non';
        inputData.MoyenDiagnosticImmunophénotypageSanguin = moyenDiagnosticImmunophénotypageSanguin === true ? 'Oui' : 'Non';
        inputData.MoyenDiagnosticImmunophenotypageSanguinResultats = moyenDiagnosticImmunophenotypageSanguinResultats;
        inputData.MoyenDiagnosticHistologie = moyenDiagnosticHistologie === true ? 'Oui' : 'Non';
        inputData.MoyenDiagnosticHistologieResultats = moyenDiagnosticHistologieResultats;
        inputData.MoyenDiagnosticImuunohistochimie = moyenDiagnosticImuunohistochimie === true ? 'Oui' : 'Non';
        inputData.MoyenDiagnosticImuunohistochimieResultats = moyenDiagnosticImuunohistochimieResultats;
        inputData.MoyenDiagnosticMedullogramme = moyenDiagnosticMedullogramme === true ? 'Oui' : 'Non';
        inputData.MoyenDiagnosticBCRAB = MoyenDiagnosticBCRABL;
        inputData.CytogenetiqueT = CytogenetiqueT;
        inputData.CytogenetiqueAca = CytogenetiqueAca;
        inputData.MoyenDiagnosticMedullogrammeResultats = moyenDiagnosticMedullogrammeResultats;
        inputData.MoyenDiagnosticBiopsieOsteomedullaire = moyenDiagnosticBiopsieOsteomedullaire;
        inputData.MoyenDiagnosticBiopsieOsteomedullaireResultats = moyenDiagnosticBiopsieOsteomedullaireResultats;
        inputData.PsOms = psOms;
        inputData.Poids = poids;
        inputData.Taille = taille;
        inputData.PoidsAge = poidsAge;
        inputData.TailleAge = tailleAge;
        inputData.ImcSc = imcSc;
        inputData.PoidsTaille = poidsTaille;
        inputData.ImcAge = imcAge;
        inputData.SignesPhysiques = SignesPhysiques;
        inputData.SignesCliniques = SignesCliniques;
        inputData.Siege = siege;
        inputData.SignesParacliniques = SignesParacliniques;
        inputData.IRMCP = IRMCP;
        inputData.IRMMP = IRMMP;
        inputData.IRMCPO = IRMCPO;
        inputData.IRMMPO = IRMMPO;
        inputData.LCR = LCR;
        inputData.Sc = Sc;
        inputData.Biologie = biologie;
        inputData.ExamenAnatomopathlogique = ExamenAnatomopathlogique;
        inputData.MargesResection = MargesResection;
        inputData.Medullogramme = Medullogramme;
        inputData.BilanPreTherapeutique = bilanPreTherapeutique;
        inputData.Media = media;
        inputData.NéoplasmesHistiocytesMacrophages = NéoplasmesHistiocytesMacrophages;
        inputData.NéoplasmesCellulesLangerhansAutresCellulesDndritiques = NéoplasmesCellulesLangerhansAutresCellulesDndritiques;
        inputData.Malade = Malade;
        inputData.TypeHistologies = TypeHistologies || CytogenetiqueDel17p;
        inputData.Medulloblasme = MedulloblasmeSelect || medulloblasme;
        inputData.TypeCystologie = TypeCistologie;
        inputData.TypeCynetogenetique = CytogenetiqueDel17p;
        inputData.GradeHistopronostique = GradeHistopronostique;
        inputData.BiologieMoleculaire = biologieMoleculaire || BiologieMoleculaireMutationTp53;
        inputData.Tils = Tils;
        inputData.CytogenetiqueConventionnelle = cytogenetiqueConventionnelle;
        inputData.FISH = FISH;
        inputData.MarqueursTumoraux = MarqueursTumoraux;
        inputData.Immunohistochimie = immuno;
        inputData.EGFR = EGFR;
        inputData.Pdl1mTor = pdl1mTor;
        inputData.Invasionlocoregionale = invasion;
        inputData.EnvahissementGanglionnaire = Envahissement;
        inputData.TailleVolume = tailleTumoral;
        inputData.AgeJeune = ageJeune;
        inputData.Taux = taux;
        inputData.Sexe = sexe;
        inputData.Hemoglobine = Hemoglobine;
        inputData.AutreFacteurMoleculaire = facteurMoleculaire;
        inputData.ALK = ALK;
        inputData.CMET = CMET;
        inputData.ROSI = ROSI;
        inputData.KRAS = KRAS;
        inputData.BRAF = BRAF;
        inputData.HER2 = HER2;
        inputData.NTRK = NTRK;
        inputData.NRG1 = NRG1;
        inputData.RET = RET;
        inputData.MutationGenetique = mutationGenetique;
        inputData.FacteursPronostiques = FacteursPronostiques || facteurPronostique;
        inputData.StadeClinique = tumeur || adenopathie || Métastase || StadeClinique;
        inputData.StadeAnnArbor = tumeur;
        inputData.ScoreHasford = ScoreHasford;
        inputData.ScoreEUTOS = ScoreEUTOS;
        inputData.ClassificationBinet = ClassificationBinet;
        inputData.StadeSokal = StadeSokal;
        inputData.AtteinteLCR = AtteinteLCR;
        inputData.AtteinteTesticulaire = AtteinteTesticulaire;
        inputData.CorticoSensibilite = CorticoSensibilite;
        inputData.FacteurGenetique = FacteurGenetique;
        inputData.Phenotype = Phenotype;
        inputData.TypeCistologie = TypeCytologique;
        inputData.Age = Age;
        inputData.IMWG201 = IMWG2014;
        inputData.ClassificationAltmann = ClassificationAltmann;
        inputData.ClassificationISS = ClassificationISS;
        inputData.ClassificationSalmonDiurie = ClassificationSalmonDiurie;
        inputData.ClassificationISSRevisee = ClassificationISSRevisee;
        inputData.ClassificationISSReviseeStade = ClassificationISSReviseeStade;
        inputData.ClassificationSalmonDiurieStade = ClassificationSalmonDiurieStade;
        inputData.ClassificationISSStade = ClassificationISSStade;
        inputData.ClassificationOmsSyndromesMyelodysplasique = ClassificationOmsSyndromesMyelodysplasiques;
        inputData.Risque = Risque;
        inputData.StadePronostic = tumeur;
        inputData.MoyenDiagnosticCytologieResultats = moyenDiagnosticCytologieResultats
        inputData.PatientId = data.id || PatientId;
        inputData.RcpId = RcpId;
        inputData.UserId = data.UserId || UserId;
        inputData.BilanEvaluationPatient = BilanEvaluationPatient;
        inputData.BilanEvaluationMalade = BilanEvaluationMalade;
        inputData.Glucocorticoïdes = Glucocorticoïdes;
        inputData.Minéralocorticoïdes = Minéralocorticoïdes;
        inputData.SteroideSexuel = SteroideSexuel;
        inputData.Biologie = addBiologie;
        inputData.Biopsie = Biopsie;
        inputData.CatecholaminesUrinaires = CatecholaminesUrinaires;
        inputData.TA = TA;
        inputData.EndoscopieDigestive = endoscopieDigestive;
        inputData.Testicule = selectedCheckbox;
        inputData.ParaTesticule = selectedCheckbox;
        inputData.Testicule = Testicule;
        inputData.ParaTesticule = ParaTesticule;
        inputData.Seminome = Seminome;
        inputData.NonSeminome = NonSeminome;
        inputData.TumeurCelluleSL = TumeurCelluleSL;
        inputData.TumeurCelluleG = TumeurCelluleG;
        inputData.Stage = Risque;
        inputData.TypeTesticule = TypeTesticule;
        inputData.TumeursGerminaux = TumeursGerminaux;
        inputData.OsteosarcomeMR = OsteosarcomeMR;
        inputData.OsteosarcomeSH = OsteosarcomeSH;
        inputData.Classification = tumeur;
        inputData.Rhabdomyosarcome = selectedCheckbox;
        inputData.Carcinome = selectedCheckbox;
        inputData.Dysgerminome = Dysgerminome;
        inputData.Teratome = Teratome;
        inputData.Germinale = Germinale;
        inputData.Choriocarcinome = Choriocarcinome;
        inputData.TumeurSacVitellin = TumeurSacVitellin;
        inputData.CordonSexuel = CordonSexuel;
        inputData.ClassificationFigo = tumeur;
        inputData.KraBrafPik = KraBrafPik;
        inputData.Fox = Fox;
        inputData.PcinquanteTrois = PcinquanteTrois;
        inputData.Adenok = Adenok;
        inputData.ConsultationOncogetique = ConsultationOncogetique;
        inputData.ScorePronostic = ScorePronostic;
        inputData.ResultatFacteur = ResultatFacteur;










        console.log(inputData);

        try {
            const reponse = await dispatch(createDiagniosticRcp(inputData)).unwrap();
            // console.log(reponse.data);
            if (reponse.data) {
                // close();
                resetStates();
                notification.success({
                    message: reponse?.message,
                    duration: 6,
                });
            }

        } catch (error) {
            // Gestion de l'erreur lors de la création
            notification.error({
                message: error,
            });
        }




    };




    return (
        <form>
            {/* onSubmit={handleSubmit} */}

            <Row gutter={6}>
                <Col span={(data.Specialite === 'Hématologie' || (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques')) ? 24 : 12}>
                    <p className='inputPatient2'>Date du diagnostic</p>

                    <DatePicker name='DateDiagnostic' placeholder="Date du diagnostic" value={dateDiagnostic ? dayjs(dateDiagnostic) : null}
                        onChange={(date, dateString) => {
                            handleChange(dateString, 'DateDiagnostic');
                            setDateDiagnostic(dateString);
                        }}
                        style={{ width: '100%' }} />
                </Col>
                {data.Specialite === 'Hématologie' || data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques' ? (
                    <div></div>
                ) : (
                    <Col span={12}>
                        <p className='inputPatient2'>Date de prélèvement</p>

                        <DatePicker name='DatePrelevement' placeholder="Date de prélèvement" value={datePrelevement ? dayjs(datePrelevement) : null}
                            onChange={(date, dateString) => {
                                handleChange(dateString, 'DatePrelevement');
                                setDatePrelevement(dateString);
                            }}
                            style={{ width: '100%' }} />
                    </Col>
                )}
            </Row>

            {data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne' && (
                <Row>
                    <Col span={24}>
                        <DatePicker name='DelaiRenduResultat' placeholder="Délai rendu résultat" value={delaiRenduResultat ? dayjs(delaiRenduResultat) : null}
                            onChange={(date, dateString) => {
                                handleChange(dateString, 'DelaiRenduResultat');
                                setDelaiRenduResultat(dateString);
                            }}
                            style={{ width: '100%' }} />
                    </Col>
                </Row>

            )}

            {data.Specialite === 'Thorax' && (
                <Row gutter={6}>
                    <Col span={12}>
                        <p className='inputPatient2'>Technique de prélèvemen</p>

                        <Input name='TechniquePrelevement' value={TechniquePrelevement} onChange={(e) => setTechniquePrelevement(e.target.value)} placeholder="Technique de prélèvement" />
                    </Col>
                    <Col span={12}>
                        <p className='inputPatient2'>État général du patient</p>
                        <Select name='EtatGeneralPatient' value={EtatGeneralPatient} onChange={(value) => setEtatGeneralPatient(value)} style={{ width: "470px" }}>
                            <Option value="PS OMS">PS OMS</Option>
                            <Option value="IMC">IMC</Option>
                            <Option value="SC">SC</Option>
                        </Select>

                    </Col>
                </Row>
            )}

            {data.Specialite === 'Thorax' ? (
                <div></div>
            ) : (
                <div>
                    {data.Specialite === 'Hématologie' && data.Organe === 'Leucémie myéloïde Chronique' ? (
                        <>
                            <p style={{ fontWeight: "650" }}>Moyen diagnostique</p>
                            <Row gutter={6}>
                                {/* Affiche Médullogramme */}
                                <Col span={5}>
                                    <p style={{ fontWeight: "600" }}>Médullogramme</p>
                                    <Select
                                        name='MoyenDiagnosticMedullogramme'
                                        value={moyenDiagnosticMedullogramme}
                                        onChange={(value) => {
                                            setMoyenDiagnosticMedullogramme(value);
                                            updateMoyensDiagnostiques('Medullogramme', value);
                                        }}
                                        style={{ width: "185px" }}
                                    >
                                        <Option value="Chronique">Chronique</Option>
                                        <Option value="Accélérée">Accélérée</Option>
                                        <Option value="Acuitisée">Acuitisée</Option>
                                    </Select>
                                </Col>

                                {/* Affiche BCR-ABL */}
                                <Col span={5}>
                                    <p style={{ fontWeight: "600" }}>BCR-ABL</p>
                                    <Input
                                        name='MoyenDiagnosticBCRABL'
                                        placeholder='BCR-ABL'
                                        value={MoyenDiagnosticBCRABL}
                                        onChange={(e) => {
                                            setMoyenDiagnosticBCRABL(e.target.value);
                                            updateMoyensDiagnostiques('BCR_ABL', e.target.value);
                                        }}
                                    />
                                </Col>

                                {/* Affiche Cytogénétique */}
                                <Col span={4.5}>
                                    <p style={{ fontWeight: "600" }}>Cytogénétique</p>
                                    <Select
                                        name='MoyenDiagnosticCytologie'
                                        value={moyenDiagnosticCytologie}
                                        onChange={(value) => {
                                            setMoyenDiagnosticCytologie(value);
                                            updateMoyensDiagnostiques('Cytologie', value);
                                        }}
                                        style={{ width: "170px" }}
                                    >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>

                                {/* Affiche t(9 ;22) */}
                                <Col span={5}>
                                    <p style={{ fontWeight: "600" }}>t(9 ;22)</p>
                                    <Select
                                        name='CytogenetiqueT'
                                        value={CytogenetiqueT}
                                        onChange={(value) => {
                                            setCytogenetiqueT(value);
                                            updateMoyensDiagnostiques('CytogenetiqueT', value);
                                        }}
                                        style={{ width: "190px" }}
                                    >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>

                                {/* Affiche ACA */}
                                <Col span={4}>
                                    <p style={{ fontWeight: "600" }}>ACA</p>
                                    <Select
                                        name='CytogenetiqueAca'
                                        value={CytogenetiqueAca}
                                        onChange={(value) => {
                                            setCytogenetiqueAca(value);
                                            updateMoyensDiagnostiques('CytogenetiqueAca', value);
                                        }}
                                        style={{ width: "170px" }}
                                    >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>
                        </>
                    ) : (data.Specialite === 'Hématologie' && (data.Organe === 'Syndromes myélodysplasiques' || data.Organe === 'Syndrome Myéloprolifératif Ph1 négative')) ? (
                        <Row>
                            <Col span={24}>
                                <p className='inputPatient2'>Moyen diagnostique</p>

                                <Select
                                    mode="tags"
                                    value={MoyenDiagnostic}
                                    onChange={handleSelectChange}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {optionMoyenDiagnostic.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            {/* Affichage des checkboxes si les conditions sont remplies */}
                            {AfficheChexbox29 && (
                                <Col span={24} style={{ marginTop: "20px" }}>
                                    <Checkbox
                                        checked={moyenDiagnosticMedullogramme === true}
                                        onChange={() => handleCheckboxMedullogramme(true)}>
                                        Oui
                                    </Checkbox>
                                    <Checkbox
                                        checked={moyenDiagnosticMedullogramme === false}
                                        onChange={() => handleCheckboxMedullogramme(false)}>
                                        Non
                                    </Checkbox>
                                </Col>
                            )}

                            {AfficheChexbox30 && (
                                <Col span={24} style={{ marginTop: "20px" }}>
                                    <Checkbox
                                        checked={moyenDiagnosticCytogenetique === true}
                                        onChange={() => handleCheckboxCytogenetique(true)}>
                                        Oui
                                    </Checkbox>
                                    <Checkbox
                                        checked={moyenDiagnosticCytogenetique === false}
                                        onChange={() => handleCheckboxCytogenetique(false)}>
                                        Non
                                    </Checkbox>
                                </Col>
                            )}

                            {AfficheChexbox31 && (
                                <Col span={24} style={{ marginTop: "20px" }}>
                                    <Checkbox
                                        checked={moyenDiagnosticBiopsieOsteomedullaire === true}
                                        onChange={() => handleCheckboxBiopsie(true)}>
                                        Oui
                                    </Checkbox>
                                    <Checkbox
                                        checked={moyenDiagnosticBiopsieOsteomedullaire === false}
                                        onChange={() => handleCheckboxBiopsie(false)}>
                                        Non
                                    </Checkbox>
                                </Col>
                            )}

                            {/* Affiche le champ de texte si "Oui" est coché */}
                            {(moyenDiagnosticMedullogramme === true || moyenDiagnosticCytogenetique === true || moyenDiagnosticBiopsieOsteomedullaire === true) && (
                                <Row>
                                    <Col span={24}>
                                        <p>Décrire le résultat ici</p>
                                        <TextArea
                                            name='MoyenDiagnosticCytologieResultats'
                                            value={moyenDiagnosticCytologieResultats || moyenDiagnosticMedullogramme || moyenDiagnosticBiopsieOsteomedullaire}
                                            onChange={handleChangeResultat}
                                            placeholder="Décrire ici..."
                                            style={{ width: '950px' }}
                                        />
                                    </Col>
                                </Row>

                            )}
                        </Row>)
                        : (
                            <Row>
                                <Col span={24}>
                                    <p className='inputPatient2'>Moyen diagnostique</p>

                                    <Select
                                        mode="tags"
                                        value={MoyenDiagnostic}
                                        onChange={handleSelectChange}
                                        style={{ width: "100%", height: "33px", }}
                                    >
                                        {optionMoyenDiagnostic.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                {AfficheChexbox && (
                                    <Col span={24} style={{ marginTop: "20px" }}>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Oui')}
                                            onChange={() => handleCheckboxChange('Oui')}
                                        >
                                            Oui
                                        </Checkbox>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Non')}
                                            onChange={() => handleCheckboxChange('Non')}
                                        >
                                            Non
                                        </Checkbox>

                                    </Col>
                                )}

                                {AfficheChexbox6 && (
                                    <Col span={24} style={{ marginTop: "20px" }}>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Matutes 4-5')}
                                            onChange={() => handleCheckboxChange('Matutes 4-5')}
                                        >
                                            Matutes 4-5
                                        </Checkbox>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('Matutes 3')}
                                            onChange={() => handleCheckboxChange('Matutes 3')}
                                        >
                                            Matutes 3
                                        </Checkbox>

                                    </Col>
                                )}
                                {AfficheChexbox20 && (
                                    <Col span={24} style={{ marginTop: "20px" }}>

                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('AFP')}
                                            onChange={() => handleCheckboxChange('AFP')}
                                        >
                                            AFP
                                        </Checkbox>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('HCG')}
                                            onChange={() => handleCheckboxChange('HCG')}
                                        >
                                            HCG
                                        </Checkbox>

                                    </Col>
                                )}

                                {AfficheChexbox21 && (
                                    <Col span={24} style={{ marginTop: "20px" }}>
                                        <Checkbox
                                            checked={MoyenDiagnostic.includes('pièce d’exérèse')}
                                            onChange={() => handleCheckboxChange('pièce d’exérèse')}
                                        >
                                            pièce d’exérèse
                                        </Checkbox>

                                    </Col>
                                )}

                                {MoyenDiagnostic.includes('Oui') && (
                                    <Row>
                                        <p>Décrire le résultat ici</p>
                                        <Col span={24} >
                                            <TextArea name='MoyenDiagnosticCytologieResultats' value={moyenDiagnosticCytologieResultats} onChange={(e) => setMoyenDiagnosticCytologieResultats(e.terget.value)} placeholder="Décrire ici..." style={{ width: '950px' }} />
                                        </Col>
                                    </Row>
                                )}
                            </Row>
                        )}

                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine' && (
                        <Row gutter={6}>
                            <Col span={12}>
                                <p className='inputPatient2'>Catécholamines urinaires</p>
                                <Select
                                    mode="tags"
                                    value={CatecholaminesUrinaires}
                                    onChange={(value) => setCatecholaminesUrinaires(value)}
                                    style={{ width: "100%", height: "33px", }}
                                >
                                    {optionCatecholaminesUrinaires.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>                                </Col>
                            <Col span={12}>
                                <p className='inputPatient2'>
                                    Biopsie
                                </p>
                                <Select mode='tags'
                                    name='Biopsie'
                                    value={Biopsie}
                                    onChange={(value) => setBiopsie(value)}

                                    style={{ width: "100%", height: "33px", }}>
                                    <Option value="Biopsie échoguidée">Biopsie échoguidée</Option>
                                    <Option value="biopsie chirurgicale">Biopsie chirurgicale</Option>

                                </Select>
                            </Col>
                        </Row>
                    )}
                    <Row gutter={6}>
                        <Col span={((data.Specialite === 'Digestif' && data.Organe === 'Estomac') || (data.Specialite === 'Hématologie') || (data.Specialite === 'Urologie') ||
                            (data.Specialite === 'Gynécologie')) ? 8 : 12}>
                            <p className='inputPatient2'>PS OMS</p>

                            <Input name='PsOms' value={psOms} onChange={(e) => setPsOms(e.target.value)} placeholder="PS OMS" />

                        </Col>
                        {((data.Specialite === 'Digestif' && data.Organe === 'Estomac') ||
                            data.Specialite === 'Gynécologie' || data.Specialite === 'Hématologie' || (data.Specialite === 'Urologie') || (data.Specialite === 'ORL')) && (
                                <>
                                    <Col span={data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx' || data.Organe === 'Cavité buccale' || data.Organe === 'Thyroide' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire') ? 12 : 8}>
                                        <p className='inputPatient2'>Poids</p>

                                        <Input name='Poids' value={poids} onChange={(e) => setPoids(e.target.value)} placeholder="Poids" />

                                    </Col>
                                    <Col span={data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx' || data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire' || data.Organe === 'Thyroide') ? 12 : 8}>
                                        <p className='inputPatient2'>Taille</p>

                                        <Input name='Taille' value={taille} onChange={(e) => setTaille(e.target.value)} placeholder="Taille" />

                                    </Col>
                                </>
                            )}

                        {(data.Specialite === 'Pédiatrie') && (
                            <>
                                <Col span={12}>
                                    <p className='inputPatient2'>Poids/âge</p>

                                    <Input name='PoidsAge' value={poidsAge} onChange={(e) => setPoidsAge(e.target.value)} placeholder="Poids" />

                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>Taille/âge</p>

                                    <Input name='TailleAge' value={tailleAge} onChange={(e) => setTailleAge(e.target.value)} placeholder="Taille" />

                                </Col>
                            </>
                        )}


                        {(data.Specialite === 'Pédiatrie') ? (
                            <div></div>
                        ) : (

                            <Col span={((data.Specialite === 'Digestif' && data.Organe === 'Estomac') || (data.Specialite === 'Hématologie') ||
                                (data.Specialite === 'Gynécologie') || (data.Specialite === 'Urologie')) ? 24 : 12}>
                                <p className='inputPatient2'>IMC/SC</p>

                                <Input name='ImcSc' value={imcSc} onChange={(e) => setImcSc(e.target.value)} placeholder="IMC/SC" />

                            </Col>
                        )}
                        {(data.Specialite === 'Urologie' || data.Specialite === 'Cancers Rares' || data.Specialite === 'Pédiatrie' || data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'LA Adulte' || data.Organe === 'Lymphome non hodgkinien adulte')) && (
                            <>
                                <Col
                                    span={
                                        (data.Specialite === 'Pédiatrie' && data.Organe !== 'Néoplasmes des cellules histiocytaires/dendritiques') ||
                                            data.Specialite === 'Cancers Rares' ? 12 :
                                            (data.Specialite === 'Hématologie' && data.Organe === 'Lymphome non hodgkinien adulte') ||
                                                (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') ? 24 :
                                                8
                                    }
                                >
                                    <p className='inputPatient2'>Rapport poids/taille</p>
                                    <Input
                                        name='PoidsTaille'
                                        value={poidsTaille}
                                        onChange={(e) => setPoidsTaille(e.target.value)}
                                        placeholder="Rapport poids/taille"
                                    />
                                </Col>

                                <Col
                                    span={
                                        ((data.Specialite === 'Hématologie' && data.Organe === 'Lymphome non hodgkinien adulte') ||
                                            data.Specialite === 'Cancers Rares') ? 12 :
                                            data.Specialite === 'Pédiatrie' ? 24 :
                                                8
                                    }
                                >
                                    <p className='inputPatient2'>IMC/âge </p>
                                    <Input
                                        name='ImcAge'
                                        value={imcAge}
                                        onChange={(e) => setImcAge(e.target.value)}
                                        placeholder="IMC/âge"
                                    />
                                </Col>
                                {data.Specialite === 'Pédiatrie' ? (
                                    <div></div>
                                ) : (data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'Syndrome Myéloprolifératif Ph1 négative' || data.Organe === 'Syndromes myélodysplasiques' || data.Organe === 'LA Adulte')) && (
                                    <Col span={8}>
                                        <p className='inputPatient2'>SC</p>
                                        <Input name='Sc' value={Sc} onChange={(e) => setSc(e.target.value)} placeholder="SC" />
                                    </Col>
                                )}
                            </>
                        )}

                    </Row>
                </div>)}

            {((data.Specialite === 'Digestif' && data.Organe === 'Estomac' || data.Organe === 'Oesophage')
                || data.Specialite === 'Pédiatrie' || data.Specialite === 'Cancers Rares' ||
                (data.Specialite === 'Gynécologie') || (data.Specialite === 'Hématologie') || (data.Specialite === 'Urologie') || (data.Specialite === 'Thorax') || data.Specialite === 'ORL') && (
                    <Row>

                        <Col span={24}>
                            <p className='inputPatient2'>Signes physiques</p>

                            <Select
                                mode="tags"
                                value={SignesPhysiques}
                                onChange={(value) => setSignesPhysiques(value)}
                                style={{ width: "100%", height: "10vh", }}
                            >
                                {optionSignesPhysiques.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>

                        </Col>
                    </Row>

                )}

            {data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine' && (
                <Row gutter={6}>
                    <Col span={12}>
                        <p className='inputPatient2'>TA</p>
                        <Select mode='tags' name='TA' value={TA} onChange={(value) => setTA(value)}
                            style={{ width: "100%", height: "33px", }}>

                        </Select>                                </Col>
                    <Col span={12}>
                        <p className='inputPatient2'>
                            Endoscopie digestive
                        </p>
                        <Select mode='tags' value={endoscopieDigestive} onChange={(value) => setEndoscopieDigestive(value)}
                            style={{ width: "100%", height: "33px", }}>

                        </Select>
                    </Col>
                </Row>
            )}

            {(data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales' || data.Organe === 'Tumeurs maxillo faciales') && (

                <Row gutter={6}>
                    {data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs orbitaires' || data.Organe === 'Tumeurs médiastinales') && (

                        <Col span={12}>
                            <p className='inputPatient2'>Siege</p>
                            <Input name='Siege' value={siege} onChange={(e) => setSiege(e.target.value)} placeholder='Siege' />
                        </Col>
                    )}


                    <Col span={12}>
                        <p className='inputPatient2'>Taille</p>
                        <Input name='Taille' value={taille} onChange={(e) => setTaille(e.target.value)} placeholder='Taille' />
                    </Col>
                </Row>
            )}

            {(data.Specialite === 'Digestif' && (data.Organe === 'Estomac' || data.Organe === 'Oesophage')
                || data.Specialite === 'Pédiatrie' || data.Specialite === 'Cancers Rares' ||
                (data.Specialite === 'Gynécologie') || (data.Specialite === 'Hématologie') || (data.Specialite === 'Urologie') || data.Specialite === 'Thorax' || data.Specialite === 'ORL') ? (
                <></>
            )
                : (

                    <Row>
                        <Col>
                            <p className='inputPatient2'>Signes cliniques</p>

                            {(data.Specialite === 'Digestif' && data.Organe === 'Anus') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('STENOSE')}
                                        onChange={() => handleCheckboxChange('STENOSE')}
                                    >
                                        STENOSE
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('INCONTINENCE')}
                                        onChange={() => handleCheckboxChange('INCONTINENCE')}
                                    >
                                        INCONTINENCE
                                    </Checkbox>
                                </>
                            ) : (data.Specialite === 'Digestif' && data.Organe === 'Voies biliaires') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('ictère')}
                                        onChange={() => handleCheckboxChange('ictère')}
                                    >
                                        ictère
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('douleur')}
                                        onChange={() => handleCheckboxChange('douleur')}
                                    >
                                        douleur
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('masse')}
                                        onChange={() => handleCheckboxChange('masse')}
                                    >
                                        masse
                                    </Checkbox>
                                </>
                            ) : (data.Specialite === 'Digestif' && data.Organe === 'Colon et rectum') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('Occlusion')}
                                        onChange={() => handleCheckboxChange('Occlusion')}
                                    >
                                        Occlusion
                                    </Checkbox>
                                </>
                            ) : (data.Specialite === 'Digestif' && data.Organe === 'Pancreas') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('Ictère')}
                                        onChange={() => handleCheckboxChange('Ictère')}
                                    >
                                        Ictère
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('Sténose digestive')}
                                        onChange={() => handleCheckboxChange('Sténose digestive')}
                                    >
                                        Sténose digestive
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('douleur')}
                                        onChange={() => handleCheckboxChange('douleur')}
                                    >
                                        douleur
                                    </Checkbox>
                                    <Checkbox
                                        checked={SignesCliniques.includes('masse')}
                                        onChange={() => handleCheckboxChange('masse')}
                                    >
                                        masse
                                    </Checkbox>
                                </>
                            ) : (data.Specialite === 'Digestif' && data.Organe === 'Foie') ? (
                                <>
                                    <Checkbox
                                        checked={SignesCliniques.includes('HTP')}
                                        onChange={() => handleCheckboxChange('HTP')}
                                    >
                                        HTP
                                    </Checkbox>
                                </>
                            ) : null}
                        </Col>
                    </Row>
                )}


                    <p className='inputPatient2'>Signes paracliniques – Bilan d’évaluation Malade</p>
                    <Row gutter={8}>
                            <Col span={6}>
                                <p className='inputPatient2'>Date</p>
                                <DatePicker placeholder="date"
                                    onChange={handleDateChangeExamClinique}
                                    value={examParaClinique.date ? dayjs(examParaClinique.date) : null}
                                    style={{ width: '100%' }} />
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>Type</p>
                                <Select
                                    onChange={handleChangeExamParaClinique}
                                    value={examParaClinique.type}
                                    style={{ width: '100%' }}>
                                </Select>
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>Resultat</p>
                                <TextArea
                                    placeholder="Décrire ici..."
                                    onChange={handleChangeResultatExamParaClinique}
                                    value={examParaClinique.resultat}
                                    style={{ width: '100%', height: "33px" }}
                                />
                            </Col>
                            <Col span={6} style={{ alignItems: 'center', marginTop: "33px", }}>
                                <Button type="button" onClick={handleAdd}>
                                    Ajouter
                                </Button>
                            </Col>

                            <ul style={{ marginLeft: "50px" }}>
                                {SignesParacliniques.map((data, index) => (
                                    <li key={index}>
                                        <strong>Date :</strong> {data.date},
                                        <strong> Type :</strong> {data.type},
                                        <strong> Résultat :</strong> {data.resultat}
                                    </li>
                                ))}
                            </ul>
                            
                        </Row>
                


            {(data.Specialite === 'Cancers Rares' && data.Organe === 'Parathyroïde') && (
                <Row guter={6}>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation du patient</p>
                        <Select mode='tags'
                            name='BilanEvaluationPatient'
                            value={BilanEvaluationPatient}
                            onChange={(value) => setBilanEvaluationPatient(value)}
                            style={{ width: "470px" }}>

                        </Select>
                    </Col>                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation de la maladie</p>
                        <Select mode='tags'
                            name='BilanEvaluationMalade'
                            value={BilanEvaluationMalade}
                            onChange={(value) => setBilanEvaluationMalade(value)}
                            style={{ width: "470px" }}>
                            <Option value="Calcémie">Calcémie</Option>
                            <Option value="Parathormone (PTH)">Parathormone (PTH)</Option>
                            <Option value="PAL">PAL</Option>
                            <Option value="LDH">LDH</Option>
                            <Option value="Phosphoremie">Phosphoremie</Option>
                        </Select>
                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan pré thérapeutique </p>
                        <Select mode='tags'
                            name='BilanPreTherapeutique'
                            value={bilanPreTherapeutique}
                            onChange={(value) => setBilanPreTherapeutique(value)}
                            style={{ width: "950px" }}>

                        </Select>
                    </Col>
                </Row>

            )}

            {(data.Specialite === 'Cancers Rares' && data.Organe === 'Cortex de la glande surrénale') && (
                <Row gutter={6}>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation du patient </p>
                        <Select mode='tags'
                            name='BilanEvaluationPatient'
                            value={BilanEvaluationPatient}
                            onChange={(value) => setBilanEvaluationPatient(value)}
                            style={{ width: "310px" }}>

                        </Select>                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation de la maladie</p>
                        <Select mode='tags'
                            name='BilanEvaluationMalade'
                            value={BilanEvaluationMalade}
                            onChange={(value) => setBilanEvaluationMalade(value)}
                            style={{ width: "310px" }}>

                        </Select>
                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Glucocorticoïdes</p>
                        <Select mode='tags'
                            name='Glucocorticoïdes'
                            value={Glucocorticoïdes}
                            onChange={(value) => setGlucocorticoïdes(value)}
                            style={{ width: "310px" }}>
                            <Option value="cortisol +/-  test de freinage à la dexaméthasone">cortisol +/-  test de freinage à la dexaméthasone</Option>
                        </Select>
                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Minéralocorticoïdes </p>
                        <Select mode='tags'
                            name='Minéralocorticoïdes'
                            value={Minéralocorticoïdes}
                            onChange={(value) => setMinéralocorticoïdes(value)}
                            style={{ width: "310px" }}>
                            <Option value="aldostérone">aldostérone</Option>
                            <Option value="rénine">rénine</Option>

                        </Select>
                    </Col>
                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Stéroïdes sexuels </p>
                        <Select mode='tags'
                            name='SteroideSexuel'
                            value={SteroideSexuel}
                            onChange={(value) => setSteroideSexuel(value)}
                            style={{ width: "310px" }}>
                            <Option value="testostérone">testostérone</Option>
                            <Option value="estradiol">estradiol</Option>
                            <Option value="leurs précurseurs (DHEAS, androstènedione, 17 hydroxy-progestérone…)">leurs précurseurs (DHEAS, androstènedione, 17 hydroxy-progestérone…)</Option>

                        </Select>
                    </Col>

                    <Col span={8}>
                        <p style={{ fontWeght: "650px" }}>Bilan pré thérapeutique </p>
                        <Select mode='tags'
                            name='bilanPreTherapeutique'
                            value={bilanPreTherapeutique}
                            onChange={(value) => setBilanPreTherapeutique(value)}
                            style={{ width: "310px" }}>

                        </Select>
                    </Col>
                </Row>

            )}

            {(data.Specialite === 'Cancers Rares' && (data.Organe === 'Medullosurrenale' || data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique')) && (
                <Row gutter={6}>
                    <Col>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation du patient </p>
                        <Select mode='tags'
                            name='BilanEvaluationPatient'
                            value={BilanEvaluationPatient}
                            onChange={(value) => setBilanEvaluationPatient(value)}
                            style={{ width: "470px" }}>

                        </Select>                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Bilan d’évaluation de la maladie</p>
                        <Select mode='tags'
                            name='BilanEvaluationMalade'
                            value={BilanEvaluationMalade}
                            onChange={(value) => setBilanEvaluationMalade(value)}
                            style={{ width: "470px" }}>

                        </Select>
                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des métanéphrines libres plasmatiques</p>
                        <Input
                            name="metanephrinesPlasma"
                            placeholder="Dosage des métanéphrines plasmatique (ng/mL)"
                            value={metanephrinesPlasma}
                            onChange={(e) => {
                                setMetanephrinesPlasma(e.target.value);
                                Biologie('metanephrinesPlasma', e.target.value);
                            }}

                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des normétanéphrines libres plasmatiques</p>
                        <Input
                            name="normetanephrinesPlasma"
                            placeholder="Dosage des normétanéphrines plasmatique (ng/mL)"
                            value={normetanephrinesPlasma}
                            onChange={(e) => {
                                setNormetanephrinesPlasma(e.target.value);
                                Biologie('normetanephrinesPlasma', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des métanéphrines dans les urines de 24h</p>
                        <Input
                            name="metanephrinesUrine"
                            placeholder="Dosage des métanéphrines dans les urines (ng/24h)"
                            value={metanephrinesUrine}
                            onChange={(e) => {
                                setMetanephrinesUrine(e.target.value);
                                Biologie('metanephrinesUrine', e.target.value);
                            }} />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage des normétanéphrines dans les urines de 24h</p>
                        <Input
                            name="normetanephrinesUrine"
                            placeholder="Dosage des normétanéphrines dans les urines (ng/24h)"
                            value={normetanephrinesUrine}
                            onChange={(e) => {
                                setNormetanephrinesUrine(e.target.value);
                                Biologie('normetanephrinesUrine', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Créatininurie des 24 heures</p>
                        <Input
                            name="creatininurie24h"
                            placeholder="Créatininurie des 24h (mg/24h)"
                            value={creatininurie24h}
                            onChange={(e) => {
                                setCreatininurie24h(e.target.value);
                                Biologie('creatininurie24h', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Dosage de la chromogranine A dans le sang</p>
                        <Input
                            name="chromogranineA"
                            placeholder="Dosage de la chromogranine A (ng/mL)"
                            value={chromogranineA}
                            onChange={(e) => {
                                setChromogranineA(e.target.value);
                                Biologie('chromogranineA', e.target.value);
                            }}
                        />
                    </Col>

                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Taux de potassium </p>
                        <Input
                            name="tauxPotassium"
                            placeholder="Taux de potassium "
                            value={tauxPotassium}
                            onChange={(e) => {
                                setTauxPotassium(e.target.value);
                                Biologie('tauxPotassium', e.target.value);
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <p style={{ fontWeght: "650px" }}>Glycémie</p>
                        <Input
                            name="glycemie"
                            placeholder="Glycémie"
                            value={glycemie}
                            onChange={(e) => {
                                setGlycemie(e.target.value);
                                Biologie('glycemie', e.target.value);
                            }}
                        />
                    </Col>


                </Row>

            )}

            {data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine' && (
                <Row gutter={6}>
                    <Col span={24}>
                        <p style={{ fontWeight: "950px" }}>Marqueurs</p>
                        <Select mode='tags' name='TA' value={MarqueursTumoraux} onChange={(value) => setMarqueursTumoraux(value)}
                            style={{ width: "950px", height: "33px", }}>
                            {optionMarqueursTumoraux.map((signe) => (
                                <Option key={signe} value={signe}>{signe}</Option>
                            ))}

                        </Select>                                </Col>

                </Row>
            )}

            {(data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale') && (
                <Row>
                    <Col span={24}>
                        <p className='inputPatient2'>Stade</p>
                        <Select
                            mode="tags"
                            value={StadeClinique}
                            onChange={(value) => setStadeClinique(value)}
                            style={{ width: '100%' }}
                        >
                            {optionStade.map((stade) => (
                                                    <Option key={stade.value} value={stade.value}>{stade.label}</Option>
                                                ))}
                        </Select>

                    </Col>
                </Row>
            )}


            {data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales' && (
                <>
                    <Row gutter={6}>
                        <Col span={6}>
                            <p className='inputPatient2'>IRM cérébrale préopératoire</p>
                            <Input name='IRMCP' value={IRMCP} onChange={(e) => setIRMCP(e.target.value)} placeholder='IRM cérébrale préopératoire' />
                        </Col>

                        <Col span={6}>
                            <p className='inputPatient2'>IRM médullaire préopératoire</p>
                            <Input name='IRMMP' value={IRMMP} onChange={(e) => setIRMMP(e.target.value)} placeholder='IRM médullaire préopératoire' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>IRM cérébrale post opératoire</p>
                            <Input name='IRMCPO' value={IRMCPO} onChange={(e) => setIRMCPO(e.target.value)} placeholder='IRM cérébrale post opératoire' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>IRM médullaire post opératoire</p>
                            <Input name='IRMMPO' value={IRMMPO} onChange={(e) => setIRMMPO(e.target.value)} placeholder='IRM médullaire post opératoire' />
                        </Col>
                    </Row>
                    <Row gutter={6}>
                        <Col span={6}>
                            <p className='inputPatient2'>LCR</p>
                            <Input name='LCR' value={LCR} onChange={(e) => setLCR(e.target.value)} placeholder='LCR' />
                        </Col>

                        <Col span={6}>
                            <p className='inputPatient2'>Examen anatomopathlogique</p>
                            <Input name='ExamenAnatomopathlogique' value={ExamenAnatomopathlogique} onChange={(e) => setExamenAnatomopathlogique(e.target.value)} placeholder='Examen anatomopathlogique' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>Marges de résection : Saine/Infiltrées</p>
                            <Input name='MargesResection' value={MargesResection} onChange={(e) => setMargesResection(e.target.value)} placeholder='Marges de résection : Saine/Infiltrées' />
                        </Col>
                        <Col span={6}>
                            <p className='inputPatient2'>Médullogramme</p>
                            <Input name='Medullogramme' value={Medullogramme} onChange={(e) => setMedullogramme(e.target.value)} placeholder='Médullogramme' />
                        </Col>
                    </Row>
                </>
            )}


            {(data.Specialite === 'Urologie' && (data.Organe === 'Prostate' || data.Organe === 'Testicule' || data.Organe === 'Penis')) && (
                <>
                    <p className='inputPatient2'>Bilan pré thérapeutique</p>

                    <Row gutter={6}>
                        <Col span={8} >
                            <p className='inputPatient2'>Désir de fertilité ultérieure</p>
                            <Select
                                value={DesirFertiliteUlterieur}
                                onChange={(value) => {
                                    setDesirFertiliteUlterieur(value);
                                    updateBilanPreTherapeutique('Désir de fertilité ultérieure', value);
                                }}
                                style={{ width: "310px" }}>
                                <option value="Oui">Oui</option>
                                <option value="Non">Non</option>
                            </Select>
                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Préservation de fertilité proposée</p>
                            <Select
                                value={PreservationFertiliteProposer}
                                onChange={(value) => {
                                    setPreservationFertiliteProposer(value);
                                    updateBilanPreTherapeutique('Préservation de fertilité proposée', value);
                                }}
                                style={{ width: "310px" }}>
                                <option value="Oui">Oui</option>
                                <option value="Non">Non</option>
                            </Select>
                        </Col>
                        <Col span={8}>
                            <p className='inputPatient2'>Onco sexualité</p>
                            <Input value={OncoSexualite}
                                onChange={(e) => {
                                    setOncoSexualite(e.targetvalue);
                                    updateBilanPreTherapeutique('Onco sexualité', e.target.value);
                                }}
                                placeholder='Onco sexualité' />
                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Onco génétique</p>
                            <Input
                                value={OncoGenetique}
                                onChange={(e) => {
                                    setOncoGenetique(e.targetvalue);
                                    updateBilanPreTherapeutique('Onco génétique', e.target.value);
                                }}
                                placeholder='Oncogénétique' />

                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Indication</p>
                            <Input
                                value={Indication}
                                onChange={(e) => {
                                    setIndication(e.targetvalue);
                                    updateBilanPreTherapeutique('Indication', e.target.value);
                                }}
                                placeholder='Indication' />

                        </Col>
                        <Col span={8} >
                            <p className='inputPatient2'>Proposition</p>
                            <Input
                                value={Proposition}
                                onChange={(e) => {
                                    setProposition(e.targetvalue);
                                    updateBilanPreTherapeutique('Proposition', e.target.value);
                                }}
                                placeholder='Proposition' />

                        </Col>

                    </Row>
                </>
            )}


            {(((data.Specialite === 'Digestif' && data.Organe === 'Estomac') || (data.Specialite === 'Gynécologie') || (data.Specialite === 'Hématologie') || (data.Specialite === 'Urologie' && data.Organe === 'Penis') || data.Specialite === 'Thorax') || (data.Specialite === 'ORL') || data.Specialite === 'Pédiatrie') && (

                <Row>

                    <Col span={24}>
                        <p className='inputPatient2'>Media +++</p>

                        <Input name='Media' value={media} onChange={(e) => setMedia(e.target.value)} placeholder="Media +++" />

                    </Col>
                </Row>

            )}

            {data.Specialite === 'Pédiatrie' && (data.Organe === 'Testicule/para-testicule') && (
                <Row>
                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Checkbox
                            value={Testicule}
                            checked={selectedCheckbox.includes('Testicule')}
                            onChange={handleChangeOption}
                        >
                            Testicule
                        </Checkbox>
                        <Checkbox
                            value={ParaTesticule}
                            checked={selectedCheckbox.includes('Para-testiculaire')}
                            onChange={handleChangeOption}
                        >
                            Para-testiculaire
                        </Checkbox>

                    </Col>

                    {selectedCheckbox.includes('Testicule') && (
                        <>
                            <Col span={24}>
                                <p className='inputPatient2'>Choisissez le type </p>
                                <Select
                                    // mode='tags'
                                    value={TypeTesticule}
                                    onChange={handleChangeType}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {optionTypeTesticles.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            {AfficheChexbox25 && (
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Seminome</p>
                                        <Input placeholder='Seminome' name='Seminome' value={Seminome} onChange={(value) => setSeminome(value)} />
                                    </Col>
                                    <Col span={12}>
                                        <p className='inputPatient2'> Non Séminomateuse</p>
                                        <Select
                                            mode='tags'
                                            value={NonSeminome} onChange={(value) => setNonSeminome(value)}
                                            style={{ width: "570px", height: "33px" }}
                                        >
                                            <Option key='Tératome' value="Tératome"></Option>
                                            <Option key='Choriocarcinome' value="Choriocarcinome"> Choriocarcinome</Option>
                                            <Option key='Tumeur du sac vitellin' value="Tumeur du sac vitellin">Tumeur du sac vitellin </Option>
                                            <Option key='Carcinome embryonnaire' value="Carcinome embryonnaire">Carcinome embryonnaire </Option>
                                        </Select>
                                    </Col>


                                </Row>
                            )}
                            {AfficheChexbox26 && (
                                <Row gutter={6}>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Tumeur à Cellules de Sertoli-Leydig</p>
                                        <Select
                                            mode='tags'
                                            value={TumeurCelluleSL} onChange={(value) => setTumeurCelluleSL(value)}
                                            style={{ width: "460px", height: "33px" }}
                                        ></Select>
                                    </Col>
                                    <Col span={12}>
                                        <p className='inputPatient2'>Tumeur à Cellules de la granulosa</p>
                                        <Select
                                            mode='tags'
                                            value={TumeurCelluleG} onChange={(value) => setTumeurCelluleG(value)}
                                            style={{ width: "475px", height: "33px" }}
                                        ></Select>
                                    </Col>

                                </Row>
                            )}

                            <Row gutter={6}>
                                <Col span={8}>
                                    <p className='inputPatient2'>Stade clinique</p>
                                    <Select
                                        name="stade clinique"
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={tumeur}
                                        style={{ width: "310px" }}
                                        onChange={(value) => setTumeur(value)}
                                    >
                                        <OptGroup label="T">
                                            {optionTumeur.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </OptGroup>

                                        <OptGroup label="N">
                                            {optionAdenophatie.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </OptGroup>

                                        <OptGroup label="M">
                                            {optionMétastase.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </OptGroup>
                                    </Select>
                                </Col>

                                <Col span={8}>
                                    <p className='inputPatient2'>Biologie moléculaire</p>
                                    <Select
                                        name="BiologieMoleculaire"
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={biologieMoleculaire}
                                        style={{ width: "310px" }}
                                        onChange={(value) => setBiologieMoleculaire(value)}>

                                        {optionBiologieMoleculaire.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}

                                    </Select>
                                </Col>
                                <Col span={8}>
                                    <p className='inputPatient2'>Facteurs pronostics</p>
                                    <Select
                                        mode='tags'
                                        name="FacteursPronostiques"
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={FacteursPronostiques}
                                        style={{ width: "310px" }}
                                        onChange={(value) => setFacteursPronostiques(value)}>

                                        {optionFacteurPronostique.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}

                                    </Select>
                                </Col>

                                <Col span={24}>
                                    <p className='inputPatient2'>Tumeurs germinales non séminomateuses</p>
                                    <Select
                                        name=""
                                        // placeholder="Sélectionnez le Tumeur primitive"
                                        value={TumeursGerminaux}
                                        style={{ width: "950px" }}
                                        onChange={(value) => setTumeursGerminaux(value)}>

                                        {optionTumeursGerminaux.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}

                                    </Select>
                                </Col>
                            </Row>

                        </>

                    )}

                    {selectedCheckbox.includes('Para-testiculaire') && (
                        <>
                            <>

                                <Col span={24}>
                                    <p className='inputPatient2'> Type Histologie</p>
                                    <Select
                                        mode='tags'
                                        value={TypeHistologies}
                                        onChange={(value) => setTypeHistologies(value)}
                                        style={{ width: "100%", height: "33px" }}
                                    >
                                        {filteredOptions.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={24}>
                                    <p className='inputPatient2'>Classification</p>
                                    <Select
                                        mode='tags'
                                        value={tumeur}
                                        onChange={(value) => setTumeur(value)}
                                        style={{ width: "100%", height: "33px" }}
                                    >
                                        {optionTumeur.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={24}>
                                    <p className='inputPatient2'>Risque</p>
                                    <Checkbox.Group onChange={handleRiskChange} value={CheckboxChoice}>
                                        <Checkbox value="Risque standard, sous-groupe A">Risque standard, sous-groupe A</Checkbox>
                                        <Checkbox value="Risque standard, sous-groupe B">Risque standard, sous-groupe B</Checkbox>
                                        <Checkbox value="Risque intermédiaire">Risque intermédiaire</Checkbox>
                                        <Checkbox value="Risque élevé">Risque élevé</Checkbox>
                                    </Checkbox.Group>

                                    {CheckboxChoice.includes('Risque standard, sous-groupe A') && (
                                        <div>
                                            <h4>Sous-groupe A - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="Stade 1, Groupe I">Stade 1, Groupe I</Option>
                                                <Option value="Stade 1, Groupe II">Stade 1, Groupe II</Option>
                                                <Option value="Stade 2, Groupe I">Stade 2, Groupe I</Option>
                                            </Select>
                                        </div>
                                    )}

                                    {CheckboxChoice.includes('Risque standard, sous-groupe B') && (
                                        <div>
                                            <h4>Sous-groupe B - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="Stade 1, Groupe III">Stade 1, Groupe III</Option>
                                                <Option value="Stade 2, Groupe II">Stade 2, Groupe II</Option>
                                                <Option value="Stade 3, Groupes I et II">Stade 3, Groupes I et II</Option>
                                            </Select>
                                        </div>
                                    )}

                                    {CheckboxChoice.includes('Risque intermédiaire') && (
                                        <div>
                                            <h4>Risque intermédiaire - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="Stades 2 et 3, Groupe III">Stades 2 et 3, Groupe III</Option>
                                                <Option value="Stade 4 groupe IV">Stade 4 groupe IV</Option>
                                                <Option value="RMS alvéolaire non métastatique">RMS alvéolaire non métastatique</Option>
                                            </Select>
                                        </div>
                                    )}

                                    {CheckboxChoice.includes('Risque élevé') && (
                                        <div>
                                            <h4>Risque élevé - Stades et Groupes</h4>
                                            <Select
                                                mode="multiple"
                                                value={Risque}
                                                onChange={handleRisqueChange}
                                                placeholder="Sélectionnez les stades"
                                                style={{ width: '100%' }}
                                            >
                                                <Option value="RMS alvéolaire métastatique">RMS alvéolaire métastatique</Option>
                                                <Option value="RMS embryonnaire métastatiques > 10 ans ou < 1 an">
                                                    RMS embryonnaire métastatiques {'>'} 10 ans ou {'<'} 1 an
                                                </Option>
                                            </Select>
                                        </div>
                                    )}
                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>Biologie moléculaire</p>
                                    <Select
                                        mode="tags"
                                        value={biologieMoleculaire}
                                        onChange={handleChangeBiomoleculaire}
                                        style={{ width: "470px", height: "33px", }}
                                    >
                                        {optionBiologieMoleculaire.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={12}>
                                    <p className='inputPatient2'>Facteurs pronostique</p>
                                    <Select
                                        mode="tags"
                                        value={FacteursPronostiques}
                                        onChange={handleFacteurProsnostique}
                                        style={{ width: "470px", height: "33px", }}
                                    >
                                        {optionFacteurPronostique.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </>

                        </>

                    )}


                </Row>
            )}


            {data.Specialite === 'Pédiatrie' && (data.Organe === 'Prostate' || data.Organe === 'Vessie') && (
                <Row>
                    <Col span={24} style={{ marginTop: "20px" }}>
                        <Checkbox
                            value={Rhabdomyosarcome}
                            checked={selectedCheckbox.includes('Rhabdomyosarcome')}
                            onChange={handleChangeOption}
                        >
                            Rhabdomyosarcome
                        </Checkbox>
                        <Checkbox
                            value={Carcinome}
                            checked={selectedCheckbox.includes('Carcinome')}
                            onChange={handleChangeOption}
                        >
                            Carcinome
                        </Checkbox>

                    </Col>
                    {selectedCheckbox.includes('Rhabdomyosarcome') && (
                        <>
                            <Col span={24}>
                                <p className='inputPatient2'> Type Histologie</p>
                                <Select
                                    mode='tags'
                                    value={TypeHistologies}
                                    onChange={(value) => setTypeHistologies(value)}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {filteredOptions.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={24}>
                                <p className='inputPatient2'>Classification</p>
                                <Select
                                    mode='tags'
                                    value={tumeur}
                                    onChange={(value) => setTumeur(value)}
                                    style={{ width: "100%", height: "33px" }}
                                >
                                    {optionTumeur.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={24}>
                                <p className='inputPatient2'>Risque</p>
                                <Checkbox.Group onChange={handleRiskChange} value={CheckboxChoice}>
                                    <Checkbox value="Risque standard, sous-groupe A">Risque standard, sous-groupe A</Checkbox>
                                    <Checkbox value="Risque standard, sous-groupe B">Risque standard, sous-groupe B</Checkbox>
                                    <Checkbox value="Risque intermédiaire">Risque intermédiaire</Checkbox>
                                    <Checkbox value="Risque élevé">Risque élevé</Checkbox>
                                </Checkbox.Group>

                                {CheckboxChoice.includes('Risque standard, sous-groupe A') && (
                                    <div>
                                        <h4>Sous-groupe A - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="Stade 1, Groupe I">Stade 1, Groupe I</Option>
                                            <Option value="Stade 1, Groupe II">Stade 1, Groupe II</Option>
                                            <Option value="Stade 2, Groupe I">Stade 2, Groupe I</Option>
                                        </Select>
                                    </div>
                                )}

                                {CheckboxChoice.includes('Risque standard, sous-groupe B') && (
                                    <div>
                                        <h4>Sous-groupe B - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="Stade 1, Groupe III">Stade 1, Groupe III</Option>
                                            <Option value="Stade 2, Groupe II">Stade 2, Groupe II</Option>
                                            <Option value="Stade 3, Groupes I et II">Stade 3, Groupes I et II</Option>
                                        </Select>
                                    </div>
                                )}

                                {CheckboxChoice.includes('Risque intermédiaire') && (
                                    <div>
                                        <h4>Risque intermédiaire - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="Stades 2 et 3, Groupe III">Stades 2 et 3, Groupe III</Option>
                                            <Option value="Stade 4 groupe IV">Stade 4 groupe IV</Option>
                                            <Option value="RMS alvéolaire non métastatique">RMS alvéolaire non métastatique</Option>
                                        </Select>
                                    </div>
                                )}

                                {CheckboxChoice.includes('Risque élevé') && (
                                    <div>
                                        <h4>Risque élevé - Stades et Groupes</h4>
                                        <Select
                                            mode="multiple"
                                            value={Risque}
                                            onChange={handleRisqueChange}
                                            placeholder="Sélectionnez les stades"
                                            style={{ width: '100%' }}
                                        >
                                            <Option value="RMS alvéolaire métastatique">RMS alvéolaire métastatique</Option>
                                            <Option value="RMS embryonnaire métastatiques > 10 ans ou < 1 an">
                                                RMS embryonnaire métastatiques {'>'} 10 ans ou {'<'} 1 an
                                            </Option>
                                        </Select>
                                    </div>
                                )}
                            </Col>
                            <Col span={12}>
                                <p className='inputPatient2'>Biologie moléculaire</p>
                                <Select
                                    mode="tags"
                                    value={biologieMoleculaire}
                                    onChange={handleChangeBiomoleculaire}
                                    style={{ width: "470px", height: "33px", }}
                                >
                                    {optionBiologieMoleculaire.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>

                            <Col span={12}>
                                <p className='inputPatient2'>Facteurs pronostique</p>
                                <Select
                                    mode="tags"
                                    value={FacteursPronostiques}
                                    onChange={handleFacteurProsnostique}
                                    style={{ width: "470px", height: "33px", }}
                                >
                                    {optionFacteurPronostique.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </>
                    )}
                    {selectedCheckbox.includes('Carcinome') && (
                        <Row gutter={6}>
                            <Col span={12}>
                                <p className='inputPatient2'> Type Histologie</p>
                                <Select
                                    mode='tags'
                                    value={TypeHistologies}
                                    onChange={(value) => setTypeHistologies(value)}
                                    style={{ width: "470px", height: "33px" }}
                                >
                                    {filteredOptions.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={12}>
                                <p className='inputPatient2'>Stadification selon le TNM</p>
                                <Select
                                    value={tumeur}
                                    onChange={(value) => setTumeur(value)}
                                    style={{ width: "470px", height: "33px" }}
                                    mode="multiple"
                                >
                                    <OptGroup label="T : Tumeur Primaire">
                                        {optionTumeur.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </OptGroup>

                                    <OptGroup label="N : Ganglions Lymphatiques Régionaux">
                                        {optionAdenophatie.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </OptGroup>

                                    <OptGroup label="M : Métastases à Distance">
                                        {optionMétastase.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </OptGroup>
                                </Select>
                            </Col>
                        </Row>
                    )}


                </Row>
            )}


            {data.Specialite === 'Pédiatrie' && data.Organe === 'Ovaire' && (
                <Row gutter={6}>
                    <Col span={6}>
                        <p className='inputPatient2'>Germinale</p>
                        <Input placeholder='Germinale' name='Germinale' value={Germinale} onChange={(e) => setGerminale(e.target.value)} />
                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Dysgerminome</p>
                        <Input placeholder='Dysgerminome' name='Dysgerminome' value={Dysgerminome} onChange={(e) => setDysgerminome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tératome</p>
                        <Input placeholder='Tératome' name='Teratome' value={Teratome} onChange={(e) => setTeratome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Choriocarcinome</p>
                        <Input placeholder='Choriocarcinome' name='Choriocarcinome' value={Choriocarcinome} onChange={(e) => setChoriocarcinome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tumeur du sac vitellin</p>
                        <Input placeholder='Tumeur du sac vitellin' name='TumeurSacVitellin' value={TumeurSacVitellin} onChange={(e) => setTumeurSacVitellin(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Carcinome embryonnaire</p>
                        <Input placeholder='Carcinome embryonnaire' name='Carcinome' value={Carcinome} onChange={(e) => setCarcinome(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Cordons sexuels</p>
                        <Input placeholder='Cordons sexuels' name='CordonSexuel' value={CordonSexuel} onChange={(e) => setCordonSexuel(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tumeur à Cellules de Sertoli-Leydi </p>
                        <Input placeholder='Tumeur à Cellules de Sertoli-Leydi' name='TumeurCelluleSL' value={TumeurCelluleSL} onChange={(e) => setTumeurCelluleSL(e.target.value)} />

                    </Col>
                    <Col span={6}>
                        <p className='inputPatient2'>Tumeur à Cellules de la granulosa </p>
                        <Input placeholder='Tumeur à Cellules de la granulosa' name='TumeurCelluleG' value={TumeurCelluleG} onChange={(e) => setTumeurCelluleG(e.target.value)} />

                    </Col>
                    <Col span={18}>
                        <p className='inputPatient2'>Classification de FIGO</p>

                        <Select
                            mode='tags'
                            name="ClassificationFigo"
                            // placeholder="Sélectionnez le Tumeur primitive"
                            value={tumeur}
                            style={{ width: "100%" }}
                            onChange={(value) => setTumeur(value)}
                        >
                            <OptGroup label="Stade I">
                                {optionTumeur.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>

                            <OptGroup label="Stade II">
                                {optionAdenophatie.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>
                            <OptGroup label="Stade III">
                                {optionMétastase.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>
                            <OptGroup label="Stade IV">
                                {optionClassification.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </OptGroup>


                        </Select>

                    </Col>
                </Row>
            )}



            {(data.Specialite === 'Hématologie' && (data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') || (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques')) ? (
                <>
                    <Row gutter={6}>
                        <Col span={12}>
                            <p>Néoplasmes des cellules de Langerhans et autres cellules dendritiques</p>
                            <Select
                                name="NéoplasmesCellulesLangerhansAutresCellulesDndritiques"
                                // placeholder="Sélectionnez le Tumeur primitive"
                                value={tumeur}
                                style={{ width: "100%" }}
                                onChange={(value) => setTumeur(value)}
                            >
                                <OptGroup label="Néoplasmes des cellules de Langerhans">
                                    {optionTumeur.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptGroup>

                                <OptGroup label="Autres néoplasmes des cellules dendritiques">
                                    {optionAdenophatie.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptGroup>


                            </Select>
                        </Col>
                        <Col span={12}>
                            <p>Néoplasmes histiocytes/macrophages</p>
                            <Select
                                name="NéoplasmesHistiocytesMacrophages"
                                // placeholder="Sélectionnez le Tumeur primitive"
                                value={NéoplasmesHistiocytesMacrophages}
                                style={{ width: "100%" }}
                                onChange={(value) => setNéoplasmesHistiocytesMacrophages(value)}
                            >
                                <OptGroup label="Néoplasmes histiocytaires">
                                    {optionNéoplasmesHistiocytesMacrophages.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </OptGroup>


                            </Select>
                        </Col>
                    </Row>

                </>
            ) : (
                <>
                    <Row gutter={6}>
                        {(data.Specialite === 'Gynécologie') || data.Specialite === 'Cancers Rares' || (data.Specialite === 'Hématologie') || (data.Specialite === 'Urologie') || data.Specialite === 'Pédiatrie' || (data.Specialite === "Thorax") || (data.Specialite === 'ORL') ?
                            (
                                <div></div>
                            ) : (

                                <Col span={8}>
                                    <p className='inputPatient2'>MALADE</p>

                                    <Select
                                        mode="tags"
                                        value={Malade}
                                        onChange={(value) => setMalade(value)}
                                        style={{ width: "315px", height: "33px", }}
                                    >
                                        {optionMalade.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}

                        {(data.Specialite === 'Hématologie' &&
                            ['LA Adulte', 'Leucémie Lymphoïde Chronique', 'Syndrome Myéloprolifératif Ph1 négative', 'Syndromes myélodysplasiques', 'Leucémie myéloïde Chronique', 'Myélome multiple'].includes(data.Organe)) ||
                            (data.Specialite === 'Pédiatrie' &&
                                ['Voie excretrice', 'Rein', 'Rétropéritoine', 'Os'].every(organe => organe !== data.Organe)) ||
                            (data.Specialite === 'Thorax' && data.Organe === 'Plèvre') ? (
                            <div></div>
                        ) : (
                            <Col span={8}>
                                <p className='inputPatient2'>Type histologique</p>

                                <Select
                                    mode='tags'
                                    value={TypeHistologies}
                                    onChange={handleChangeTypeHistologies}
                                    style={{
                                        width:
                                            (data.Specialite === 'Gynécologie' &&
                                                (data.Organe === 'Néoplasies trophoblastiques gestationnelles' || data.Organe === 'Sein')) ||
                                                data.Specialite === 'Hématologie' ||
                                                data.Specialite === 'Urologie' && data.Organe === 'Vessie/Arbre urinaire' ||
                                                data.Specialite === 'Thorax' ||
                                                (data.Specialite === 'Pédiatrie' &&
                                                    (data.Organe !== 'Voie excretrice' && data.Organe !== 'Rein')) ||
                                                data.Specialite === 'Cancers Rares'
                                                ? "950px"
                                                : "310px",
                                        height: "33px",
                                    }}
                                >
                                    {optionTypeHistologies.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>              </Col>

                        )}

                        {data.Specialite === 'Pédiatrie' && (data.Organe === 'Tumeurs cerebrales') && (
                            <Col span={8}>
                                <p className='inputPatient2'>Medulloblasme</p>

                                <Select
                                    // mode="tags"
                                    value={medulloblasme}
                                    onChange={(value) => setMedulloblasme(value)}
                                    style={{ width: "630px", height: "33px", }}
                                >
                                    {optionMedulloblasme.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>                </Col>

                        )}


                        {AfficheChexbox9 && (
                            <Col span={24} style={{ marginTop: '20px' }}>
                                <Checkbox
                                    checked={MedulloblasmeSelect[medulloblasme] === "Oui"}
                                    onChange={(e) => Medulloblasme(medulloblasme, e.target.checked ? "Oui" : null)}
                                >
                                    Oui
                                </Checkbox>
                                <Checkbox
                                    checked={MedulloblasmeSelect[medulloblasme] === "Non"}
                                    onChange={(e) => Medulloblasme(medulloblasme, e.target.checked ? "Non" : null)}
                                >
                                    Non
                                </Checkbox>
                            </Col>
                        )}


                        {data.Specialite === 'Thorax' && data.Organe === 'Plèvre' &&
                            (
                                <>
                                    <p className='inputPatient2'>Type histologique</p>

                                    <Col span={8}>
                                        <p className='inputPatient2'>Si mésothéliome pleural malin</p>

                                        <Select style={{ width: "470px" }}
                                            name='TypeHistologies'
                                            value={TypeHistologies}
                                            onChange={(value) => setTypeHistologies(value)}
                                        >
                                            <Option value="Epithélioïde">Epithélioïde</Option>
                                            <Option value="Sarcomatoïde">Sarcomatoïde</Option>
                                            <Option value="Mixte ou biphasique">Mixte ou biphasique</Option>
                                            <Option value="Desmoplasique">Desmoplasique</Option>
                                        </Select>
                                    </Col>

                                    <Col span={12}>
                                        <p>Autres tumeurs pleurales, preciser type histologique</p>
                                        <Input
                                        name='TypeHistologies'
                                        value={TypeHistologies}
                                        onChange={(value) => setTypeHistologies(value)}
                                        className='inputPatient2' />
                                    </Col>


                                </>

                            )}

                        {(data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte') || (data.Specialite === 'Pédiatrie' && (data.Organe === 'T. Sacro-coccygienne' || data.Organe === 'Corticosurrenale' || data.Organe === 'Oesophage')) && (
                            <>
                                <Col span={8}>
                                    <p className='inputPatient2'>Type cistologique</p>

                                    <Select
                                        mode="tags"
                                        value={TypeCistologie}
                                        onChange={(value) => setTypeCistologie(value)}
                                        style={{ width: "950px", height: "33px", }}
                                    >
                                        {optionCystologie.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                            </>

                        )}

                        {(data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique') && (
                            <>
                                <Col span={8}>
                                    <p className='inputPatient2'>Cytogénétique</p>

                                    <Select
                                        // mode="tags"

                                        value={TypeHistologies}
                                        onChange={handleChangeCystogénétique}
                                        style={{ width: "950px", height: "33px", }}
                                    >
                                        {optionTypeHistologies.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                {AfficheChexbox7 && (
                                    <Col span={24} style={{ marginTop: '20px' }}>
                                        <Checkbox
                                            checked={CytogenetiqueDel17p[TypeHistologies] === "Oui"}
                                            onChange={(e) => Cytogenetique(TypeHistologies, e.target.checked ? "Oui" : null)}
                                        >
                                            Oui
                                        </Checkbox>
                                        <Checkbox
                                            checked={CytogenetiqueDel17p[TypeHistologies] === "Non"}
                                            onChange={(e) => Cytogenetique(TypeHistologies, e.target.checked ? "Non" : null)}
                                        >
                                            Non
                                        </Checkbox>
                                    </Col>
                                )}

                            </>

                        )}


                        {(data.Specialite === 'Gynécologie' && (data.Organe === 'Néoplasies trophoblastiques gestationnelles' || data.Organe === 'Sein') || data.Specialite === 'Cancers Rares' || (data.Specialite === 'Hématologie') || data.Specialite === 'Pédiatrie' || (data.Specialite === 'Urologie' && (data.Organe === 'Vessie/Arbre urinaire' || data.Organe === 'Testicule' || data.Organe === 'Prostate' || data.Organe === 'Penis' || data.Organe === 'Surrenale' || data.Organe === 'Reins') || data.Specialite === 'Thorax')) ?
                            (
                                <div></div>
                            ) : (
                                <Col span={data.Specialite === 'Gynécologie' && (data.Organe === 'Ovaire' || data.Organe === 'Col' || data.Organe === 'Utérus' || data.Organe === 'Vagin' || data.Organe === 'Vulve') || (data.Specialite === 'Urologie' && data.Organe === 'Prostate') || (data.Specialite === 'ORL') ? 16 : 8}>
                                    <p className='inputPatient2'>Grade histopronostique</p>

                                    <Input name='GradeHistopronostique' value={GradeHistopronostique} onChange={(e) => setGradeHistopronostique(e.target.value)} placeholder="Grade histopronostique" />
                                </Col>
                            )}
                        {data.Specialite === 'Urologie' && data.Organe === 'Prostate' || (data.Specialite === 'ORL' && data.Organe === 'Thyroide') || data.Specialite === 'Cancers Rares' && (data.Organe !== 'Parathyroïde') || (data.Specialite === 'Pédiatrie' && (data.Organe === 'Rein' || data.Organe === 'Rétropéritoine')) && (
                            <Col span={24}>
                                <p className='inputPatient2'>Grade histopronostique</p>
                                <Select
                                    // mode="tags"
                                    value={GradeHistopronostique}
                                    onChange={(value) => setGradeHistopronostique(value)}
                                    style={{ width: '950px', height: "33px", }}
                                >
                                    {optionGradeHistopronostique.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}
                    </Row>

                    <Row gutter={6}>
                        {(data.Specialite === 'Hématologie' && (data.Organe === 'Leucémie Lymphoïde Chronique' || data.Organe === 'Syndrome Myéloprolifératif Ph1 négative' || data.Organe === 'Syndromes myélodysplasiques' || data.Organe === 'Leucémie myéloïde Chronique' || data.Organe === 'Lymphome non hodgkinien adulte' || data.Organe === 'Myélome multiple') || (data.Specialite === 'Urologie' && (data.Organe === 'Penis' || data.Organe === 'Surrenale') || (data.Specialite === 'Thorax' && data.Organe !== 'Poumon') || data.Specialite === 'Pédiatrie' || data.Specialite === 'Cancers Rares' || (data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire' || data.Organe === 'Thyroide')))) ?
                            (
                                <div></div>
                            ) : (

                                <>
                                    <Col span={8}>
                                        <p className='inputPatient2'>Biologie moléculaire/Autre</p>


                                        <Select
                                            mode="tags"
                                            value={biologieMoleculaire}
                                            onChange={handleChangeBiomoleculaire}
                                            style={{ width: (data.Specialite === 'Urologie' && data.Organe === 'Vessie/Arbre urinaire') || (data.Specialite === 'Thorax' && data.Organe === 'Poumon') || data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? '950px' : "310px", height: "33px", }}
                                        >
                                            {optionBiologieMoleculaire.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>                </Col>




                                    {data.Specialite === 'Thorax' && data.Organe === 'Poumon' || data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx') ? (
                                        <div></div>
                                    ) : (
                                        <>
                                            <Col span={16}>
                                                <p className='inputPatient2'>Tils</p>
                                                <Input name='Tils' value={Tils} onChange={(e) => setTils(e.target.value)} placeholder="" />                                    </Col>

                                            {(data.Specialite === 'Gynécologie' && data.Organe === 'Néoplasies trophoblastiques gestationnelles' || data.Specialite === 'Urologie') ?
                                                (
                                                    <div></div>
                                                ) : (
                                                    <>
                                                        {data.Specialite === 'Pédiatrie' && (data.Organe === 'Oesophage' || data.Organe === 'Colo-rectum') && (
                                                            <Col span={8}>
                                                                <p className='inputPatient2'>Cytogénétique conventionnelle</p>
                                                                <Input name='CytogenetiqueConventionnelle' value={cytogenetiqueConventionnelle} onChange={(e) => setCytogenetiqueConventionnelle(e.target.value)} placeholder="Cytogénétique conventionnelle" />

                                                            </Col>
                                                        )}
                                                        <Col span={8}>
                                                            <p className='inputPatient2'>FISH</p>
                                                            <Input name='FISH' value={FISH} onChange={(e) => setFISH(e.target.value)} placeholder="FISH" />

                                                        </Col>

                                                        {(data.Specialite === 'Gynécologie' || data.Specialite === 'Hématologie') ?
                                                            (
                                                                <div></div>
                                                            ) : (

                                                                <Col span={8}>
                                                                    <p className='inputPatient2'>Marqueurs tumoraux</p>

                                                                    <Select
                                                                        mode="tags"
                                                                        value={MarqueursTumoraux}
                                                                        onChange={(value) => setMarqueursTumoraux(value)}
                                                                        style={{ width: "310px", height: "33px", }}
                                                                    >
                                                                        {optionMarqueursTumoraux.map((option) => (
                                                                            <Option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Col>

                                                            )}

                                                    </>
                                                )}

                                        </>
                                    )}
                                </>
                            )}

                        {(data.Specialite === 'Gynécologie' && (data.Organe === 'Ovaire' || data.Organe === 'Sein' || data.Organe === 'Col' || data.Organe === 'Utérus' || data.Organe === 'Vagin' || data.Organe === 'Vulve') || (data.Specialite === 'Thorax' && (data.Organe === 'Parois thoracique' || data.Organe === 'Plèvre'))) &&
                            (

                                <Col span={12}>
                                    <p className='inputPatient2'>immunohistochimie</p>

                                    <Select
                                        // mode="tags"
                                        value={immuno}
                                        onChange={(value) => setImmuno(value)}
                                        style={{ width: (data.Specialite === 'Thorax' && (data.Organe === 'Parois thoracique' || data.Organe === 'Plèvre' || data.Organe === 'Poumon')) ? "950px" : "470px", height: "33px", }}
                                    >
                                        {optionImmuno.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            )}
                        {(data.Specialite === 'Gynécologie' && data.Organe === 'Sein') && (
                            <>
                                <Col span={12}>
                                    <p className='inputPatient2'>Mutation génétique</p>
                                    <Select
                                        // mode="tags"
                                        value={mutationGenetique}
                                        onChange={(value) => setMutationGenetique(value)}
                                        style={{ width: "470px", height: "33px", }}
                                    >
                                        {optionMutation.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={16}>
                                    <p className='inputPatient2'>PDL1/mTOR</p>
                                    <Input placeholder="PDL1/mTOR"
                                        name="pdl1"
                                        value={pdl1mTor}
                                        onChange={(e) => setPdl1mTor(e.target.value)} />
                                </Col>
                            </>
                        )}

                        {(data.Specialite === 'Pédiatrie' || data.Specialite === 'Cancers Rares' || data.Specialite === 'Hématologie' && (data.Organe === 'Syndromes myélodysplasiques' || data.Organe === 'Syndrome Myéloprolifératif Ph1 négative' || data.Organe === 'LA Adulte' || data.Organe === 'Myélome multiple' || data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'Myélome multiple' || data.Organe === 'Leucémie Lymphoïde Chronique' || data.Organe === 'Leucémie Lymphoïde Chronique' || data.Organe === 'Lymphome non hodgkinien adulte') || (data.Specialite === 'Urologie' && (data.Organe === 'Vessie/Arbre urinaire' || data.Organe === 'Penis' || data.Organe === 'Surrenale') || data.Specialite === 'Thorax' || data.Specialite === 'ORL')) ? (
                            <div></div>

                        ) : (

                            <Col
                                span={
                                    (data.Specialite === 'Gynécologie' && data.Organe === 'Néoplasies trophoblastiques gestationnelles') ||
                                        (data.Specialite === 'Hématologie' &&
                                            (data.Organe === 'Lymphome Hodgkin adulte' || data.Organe === 'Leucémie myéloïde Chronique'))
                                        ? 24
                                        : data.Specialite === 'Urologie' && data.Organe === 'Reins'
                                            ? 24
                                            : data.Specialite === 'Urologie'
                                                ? 16
                                                : 8
                                }>
                                <p className='inputPatient2'>Facteurs pronostiques (organe)</p>

                                <Input placeholder="Facteurs pronostiques (organe)"
                                    name="facteurPronostique"
                                    value={facteurPronostique}
                                    onChange={(e) => setFacteurPronostique(e.target.value)} />
                            </Col>
                        )}


                        {(data.Specialite === 'ORL' &&
                            ['Cavum', 'Nasopharynx'].includes(data.Organe)) ||
                            (data.Specialite === 'Pédiatrie' && data.Organe !== 'Rein') || (data.Specialite === 'Hématologie' && (data.Organe === 'Myélome multiple' || data.Organe === 'Syndrome Myéloprolifératif Ph1 négative' || data.Organe === 'Syndromes myélodysplasiques')) ? (
                            <div></div>

                        ) : (

                            <Col span={24}>
                                <p className='inputPatient2'>Facteurs pronostiques </p>
                                <Select
                                    mode="tags"
                                    value={facteurPronostique}
                                    onChange={handleFacteurProsnostique}
                                    style={{ width: "100%", height: "33px", }}
                                >
                                    {optionFacteurPronostique.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}

                        {AfficheChexbox15 && (
                            <Col span={24} style={{}}>
                                <Checkbox onChange={handleCheckboxChange}>mauvais pronostique</Checkbox>
                            </Col>
                        )}

                        {AfficheChexbox16 && (
                            <Col span={24} style={{}}>
                                <Checkbox onChange={handleCheckboxChange}>bon pronostique</Checkbox>
                            </Col>
                        )}

                        {AfficheChexbox17 && (
                            <>
                                <Col span={12} style={{}}>
                                    <p>{'âge > 40 ans chez l’homme et > 50 ans chez la femme'}</p>
                                    <Input placeholder='age' />
                                </Col>
                                <Col span={12} style={{}}>
                                    <p>Sexe : Pc légèrement meilleur chez les femmes</p>
                                    <Input name='' placeholder='Sex' />
                                </Col>
                            </>
                        )}

                        {AfficheChexbox18 && (
                            <>
                                <Col span={8} style={{}}>
                                    <p>Taille</p>
                                    <Select style={{ width: "310px" }}>
                                        <Option value="T3">T3</Option>
                                        <Option value="T4">T4</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Histologie</p>
                                    <Select style={{ width: '310px' }}>
                                        <Option value="faible degré de différenciation tumorale">faible degré de différenciation tumorale</Option>
                                        <Option value="formes agressives ( les cancers anaplasiques, cellules hautes, cylindriques) extension tumorale au-delà de la capsule thyroïdienne">formes agressives ( les cancers anaplasiques, cellules hautes, cylindriques) extension tumorale au-delà de la capsule thyroïdienne</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Atypies nucléaires, Emboles vasculaires, nécrose</p>
                                    <Input placeholder='Atypies nucléaires, Emboles vasculaires, nécrose' />
                                </Col>
                                <Col span={12} style={{}}>
                                    <p>Métastases ganglionnaires Métastases à distance initiales</p>
                                    <Input placeholder='' />
                                </Col>
                                <Col span={12} style={{}}>
                                    <p>Mutation BRAF-V600E</p>
                                    <Input placeholder='Mutation BRAF-V600E' />
                                </Col>
                            </>
                        )}
                        {AfficheChexbox19 && (
                            <>
                                <Col span={8} style={{}}>
                                    <p>Haut Risque (30-55%)</p>
                                    <Select style={{ width: "310px" }}>
                                        <Option value="Métastases, Gg> 3 cm">{'Métastases, Gg> 3 cm'}</Option>
                                        <Option value="Extension extra-thyroïdienne massive">Extension extra-thyroïdienne massive</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Risque intermédiaire (3-40%)</p>
                                    <Select style={{ width: '310px' }}>
                                        <Option value="Histologie aggressive Ext extra-thyr mineure > 5 Gg de 0,2 à 3 cm">{'Histologie aggressive Ext extra-thyr mineure > 5 Gg de 0,2 à 3 cm'}</Option>
                                    </Select>
                                </Col>
                                <Col span={8} style={{}}>
                                    <p>Bas Risque (1-6%)</p>
                                    <Select style={{ width: '310px' }}>
                                        <Option value="Cancer intra-thyroidien">Cancer intra-thyroidien</Option>
                                        <Option value="<5 meta gg">{'<5 meta gg'}</Option>
                                        <Option value="<2mm">{'<2mm'}</Option>
                                    </Select>                                </Col>
                            </>
                        )}



                    </Row>

                    {data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique' || data.Specialite === 'Cancers Rares' || data.Specialite === 'Pédiatrie' && (data.Organe === 'Corticosurrenale' || data.Organe === 'Ovaire' || data.Organe === 'Rein' || data.Organe === 'Rétropéritoine' || data.Organe === 'Oesophage' || data.Organe === 'Colo-rectum') && (
                        <>
                            <Col span={8}>
                                <p className='inputPatient2'>Biologie moléculaire/Autre</p>


                                <Select
                                    value={biologieMoleculaire}
                                    onChange={handleChangeBiomoleculaire}
                                    style={{ width: '950px', height: "33px", }}
                                >
                                    {optionBiologieMoleculaire.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>                </Col>


                            {AfficheChexbox8 && (
                                <Col span={24} style={{ marginTop: '20px' }}>
                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['Mutation Tp53'] === "Oui"}
                                        onChange={(e) => Mutation('Mutation Tp53', e.target.checked ? "Oui" : "Non")}
                                    >
                                        Oui
                                    </Checkbox>

                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['Mutation Tp53'] === "Non"}
                                        onChange={(e) => Mutation('Mutation Tp53', e.target.checked ? "Non" : "Oui")}
                                    >
                                        Non
                                    </Checkbox>
                                </Col>
                            )}


                            {AfficheChexbox32 && (
                                <Col span={24} style={{ marginTop: '20px' }}>
                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "KI 67"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "KI 67" : "AC anti-bcl2")}
                                    >
                                        KI 67
                                    </Checkbox>

                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "AC anti-bcl2"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "AC anti-bcl2" : "KI 67")}
                                    >
                                        AC anti-bcl2
                                    </Checkbox>
                                </Col>
                            )}

                            {AfficheChexbox33 && (
                                <Col span={24} style={{ marginTop: '20px' }}>
                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "chromogranine A"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "chromogranine A" : "synaptophysine")}
                                    >
                                        chromogranine A
                                    </Checkbox>

                                    <Checkbox
                                        checked={BiologieMoleculaireMutationTp53['IHC'] === "synaptophysine"}
                                        onChange={(e) => Mutation('IHC', e.target.checked ? "synaptophysine" : "chromogranine A")}
                                    >
                                        synaptophysine
                                    </Checkbox>
                                </Col>
                            )}

                        </>

                    )}

                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Ovaire' && (
                        <Row gutter={6}>
                            <Col span={6}>
                                <p className='inputPatient2'>K-ras, BRAF et PIK3CA</p>
                                <Select mode="tags"
                                    name='KraBrafPik'
                                    value={KraBrafPik}
                                    onChange={(value) => setKraBrafPik(value)}
                                    style={{ width: "230px" }}>
                                    <Option key="K-ras" value="K-ras">K-ras</Option>
                                    <Option key="BRAF" value="BRAF">BRAF</Option>
                                    <Option key="PIK3CA" value="K-ras">PIK3CA</Option>
                                </Select>
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>FOXL2</p>
                                <Input placeholder='FOXL2'
                                    name='Fox'
                                    value={Fox}
                                    onChange={(value) => setFox(value)} /></Col>
                            <Col span={6}>
                                <p className='inputPatient2'>BRCA</p>
                                <Select mode="tags" style={{ width: "230px" }}>
                                    <Option key="BRCA1" value="BRCA1">BRCA1</Option>
                                    <Option key="BRCA2" value="BRCA2">BRCA2</Option>                                </Select>
                            </Col>
                            <Col span={6}>
                                <p className='inputPatient2'>p53</p>
                                <Input placeholder='p53'
                                    name='PcinquanteTrois'
                                    value={PcinquanteTrois}
                                    onChange={(value) => setPcinquanteTrois(value)} /></Col>
                        </Row>
                    )}

                    {(data.Specialite === 'ORL' && (data.Organe === 'Cavum' || data.Organe === 'Nasopharynx')) && (
                        <>
                            <p className='inputPatient2'>Facteurs pronostiques</p>
                            <Row gutter={10}>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Invasion locorégionale</p>
                                    <Select
                                        mode="tags"
                                        value={invasion}
                                        onChange={(value) => setInvasion(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionInvasion.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Envahissement ganglionnaire N+</p>
                                    <Select
                                        mode="tags"
                                        value={Envahissement}
                                        onChange={(value) => setEnvahissement(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionEnvahissement.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Taille & le volume tumoral</p>
                                    <Select
                                        mode="tags"
                                        value={tailleTumoral}
                                        onChange={(value) => setTailleTumoral(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionTailleTumoral.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Sexe</p>
                                    <Select
                                        mode="tags"
                                        value={sexe}
                                        onChange={(value) => setSexe(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionSexe.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={6}>

                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Age jeune</p>
                                    <Select
                                        mode="tags"
                                        value={ageJeune}
                                        onChange={(value) => setAgeJeune(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionAgeJeune.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Taux des AC préTTT et post-TTT</p>
                                    <Select
                                        mode="tags"
                                        value={taux}
                                        onChange={(value) => setTaux(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionTaux.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>Hémoglobine</p>
                                    <Select
                                        mode="tags"
                                        value={Hemoglobine}
                                        onChange={(value) => setHemoglobine(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionHemoglobine.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>

                                <Col span={6} style={{}}>
                                    <p className='inputPatient2'>D’autres facteurs moléculaires</p>
                                    <Select
                                        mode="tags"
                                        value={facteurMoleculaire}
                                        onChange={(value) => setfacteurMoleculaire(value)}
                                        style={{ width: "210px", height: "33px", }}
                                    >
                                        {optionFacteurMoleculaire.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </>

                    )}


                    {(data.Specialite === 'Thorax' && data.Organe === 'Poumon') &&
                        (

                            <Col span={24}>
                                <p className='inputPatient2'>immunohistochimie</p>

                                <Select
                                    // mode="tags"
                                    value={immuno}
                                    onChange={(value) => setImmuno(value)}
                                    style={{ width: (data.Specialite === 'Thorax' && (data.Organe === 'Parois thoracique' || data.Organe === 'Plèvre' || data.Organe === 'Poumon')) ? "950px" : "470px", height: "33px", }}
                                >
                                    {optionImmuno.map((option) => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        )}

                    {AfficheChexbox13 && (
                        <>
                            <Row gutter={6}>
                                <Col span={6}>
                                    <p className='inputPatient2'>EGFR</p>
                                    <Select value={EGFR} onChange={(value) => setEGFR(value)} style={{ width: "230px" }}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>ALK</p>
                                    <Select value={ALK} onChange={(value) => setALK(value)} style={{ width: "230px" }}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>ROSI</p>
                                    <Select style={{ width: "230px" }} value={ROSI} onChange={(value) => setROSI(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>c-MET</p>
                                    <Select style={{ width: "230px" }} value={CMET} onChange={(value) => setCMET(value)} >
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row gutter={6}>
                                <Col span={6}>
                                    <p className='inputPatient2'>HER2</p>
                                    <Select style={{ width: "230px" }} value={HER2} onChange={(value) => setHER2(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>KRAS G12C</p>
                                    <Select style={{ width: "230px" }} value={KRAS} onChange={(value) => setKRAS(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>BRAF</p>
                                    <Select style={{ width: "230px" }} value={BRAF} onChange={(value) => setBRAF(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={6}>
                                    <p className='inputPatient2'>NTRK</p>
                                    <Select style={{ width: "230px" }} value={NTRK} onChange={(value) => setNTRK(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>

                        </>
                    )}

                    {AfficheChexbox14 && (
                        <>
                            <Row gutter={6}>
                                <Col span={12}>
                                    <p className='inputPatient2'>RET</p>
                                    <Select style={{ width: "470px" }} value={RET} onChange={(value) => setRET(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>NRG1</p>
                                    <Select style={{ width: "470px" }} value={NRG1} onChange={(value) => setNRG1(value)}>
                                        <Option value="Oui">Oui</Option>
                                        <Option value="Non">Non</Option>
                                    </Select>
                                </Col>
                            </Row>

                        </>
                    )}

                    <Row gutter={6}>
                        {(data.Specialite === 'Gynécologie' && data.Organe === 'Néoplasies trophoblastiques gestationnelles') ?
                            (
                                <>
                                    <Col span={24}>
                                        <p className='inputPatient2'>Stade clinique</p>
                                        <Select
                                            mode='tags'
                                            name="stade clinique"
                                            // placeholder="Sélectionnez le Tumeur primitive"
                                            value={tumeur}
                                            style={{ width: "100%" }}
                                            onChange={(value) => setTumeur(value)}
                                        >
                                            <OptGroup label="Stade">
                                                {optionTumeur.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>

                                            <OptGroup label="Score">
                                                {optionAdenophatie.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>

                                            <OptGroup label="OMS FIGO">
                                                {optionMétastase.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}
                                            </OptGroup>
                                        </Select>
                                    </Col>
                                </>
                            ) :
                            (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs orbitaires') ?
                                (
                                    <>
                                        <Col span={24}>
                                            <p className='inputPatient2'>Stade clinique
                                            </p>
                                            International Neuroblastoma Risk Group Staging System (INRGSS)/ système international de stadification par catégorie de risque du neuroblastome

                                            <Select
                                                mode='tags'
                                                name="stade clinique"
                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                value={tumeur}
                                                style={{ width: "100%" }}
                                                onChange={(value) => setTumeur(value)}
                                            >
                                                {optionTumeur.map((option) => (
                                                    <Option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </Option>
                                                ))}


                                            </Select>
                                        </Col>
                                    </>) :
                                (data.Specialite === 'Pédiatrie' && data.Organe === 'Os') ?
                                    (
                                        <>
                                            <Col span={24}>
                                                <p className='inputPatient2'>Stade clinique
                                                </p>

                                                <Select
                                                    mode='tags'
                                                    name="stade clinique"
                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                    value={tumeur}
                                                    style={{ width: "100%" }}
                                                    onChange={(value) => setTumeur(value)}
                                                >
                                                    {optionTumeur.map((option) => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}


                                                </Select>
                                            </Col>
                                        </>)
                                    : (data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine') ?
                                        (
                                            <>
                                                <Col span={24}>
                                                    <p className='inputPatient2'>Stade clinique
                                                    </p>

                                                    <Select
                                                        mode='tags'
                                                        name="stade clinique"
                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                        value={tumeur}
                                                        style={{ width: "100%" }}
                                                        onChange={(value) => setTumeur(value)}
                                                    >
                                                        {optionTumeur.map((option) => (
                                                            <Option key={option.value} value={option.value}>
                                                                {option.label}
                                                            </Option>
                                                        ))}


                                                    </Select>
                                                </Col>
                                            </>)
                                        :
                                        (data.Specialite === 'Pédiatrie' && data.Organe === 'Oesophage' || data.Organe === 'Colo-rectum') ?
                                            (
                                                <>
                                                    <Col span={12}>
                                                        <p className='inputPatient2'>Adénok
                                                        </p>
                                                        <Input
                                                            placeholder="Adénok"
                                                            name='Adenok'
                                                            value={Adenok}
                                                            onChange={(e) => setAdenok(e.target.value)}
                                                            style={{ width: "100%" }}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <p className='inputPatient2'>Classification TNM (cf adultes)
                                                        </p>
                                                        <Select
                                                            mode='tags'
                                                            name="stade clinique"
                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                            value={tumeur}
                                                            style={{ width: "100%" }}
                                                            onChange={(value) => setTumeur(value)}
                                                        >
                                                            {optionTumeur.map((option) => (
                                                                <Option key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </Option>
                                                            ))}


                                                        </Select>
                                                    </Col>
                                                </>) :
                                            (data.Specialite === 'Pédiatrie' && data.Organe === 'Oesophage') ?
                                                (
                                                    <>

                                                        <Col span={12}>
                                                            <p className='inputPatient2'>Classification TNM (cf adultes)
                                                            </p>
                                                            <Select
                                                                mode='tags'
                                                                name="stade clinique"
                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                value={tumeur}
                                                                style={{ width: "100%" }}
                                                                onChange={(value) => setTumeur(value)}
                                                            >
                                                                {optionTumeur.map((option) => (
                                                                    <Option key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </Option>
                                                                ))}


                                                            </Select>
                                                        </Col>
                                                    </>) :
                                                (data.Specialite === 'Hématologie' && data.Organe === 'Syndromes myélodysplasiques') ?
                                                    (
                                                        <>

                                                            <Col span={24}>
                                                                <p className='inputPatient2'>Classification OMS des syndromes myélodysplasiques 2016
                                                                </p>
                                                                <Select
                                                                    mode='tags'
                                                                    name="ClassificationOmsSyndromesMyelodysplasiques"
                                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                                    value={ClassificationOmsSyndromesMyelodysplasiques}
                                                                    style={{ width: "100%" }}
                                                                    onChange={(value) => setClassificationOmsSyndromesMyelodysplasiques(value)}
                                                                >
                                                                    {optionClassificationOMS.map((option) => (
                                                                        <Option key={option.value} value={option.value}>
                                                                            {option.label}
                                                                        </Option>
                                                                    ))}


                                                                </Select>
                                                            </Col>
                                                        </>) :

                                                    (data.Specialite === 'Hématologie' && data.Organe === 'Syndrome Myéloprolifératif Ph1 négative') ?
                                                        (
                                                            <>

                                                                <Col span={24}>
                                                                    <p className='inputPatient2'>Classification OMS 2016 des syndromes myéloprolifératifs Philadelphie négatif
                                                                    </p>
                                                                    <Select
                                                                        mode='tags'
                                                                        name="ClassificationOmsSyndromesMyelodysplasiques"
                                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                                        value={ClassificationOmsSyndromesMyelodysplasiques}
                                                                        style={{ width: "100%" }}
                                                                        onChange={(value) => setClassificationOmsSyndromesMyelodysplasiques(value)}
                                                                    >
                                                                        {optionClassificationOMS.map((option) => (
                                                                            <Option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </Option>
                                                                        ))}


                                                                    </Select>
                                                                </Col>
                                                            </>) :

                                                        (data.Specialite === 'Pédiatrie' && data.Organe === 'Rein') ?
                                                            (
                                                                <>
                                                                    <Col span={12}>
                                                                        <p className='inputPatient2'>Stade clinique
                                                                        </p>

                                                                        <Select
                                                                            mode='tags'
                                                                            name="stade clinique"
                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                            value={tumeur}
                                                                            style={{ width: "100%" }}
                                                                            onChange={(value) => setTumeur(value)}
                                                                        >
                                                                            {optionTumeur.map((option) => (
                                                                                <Option key={option.value} value={option.value}>
                                                                                    {option.label}
                                                                                </Option>
                                                                            ))}


                                                                        </Select>
                                                                    </Col>
                                                                    <Col span={12}>
                                                                        <p className='inputPatient2'>Classification
                                                                        </p>

                                                                        <Select
                                                                            mode='tags'
                                                                            name="Classification"
                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                            value={ClassificationAltmann}
                                                                            style={{ width: "100%" }}
                                                                            onChange={(value) => setClassificationAltmann(value)}
                                                                        >
                                                                            {optionClassification.map((option) => (
                                                                                <Option key={option.value} value={option.value}>
                                                                                    {option.label}
                                                                                </Option>
                                                                            ))}


                                                                        </Select>
                                                                    </Col>
                                                                </>) :
                                                            (data.Specialite === 'Pédiatrie' && data.Organe === 'Voie excretrice') ?
                                                                (
                                                                    <>
                                                                        <Col span={24}>
                                                                            <p className='inputPatient2'>Stade clinique
                                                                            </p>

                                                                            <Select
                                                                                mode='tags'
                                                                                name="stade clinique"
                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                value={tumeur}
                                                                                style={{ width: "100%" }}
                                                                                onChange={(value) => setTumeur(value)}
                                                                            >
                                                                                <OptGroup label="T : Tumeur Primaire">
                                                                                    {optionTumeur.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}
                                                                                </OptGroup>

                                                                                <OptGroup label="N : Ganglions Lymphatiques Régionaux">
                                                                                    {optionAdenophatie.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}
                                                                                </OptGroup>

                                                                                <OptGroup label="M : Métastases à Distance">
                                                                                    {optionMétastase.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}
                                                                                </OptGroup>


                                                                            </Select>
                                                                        </Col>
                                                                    </>) :

                                                                (data.Specialite === 'Pédiatrie' && data.Organe === 'Ovaire') ?
                                                                    (
                                                                        <>
                                                                            <Col span={24}>
                                                                                <p className='inputPatient2'>Facteurs pronostiques
                                                                                </p>

                                                                                <Select
                                                                                    mode='tags'
                                                                                    name="FacteursPronostique"
                                                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                                                    value={FacteursPronostiques}
                                                                                    style={{ width: "100%" }}
                                                                                    onChange={(value) => setFacteursPronostiques(value)}
                                                                                >
                                                                                    {optionFacteurPronostique.map((option) => (
                                                                                        <Option key={option.value} value={option.value}>
                                                                                            {option.label}
                                                                                        </Option>
                                                                                    ))}


                                                                                </Select>
                                                                            </Col>
                                                                        </>) :
                                                                    (data.Specialite === 'Pédiatrie' && data.Organe === 'Corticosurrenale') ?
                                                                        (
                                                                            <>
                                                                                <Col span={24}>
                                                                                    <p className='inputPatient2'>Stade clinique
                                                                                    </p>
                                                                                    International Neuroblastoma Risk Group Staging System (INRGSS)/ système international de stadification par catégorie de risque du neuroblastome

                                                                                    <Select
                                                                                        mode='tags'
                                                                                        name="stade clinique"
                                                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                                                        value={tumeur}
                                                                                        style={{ width: "100%" }}
                                                                                        onChange={(value) => setTumeur(value)}
                                                                                    >
                                                                                        {optionTumeur.map((option) => (
                                                                                            <Option key={option.value} value={option.value}>
                                                                                                {option.label}
                                                                                            </Option>
                                                                                        ))}


                                                                                    </Select>
                                                                                </Col>
                                                                            </>) :
                                                                        (data.Specialite === 'Pédiatrie' && data.Organe === 'Foie/Pédiatrie') ?
                                                                            (
                                                                                <>
                                                                                    <Col span={24}>
                                                                                        <p className='inputPatient2'>Stade clinique:LOCALISE OU METASTATIQUE
                                                                                        </p>

                                                                                        <Select
                                                                                            mode='tags'
                                                                                            name="stade clinique"
                                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                                            value={tumeur}
                                                                                            style={{ width: "100%" }}
                                                                                            onChange={(value) => setTumeur(value)}
                                                                                        >
                                                                                            {optionTumeur.map((option) => (
                                                                                                <Option key={option.value} value={option.value}>
                                                                                                    {option.label}
                                                                                                </Option>
                                                                                            ))}


                                                                                        </Select>
                                                                                    </Col>
                                                                                </>) :
                                                                            (data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne') ?
                                                                                (
                                                                                    <>
                                                                                        <Col span={24}>
                                                                                            <p className='inputPatient2'>Classification de Altmann:
                                                                                            </p>

                                                                                            <Select
                                                                                                mode='tags'
                                                                                                name="Classification de Altmann"
                                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                value={tumeur}
                                                                                                style={{ width: "100%" }}
                                                                                                onChange={(value) => setTumeur(value)}
                                                                                            >
                                                                                                {optionTumeur.map((option) => (
                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                        {option.label}
                                                                                                    </Option>
                                                                                                ))}


                                                                                            </Select>
                                                                                        </Col>
                                                                                    </>) :
                                                                                (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs maxillo faciales') ?
                                                                                    (
                                                                                        <>
                                                                                            <Col span={24}>
                                                                                                <p className='inputPatient2'>Stade clinique
                                                                                                </p>
                                                                                                Système de stadification pré-traitement basé sur le TNM
                                                                                                <Select
                                                                                                    mode='tags'
                                                                                                    name="stade clinique"
                                                                                                    // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                    value={tumeur}
                                                                                                    style={{ width: "100%" }}
                                                                                                    onChange={(value) => setTumeur(value)}
                                                                                                >
                                                                                                    {optionTumeur.map((option) => (
                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                            {option.label}
                                                                                                        </Option>
                                                                                                    ))}


                                                                                                </Select>
                                                                                            </Col>
                                                                                        </>) :
                                                                                    (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs médiastinales') ?
                                                                                        (
                                                                                            <>
                                                                                                <Col span={24}>
                                                                                                    <p className='inputPatient2'>Stade clinique
                                                                                                    </p>
                                                                                                    International Neuroblastoma Risk Group Staging System (INRGSS)/ système international de stadification par catégorie de risque du neuroblastome                                                     <Select
                                                                                                        mode='tags'
                                                                                                        name="stade clinique"
                                                                                                        // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                        value={tumeur}
                                                                                                        style={{ width: "100%" }}
                                                                                                        onChange={(value) => setTumeur(value)}
                                                                                                    >
                                                                                                        {optionTumeur.map((option) => (
                                                                                                            <Option key={option.value} value={option.value}>
                                                                                                                {option.label}
                                                                                                            </Option>
                                                                                                        ))}


                                                                                                    </Select>
                                                                                                </Col>
                                                                                            </>)

                                                                                        : (data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales') ?
                                                                                            (
                                                                                                <>
                                                                                                    <Col span={24}>
                                                                                                        <p className='inputPatient2'>Stade clinique</p>
                                                                                                        <Select
                                                                                                            mode='tags'
                                                                                                            name="stade clinique"
                                                                                                            // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                            value={tumeur}
                                                                                                            style={{ width: "100%" }}
                                                                                                            onChange={(value) => setTumeur(value)}
                                                                                                        >
                                                                                                            <OptGroup label="T">
                                                                                                                {optionTumeur.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </OptGroup>

                                                                                                            <OptGroup label="N">
                                                                                                                {optionAdenophatie.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </OptGroup>

                                                                                                            <OptGroup label="M">
                                                                                                                {optionMétastase.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </OptGroup>
                                                                                                        </Select>
                                                                                                    </Col>
                                                                                                </>
                                                                                            )
                                                                                            : (data.Specialite === 'Cancers Rares') ?
                                                                                                (
                                                                                                    <>
                                                                                                        <Col span={24}>
                                                                                                            <p className='inputPatient2'>Stade clinique</p>
                                                                                                            <Select
                                                                                                                mode='tags'
                                                                                                                name="stade clinique"
                                                                                                                // placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                value={tumeur}
                                                                                                                style={{ width: "100%" }}
                                                                                                                onChange={(value) => setTumeur(value)}
                                                                                                            >
                                                                                                                <OptGroup label="T">
                                                                                                                    {optionTumeur.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </OptGroup>

                                                                                                                <OptGroup label="N">
                                                                                                                    {optionAdenophatie.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </OptGroup>

                                                                                                                <OptGroup label="M">
                                                                                                                    {optionMétastase.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </OptGroup>
                                                                                                            </Select>
                                                                                                        </Col>
                                                                                                        <Col>
                                                                                                            <p className='inputPatient2'>Consultation oncogénétique </p>

                                                                                                            <Select mode='tags'
                                                                                                                name='ConsultationOncogetique'
                                                                                                                value={ConsultationOncogetique}
                                                                                                                onChange={(value) => setConsultationOncogetique(value)}
                                                                                                                style={{ width: "950px" }}>
                                                                                                                <Option value=""></Option>
                                                                                                            </Select>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                )
                                                                                                : (data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome Hodgkin adulte')) ? (
                                                                                                    <>
                                                                                                        <Col span={24}>
                                                                                                            <p className='inputPatient2'>Stade Ann Arbor</p>
                                                                                                            <Select
                                                                                                                mode='tags'
                                                                                                                // name="stade clinique"
                                                                                                                placeholder="Stade Ann Arbor"
                                                                                                                value={tumeur}
                                                                                                                style={{ width: "100%" }}
                                                                                                                onChange={handleStadeChange}
                                                                                                            >
                                                                                                                {optionTumeur.map((option) => (
                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                        {option.label}
                                                                                                                    </Option>
                                                                                                                ))}
                                                                                                            </Select>

                                                                                                            {AfficheChexbox1 && (
                                                                                                                <Col span={24} style={{}}>
                                                                                                                    <Checkbox onChange={handleCheckboxChange}>Oui</Checkbox>
                                                                                                                    <Checkbox onChange={() => setCheckOui(false)}>Non</Checkbox>
                                                                                                                </Col>
                                                                                                            )}

                                                                                                            {CheckOui && (
                                                                                                                <Row>
                                                                                                                    <p style={{ fontWeight: 650 }}>Décrire le résultat ici</p>
                                                                                                                    <Col span={24} >
                                                                                                                        <TextArea placeholder="Décrire ici..." name='ResultateFacteur' value={ResultatFacteur} onChange={(value) => setResultatFacteur(value)} style={{ width: '950px' }} />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            )}
                                                                                                        </Col>
                                                                                                        <p className='inputPatient2'>Facteurs pronostiques</p>




                                                                                                        {AfficheChexbox2 && (
                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                <p className='inputPatient2'>EORTC</p>
                                                                                                                <Checkbox onChange={handleCheckboxChange1}>favorable</Checkbox>
                                                                                                                <Checkbox onChange={handleCheckboxChange1}>défavorable</Checkbox>
                                                                                                            </Col>
                                                                                                        )}

                                                                                                        {AfficheChexbox3 && (
                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                <p className='inputPatient2'>IPS</p>
                                                                                                                <Checkbox onChange={handleCheckboxChange1}>Favorable</Checkbox>
                                                                                                                <Checkbox onChange={handleCheckboxChange1}>Défavorable</Checkbox>
                                                                                                            </Col>
                                                                                                        )}

                                                                                                    </>

                                                                                                )
                                                                                                    : (data.Specialite === 'Hématologie' && (data.Organe === 'Lymphome non hodgkinien adulte')) ? (
                                                                                                        <>
                                                                                                            <Col span={24}>
                                                                                                                <p className='inputPatient2'>Stade Ann Arbor</p>
                                                                                                                <Select
                                                                                                                    mode='tags'
                                                                                                                    // name="stade clinique"
                                                                                                                    placeholder="Stade Ann Arbor"
                                                                                                                    value={tumeur}
                                                                                                                    style={{ width: "100%" }}
                                                                                                                    onChange={handleStadeChange1}
                                                                                                                >
                                                                                                                    {optionTumeur.map((option) => (
                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                            {option.label}
                                                                                                                        </Option>
                                                                                                                    ))}
                                                                                                                </Select>
                                                                                                            </Col>
                                                                                                            <p className='inputPatient2'>Scores pronostiques</p>


                                                                                                            {AfficheChexbox1 && (
                                                                                                                <Row style={{ marginTop: "20px" }}>
                                                                                                                    <Col span={24}>
                                                                                                                        <Checkbox onChange={handleCheckboxChange}>Oui</Checkbox>
                                                                                                                        <Checkbox onChange={() => setCheckOui(false)}>Non</Checkbox>
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            )}

                                                                                                            {CheckOui && (
                                                                                                                <Row>
                                                                                                                    <p style={{ fontWeight: 650 }}>Décrire le résultat ici</p>
                                                                                                                    <Col span={24} >
                                                                                                                        <TextArea placeholder="Décrire ici..." name='ResultateFacteur' value={ResultatFacteur} onChange={(value) => setResultatFacteur(value)} style={{ width: '950px' }} />
                                                                                                                    </Col>
                                                                                                                </Row>
                                                                                                            )}

                                                                                                            {AfficheChexbox10 && (
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                    <p className='inputPatient2'>FLIPI</p>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('FLIPI', 'faible')}>faible</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('FLIPI', 'intermédiaire')}>intermédiaire</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('FLIPI', 'défavorable')}>défavorable</Checkbox>
                                                                                                                </Col>
                                                                                                            )}

                                                                                                            {AfficheChexbox11 && (
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                    <p className='inputPatient2'>MIPI</p>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('MIPI', 'faible')}>faible</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('MIPI', 'intermédiaire')}>intermédiaire</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('MIPI', 'défavorable')}>défavorable</Checkbox>
                                                                                                                </Col>
                                                                                                            )}

                                                                                                            {AfficheChexbox12 && (
                                                                                                                <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                    <p className='inputPatient2'>IPI</p>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('IPI', 'risque faible')}>risque faible</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('IPI', 'faible intermédiaire')}>faible intermédiaire</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('IPI', 'intermédiaire élevé')}>intermédiaire élevé</Checkbox>
                                                                                                                    <Checkbox onChange={handleScorePronosticChange('IPI', 'haut risque')}>haut risque</Checkbox>
                                                                                                                </Col>
                                                                                                            )}
                                                                                                        </>


                                                                                                    )

                                                                                                        : (data.Specialite === 'Hématologie' && data.Organe === 'Leucémie Lymphoïde Chronique') ? (
                                                                                                            <>
                                                                                                                <Col span={24} >
                                                                                                                    <p className='inputPatient2'>Classification de Bine</p>
                                                                                                                    <Select mode='tags' style={{ width: "950px" }} value={ClassificationBinet} onChange={() => setClassificationBinet}>
                                                                                                                        <option value="Stade A non actif">Stade A non actif</option>
                                                                                                                        <option value="Stade A actif">Stade A actif</option>
                                                                                                                        <option value="Stade A actif">Stade B</option>
                                                                                                                        <option value="Stade A actif">Stade C</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                        ) : (data.Specialite === 'Hématologie' && data.Organe === 'Leucémie myéloïde Chronique') ? (
                                                                                                            <>
                                                                                                                <Col span={8} >
                                                                                                                    <p className='inputPatient2'>Stade de Sokal</p>
                                                                                                                    <Select made='tags' style={{ width: "310px" }} value={StadeSokal} onChange={() => setStadeSokal} >
                                                                                                                        <option value="Faible risque">Faible risque</option>
                                                                                                                        <option value="Risque intermédiaire">Risque intermédiaire</option>
                                                                                                                        <option value="Risque élevé">Risque élevé</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                                <Col span={8} >
                                                                                                                    <p className='inputPatient2'>Score de Hasford</p>
                                                                                                                    <Select style={{ width: "310px" }} value={ScoreHasford} onChange={() => setScoreHasford}>
                                                                                                                        <option value="Faible risque">Faible risque</option>
                                                                                                                        <option value="Risque intermédiaire">Risque intermédiaire</option>
                                                                                                                        <option value="Risque élevé">Risque élevé</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                                <Col span={8} >
                                                                                                                    <p className='inputPatient2'>Score d’EUTOS</p>
                                                                                                                    <Select style={{ width: "310px" }} value={ScoreEUTOS} onChange={() => setScoreEUTOS}>
                                                                                                                        <option value="Faible risque">Faible risque</option>
                                                                                                                        <option value="Risque élevé">Risque élevé</option>
                                                                                                                    </Select>
                                                                                                                </Col>
                                                                                                            </>
                                                                                                        )

                                                                                                            : (data.Specialite === 'Hématologie' && data.Organe === 'Myélome multiple') ? (
                                                                                                                <>
                                                                                                                    <Col span={24} >
                                                                                                                        <p className='inputPatient2'>IMWG 2014</p>
                                                                                                                        <Select style={{ width: "950px" }} value={IMWG2014} onChange={() => setIMWG2014}>
                                                                                                                            <option value="Myélome symptomatique">Myélome symptomatique</option>
                                                                                                                            <option value="Myélome indolent">Myélome indolent</option>
                                                                                                                            <option value="Gammapathie monoclonale de signification indéterminée">Gammapathie monoclonale de signification indéterminée</option>
                                                                                                                            <option value="Plasmocytome solitaire">Plasmocytome solitaire</option>
                                                                                                                        </Select>
                                                                                                                    </Col>

                                                                                                                    <Col span={24} >
                                                                                                                        <p className='inputPatient2'>Classification de Salmon et Diurie</p>
                                                                                                                        <Select style={{ width: "950px" }} value={ClassificationSalmonDiurie} onChange={() => setClassificationSalmonDiurie}>
                                                                                                                            <option value="Oui">Oui</option>
                                                                                                                            <option value="Non">Non</option>

                                                                                                                        </Select>
                                                                                                                        {ClassificationSalmonDiurie.includes("Oui") && (
                                                                                                                            <>
                                                                                                                                <div style={{ marginTop: "10px" }}>
                                                                                                                                    <p>Choisissez le stade :</p>
                                                                                                                                    <Select made='tags' style={{ width: "950px" }} value={ClassificationSalmonDiurieStade} onChange={(value) => setClassificationSalmonDiurieStade(value)}>
                                                                                                                                        <option value="Stade IA">Stade IA</option>
                                                                                                                                        <option value="Stade IB">Stade IB</option>
                                                                                                                                        <option value="Stade IB">Stade IIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIB</option>
                                                                                                                                        <option value="Stade IB">Stade IIIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIIB</option>

                                                                                                                                    </Select>
                                                                                                                                </div>

                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </Col>
                                                                                                                    <Col span={24} >
                                                                                                                        <p className='inputPatient2'>Classification ISS-révisée</p>
                                                                                                                        <Select style={{ width: "950px" }} value={ClassificationISSRevisee} onChange={(value) => setClassificationISSRevisee(value)}>
                                                                                                                            <option value="Oui">Oui</option>
                                                                                                                            <option value="Non">Non</option>

                                                                                                                        </Select>
                                                                                                                        {ClassificationISSRevisee.includes("Oui") && (
                                                                                                                            <>
                                                                                                                                <div style={{ marginTop: "10px" }}>
                                                                                                                                    <p>Choisissez le stade :</p>
                                                                                                                                    <Select style={{ width: "950px" }} made='tags' value={ClassificationISSReviseeStade} onChange={(value) => setClassificationISSReviseeStade(value)}>
                                                                                                                                        <option value="Stade IA">Stade IA</option>
                                                                                                                                        <option value="Stade IB">Stade IB</option>
                                                                                                                                        <option value="Stade IB">Stade IIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIB</option>
                                                                                                                                        <option value="Stade IB">Stade IIIA</option>
                                                                                                                                        <option value="Stade IB">Stade IIIB</option>

                                                                                                                                    </Select>
                                                                                                                                </div>

                                                                                                                            </>
                                                                                                                        )}
                                                                                                                    </Col>
                                                                                                                    <Col span={24}>
                                                                                                                        <p className='inputPatient2'>Autres facteurs pronostic</p>
                                                                                                                        <Select
                                                                                                                            style={{ width: "950px" }}
                                                                                                                            placeholder="Choisissez un facteur"
                                                                                                                            onChange={handleFacteurChange}
                                                                                                                            mode="tags"
                                                                                                                            name='FactuersPronostiques'
                                                                                                                            value={FacteursPronostiques}
                                                                                                                        >
                                                                                                                            <Option value="CRP">CRP</Option>
                                                                                                                            <Option value="Anomalies cytogénétiques défavorable">Anomalies cytogénétiques défavorable</Option>
                                                                                                                        </Select>

                                                                                                                        {/* Afficher les cases à cocher si le facteur est sélectionné */}
                                                                                                                        {AfficheChexbox27 && (
                                                                                                                            <div style={{ marginTop: "10px" }}>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Oui"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Oui" : null)}
                                                                                                                                >
                                                                                                                                    Oui
                                                                                                                                </Checkbox>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Non"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Non" : null)}
                                                                                                                                >
                                                                                                                                    Non
                                                                                                                                </Checkbox>
                                                                                                                            </div>
                                                                                                                        )}

                                                                                                                        {AfficheChexbox28 && (
                                                                                                                            <div style={{ marginTop: "10px" }}>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Oui"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Oui" : null)}
                                                                                                                                >
                                                                                                                                    Oui
                                                                                                                                </Checkbox>
                                                                                                                                <Checkbox
                                                                                                                                    checked={facteurPronostique[FacteursPronostiques] === "Non"}
                                                                                                                                    onChange={(e) => toggleFacteurStatus(FacteursPronostiques, e.target.checked ? "Non" : null)}
                                                                                                                                >
                                                                                                                                    Non
                                                                                                                                </Checkbox>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Col>







                                                                                                                </>
                                                                                                            )


                                                                                                                : (data.Specialite === 'Hématologie' && data.Organe === 'LA Adulte') ? (
                                                                                                                    <>
                                                                                                                        <Col span={24}>
                                                                                                                            <p className='inputPatient2'>Stade pronostic</p>
                                                                                                                            <Select
                                                                                                                                made='tags'
                                                                                                                                name="stade pronostic"
                                                                                                                                placeholder="Stade pronostique"
                                                                                                                                value={tumeur}
                                                                                                                                style={{ width: "100%" }}
                                                                                                                                onChange={handleStadePronosticChange}
                                                                                                                            >
                                                                                                                                {optionTumeur.map((option) => (
                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                        {option.label}
                                                                                                                                    </Option>
                                                                                                                                ))}
                                                                                                                            </Select>
                                                                                                                        </Col>

                                                                                                                        {AfficheChexbox4 && (
                                                                                                                            <Col span={24} style={{ marginTop: "20px" }}>
                                                                                                                                <Checkbox onChange={handleCheckboxChange1}>Favorable</Checkbox>
                                                                                                                                <Checkbox onChange={handleCheckboxChange1}>Intermédiaire</Checkbox>
                                                                                                                                <Checkbox onChange={handleCheckboxChange1}>Défavorable</Checkbox>
                                                                                                                            </Col>
                                                                                                                        )}
                                                                                                                        {AfficheChexbox5 && (
                                                                                                                            <>
                                                                                                                                <Col span={6} >
                                                                                                                                    <p className='inputPatient2'>Age</p>
                                                                                                                                    <Input placeholder="Age" value={Age} onChange={(e) => setAge(e.target.value)} />
                                                                                                                                </Col>
                                                                                                                                <Col span={6}>
                                                                                                                                    <p className='inputPatient2'>{"Hyperleucocytose(>50G/L)"}</p>
                                                                                                                                    <Input type='number' placeholder="Hyperleucocytose" />
                                                                                                                                </Col>
                                                                                                                                <Col span={6} >
                                                                                                                                    <p className='inputPatient2'>Atteinte testiculaire</p>
                                                                                                                                    <Select style={{ width: "230px" }} value={AtteinteTesticulaire} onChange={(value) => setAtteinteTesticulaire(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                                <Col span={6} >
                                                                                                                                    <p className='inputPatient2'>Atteinte LCR</p>
                                                                                                                                    <Select style={{ width: "230px" }} value={AtteinteLCR} onChange={(value) => setAtteinteLCR(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>

                                                                                                                                <Col span={8} >
                                                                                                                                    <p className='inputPatient2'>Corticosensibilité</p>
                                                                                                                                    <Select style={{ width: "310px" }} value={CorticoSensibilite} onChange={(value) => setCorticoSensibilite(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                                <Col span={8} >
                                                                                                                                    <p className='inputPatient2'>Facteurs génétiques de mauvais pronostic</p>
                                                                                                                                    <Select style={{ width: "310px" }} value={FacteurGenetique} onChange={(value) => setFacteurGenetique(value)}>
                                                                                                                                        <option value="Oui">Oui</option>
                                                                                                                                        <option value="Non">Non</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                                <Col span={8} >
                                                                                                                                    <p className='inputPatient2'>Phénotype</p>
                                                                                                                                    <Select style={{ width: "310px" }} value={Phenotype} onChange={(value) => setPhenotype(value)}>
                                                                                                                                        <option value="Oui">B</option>
                                                                                                                                        <option value="Non">T</option>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                            </>
                                                                                                                        )}

                                                                                                                    </>)

                                                                                                                    : (data.Specialite === 'Urologie' && data.Organe === 'Vessie/Arbre urinaire') ? (
                                                                                                                        <>
                                                                                                                            <Col span={24}>
                                                                                                                                <p className='inputPatient2'>Stade clinique :</p>
                                                                                                                                <Select
                                                                                                                                    made='tags'
                                                                                                                                    name="Stade clinique"
                                                                                                                                    placeholder="Stade clinique"
                                                                                                                                    value={tumeur}
                                                                                                                                    style={{ width: "100%" }}
                                                                                                                                    onChange={(value) => setTumeur(value)}
                                                                                                                                >
                                                                                                                                    {optionTumeur.map((option) => (
                                                                                                                                        <Option key={option.value} value={option.value}>
                                                                                                                                            {option.label}
                                                                                                                                        </Option>
                                                                                                                                    ))}
                                                                                                                                </Select>
                                                                                                                            </Col>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                        : (data.Specialite === 'ORL' && (data.Organe === 'Cavité buccale' || data.Organe === 'Pharynx' || data.Organe === 'Larynx' || data.Organe === 'Cavité nasale' || data.Organe === 'Lévre' || data.Organe === 'Sinus' || data.Organe === 'glandes salivaire')) ? (
                                                                                                                            <>
                                                                                                                                <Col span={24}>
                                                                                                                                    <p className='inputPatient2'>Stade clinique : TNM Classification UICC</p>
                                                                                                                                    <Select
                                                                                                                                        made='tags'
                                                                                                                                        name="stade clinique"
                                                                                                                                        placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                                        value={tumeur}
                                                                                                                                        style={{ width: "100%" }}
                                                                                                                                        onChange={(value) => setTumeur(value)}
                                                                                                                                    >
                                                                                                                                        <OptGroup label="T">
                                                                                                                                            {optionTumeur.map((option) => (
                                                                                                                                                <Option key={option.value} value={option.value}>
                                                                                                                                                    {option.label}
                                                                                                                                                </Option>
                                                                                                                                            ))}
                                                                                                                                        </OptGroup>

                                                                                                                                        <OptGroup label="N">
                                                                                                                                            {optionAdenophatie.map((option) => (
                                                                                                                                                <Option key={option.value} value={option.value}>
                                                                                                                                                    {option.label}
                                                                                                                                                </Option>
                                                                                                                                            ))}
                                                                                                                                        </OptGroup>

                                                                                                                                        <OptGroup label="M">
                                                                                                                                            {optionMétastase.map((option) => (
                                                                                                                                                <Option key={option.value} value={option.value}>
                                                                                                                                                    {option.label}
                                                                                                                                                </Option>
                                                                                                                                            ))}
                                                                                                                                        </OptGroup>
                                                                                                                                    </Select>
                                                                                                                                </Col>
                                                                                                                            </>

                                                                                                                        )
                                                                                                                            : (data.Specialite === 'ORL' && (data.Organe === 'Thyroide' || data.Organe === 'Cavum' || data.Organe === 'Nasopharynx')) ? (
                                                                                                                                <>
                                                                                                                                    <Col span={24}>
                                                                                                                                        <p className='inputPatient2'>Stade clinique : TNM </p>
                                                                                                                                        <Select
                                                                                                                                            made='tags'
                                                                                                                                            name="stade clinique"
                                                                                                                                            placeholder="Sélectionnez le Tumeur primitive"
                                                                                                                                            value={tumeur}
                                                                                                                                            style={{ width: "100%" }}
                                                                                                                                            onChange={(value) => setTumeur(value)}
                                                                                                                                        >
                                                                                                                                            <OptGroup label="T">
                                                                                                                                                {optionTumeur.map((option) => (
                                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                                        {option.label}
                                                                                                                                                    </Option>
                                                                                                                                                ))}
                                                                                                                                            </OptGroup>

                                                                                                                                            <OptGroup label="N">
                                                                                                                                                {optionAdenophatie.map((option) => (
                                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                                        {option.label}
                                                                                                                                                    </Option>
                                                                                                                                                ))}
                                                                                                                                            </OptGroup>

                                                                                                                                            <OptGroup label="M">
                                                                                                                                                {optionMétastase.map((option) => (
                                                                                                                                                    <Option key={option.value} value={option.value}>
                                                                                                                                                        {option.label}
                                                                                                                                                    </Option>
                                                                                                                                                ))}
                                                                                                                                            </OptGroup>
                                                                                                                                        </Select>
                                                                                                                                    </Col>
                                                                                                                                </>

                                                                                                                            ) : null}

                        {data.Specialite === 'Pédiatrie' && data.Organe === 'Os' && (
                            <>
                                <Col span={12}>
                                    <p className='inputPatient2'>Osteosarcome : Score de Huvos permet d'adapter le traitement en post-chirurgie</p>
                                    <Select
                                        name="OsteosarcomeSH"
                                        placeholder="OsteosarcomeSH"
                                        value={OsteosarcomeSH}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setOsteosarcomeSH(value)}
                                    >
                                        {optionOsteosarcomeSH.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col span={12}>
                                    <p className='inputPatient2'>Osteosarcome : Marges de résection</p>
                                    <Select
                                        name="OsteosarcomeSH"
                                        placeholder="OsteosarcomeSH"
                                        value={OsteosarcomeMR}
                                        style={{ width: "100%" }}
                                        onChange={(value) => setOsteosarcomeMR(value)}
                                    >
                                        {optionOsteosarcomeMR.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </>
                        )}
                        {/* <Col span={8}>
                    <p>les adénophaties régionnales</p>
                    <Select placeholder="Sélectionnez les adénophaties régionnales"
                        name="stade clinique"
                        value={adenopathie}
                        style={{ width: "100%" }}
                        onChange={(value) => setAdenopathie(value)}>
                        {optionAdenophatie.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>                 </Col>
                <Col span={8}>
                    <p>les Métastases à distance</p>
                    <Select placeholder="Sélectionnez les Métastases à distance"
                        name="stade clinique"
                        style={{ width: "100%" }}

                        value={Métastase}
                        onChange={(value) => setMétastase(value)

                        }
                    >
                        {optionMétastase.map((option) => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Col> */}
                    </Row>

                </>
            )
            }
            <br />
           
          
                    {/* <BtnSubmit 
                        type_="Submit"
                         float_="right"
                         top_="-18px"
                        buttonText="Enregistrer"
                        position_="relative"
                        border_='none'
                        padding_='10px 20px'
                        borderRadius_='0.5rem'
                        color='#fff'
                        bgNormalColor='linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253))'
                    /> */}
        </form >
    );
};

export default Diagniostigue;