import { Card, Col, Row, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { fetchSpecialite } from "../../redux/specialiteSlice";
import { useEffect, useState } from "react";
import { fetchStatistics } from "../../redux/StatisticSlice";
import { Column } from '@ant-design/charts';
import Title from "antd/es/typography/Title";
import { fetchStatisticSpecialitePatient } from "../../redux/StatisticSpecialitePatientSlice";
import "./accueil.css";
import { useCollapsed } from "../dashBoard/CollapsedContext";
import { Empty } from 'antd';



const Accueil = () => {
  const dispatch = useDispatch();
  const [Statistc, setStatistc] = useState([]);
  const collapsed = useCollapsed();
  const { data: getspecialites = [], loading } = useSelector((state) => state.specialite || []);
  const [selectedSpecialite, setSelectedSpecialite] = useState(null);
  const [specialites, setSpecialites] = useState([]);
  const [TotalPatientsSpecialite, setTotalPatientsSpecialite] = useState(0);
  const [LaSpecialiteSelectionne, setLaSpecialiteSelectionne] = useState("");
  const [message, setMessage] = useState('');

useEffect(() => {
  dispatch(fetchSpecialite());
}, [dispatch]);

useEffect(() => {
  dispatch(fetchStatisticSpecialitePatient());
}, [dispatch]);
const { data: dataStatisticSpecialitePatient } = useSelector((state) => state.StatisticSpecialitePatient || []);
// console.log(dataStatisticSpecialitePatient);

  useEffect(() => {
    if (Array.isArray(getspecialites)) {
      setSpecialites(getspecialites);
    } else {
      setSpecialites([]); 
    }
  }, [getspecialites]);

  // console.log(specialites);
  useEffect(() => {
    if (specialites.length > 0) {
      const firstSpecialite = specialites[0];
      setSelectedSpecialite(firstSpecialite);
      setLaSpecialiteSelectionne(firstSpecialite.NomSpecialite);
      dispatch(fetchStatistics({ Specialite: firstSpecialite.NomSpecialite }))
        .then((response) => {
          const data = response.payload || [];
          if (data.status === 0) {
            setMessage(data?.message);
          }else{
            setStatistc(data);
            setTotalPatientsSpecialite(data[0].NombrePatients);
            setLaSpecialiteSelectionne(data[0].Specialite);
          }
        })
        .catch((error) => console.error("Erreur lors de la récupération des statistiques : ", error));
      }
    
  }, [specialites]);
  // console.log(Statistc);
  // console.log(LaSpecialiteSelectionne);
  const handleSpecialiteChange = (value) => {
    setTotalPatientsSpecialite(0);
    setLaSpecialiteSelectionne(value);
  
    const selected = specialites.find((specialite) => specialite.NomSpecialite === value);
    setSelectedSpecialite(selected);
    if (selected) {
      dispatch(fetchStatistics({ Specialite: selected.NomSpecialite }))
        .then((response) => {
          const data = response.payload || [];
          if (data.status === 0) {
            setMessage(data?.message);
          } else {
            setStatistc(data);
            setTotalPatientsSpecialite(data[0]?.NombrePatients || 0);
            setLaSpecialiteSelectionne(data[0]?.Specialite || "");
          }
        })
        .catch((error) => console.error("Erreur lors de la récupération des statistiques : ", error));
    }
  };
  // console.log(TotalPatientsSpecialite);
  const SpecialiteCards = ({ specialite }) => {
    const organes = Statistc ? Statistc.find((stat) => stat.Specialite === specialite.NomSpecialite)?.Organes : [];
    return (
     <>
      <div style={{marginTop:"11vh", marginLeft: collapsed ? 10 : 15 }}>
          <Row gutter={[12, 12]}>
            { organes === undefined ? (
              <>
                <Col span={24}>
              <div style={{ backgroundColor: '#FFFFFF', borderRadius:"0.5rem",width:"1150px"}} >
              <Row> 
                  <Col span={24} >
                    <Empty size="large" style={{fontSize:"100%"}} description={false} />
                    <Title level={5}>{message} </Title>
                  </Col>
                </Row>
              </div>
              </Col>
              </>
            ): organes !== '' && organes.length > 0 ? (
              organes.map((organe, idx) => (
                <Col span={24} key={idx} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    size="small"
                    title={organe.Organe}
                    bordered={true}
                    headStyle={{ background: "#21A3FE", color: "#FFFFFF" }}
                    style={{ width: "100%", borderWidth: "3px", borderStyle: "solid" }}
                  >
                    <p>
                      <b>Nombre total de patients :</b> {organe.NombrePatients}
                    </p>
                    <Link to={`/dashboard/patient?specialite=${specialite.NomSpecialite}&organe=${organe.Organe}`}>
                      Voir plus
                    </Link>
                  </Card>
                </Col>
              ))
            ) : (
              <>
                <Col span={24}>
              <div style={{width:"1150px", backgroundColor: '#FFFFFF', borderRadius:"0.5rem"}} >
              <Row> 
                  <Col span={24}  xs={24} sm={12} md={8} lg={6} >
                    <Empty size="large" style={{fontSize:"100%",width:"100%"}} description={false} />
                  </Col>
                </Row>
                <Row>
                <Col span={24} >
                    <Title level={5}>{message} </Title>
                  </Col>
                </Row>
              </div>
              </Col>
              </>
            ) }
          </Row>
          <br/>
      </div>
      </>
    );
  };

  const chartData = dataStatisticSpecialitePatient?.map((stat) => ({
    Specialite: stat.Specialite,
    NombrePatients: stat.NombrePatients,
  }));

  const config = {
    data: chartData,
    xField: "Specialite",
    yField: "NombrePatients",
    label: {
      position: "top",
      style: { fill: "#FFFFFF", opacity: 1.6 },
    },
    style: { maxWidth: 60},
  };

  
  return (
    <>
        <Row style={{}}>
        <Title style={{marginLeft:"6px", float:"left", color:"#21A3FE",  marginTop:"12vh"}} level={4}>Nombre de patient par organe</Title>
          <Col span={24}>
          <Row>
            <Col span={24}>
              <div style={{float:"left", marginLeft:"2px",marginTop: "-3vh" , padding: "7px"}}>
              <Title  style={{marginBottom:"5px" }} level={5}>{LaSpecialiteSelectionne} : {TotalPatientsSpecialite } patients</Title>
              </div>
            </Col>
          </Row>
          <Row style={{marginTop: "-8vh"}}>
              <Col>
                <Select
                style={{ float:"right", width: "23.5%", marginRight: "5px", height: "6vh", border: "1.5px solid #1677FF", borderRadius: "0.5rem", }}
                  placeholder="Choisir une spécialité"
                  onChange={handleSpecialiteChange}
                  value={selectedSpecialite?.NomSpecialite || null}
                  loading={loading}
                >
                  {specialites.map((specialite, index) => (
                    <Select.Option key={index} value={specialite.NomSpecialite}>
                      {specialite.NomSpecialite}
                    </Select.Option>
                  ))}
                </Select>
            {loading ? (
              <Spin size="small" />
            ) : (
              selectedSpecialite && <SpecialiteCards specialite={selectedSpecialite} />
            )}
          </Col>
          </Row>
          </Col>
          <Col span={24}>
            <Row >
              <Col span={18}>
                {dataStatisticSpecialitePatient?.length > 0 && <Column {...config} />}
              </Col>
              <Col span={24}>
              <Title  style={{marginLeft:"8px",float:"left", color:"#21A3FE", marginTop:"-1vh"}} level={4}>Nombre de patient par spécialité</Title>
              </Col>
            </Row>
          </Col>
        </Row>
    </>
  );
};

export default Accueil;

