import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les statistiques via une requête POST
export const fetchStatistics = createAsyncThunk(
  "Statistics/getStatistics",
  async (body, { rejectWithValue }) => {
    try {
      const response = await api.postStatistics(body); // Appelle la méthode POST de votre API
      console.log(response.data.data);
      return response.data.data; // Retourne les données de la réponse
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message); // Gère les erreurs
    }
  }
);

// Création du slice pour les statistiques
export const statisticSlice = createSlice({
  name: "Statistics",
  initialState: {
    data: [], // Données statistiques
    isSuccess: false, // Indique si la requête a réussi
    loading: false, // Indique si une requête est en cours
    error: null, // Stocke les éventuelles erreurs
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatistics.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
        state.error = null;
      })
      .addCase(fetchStatistics.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = true;
        state.data = payload;
      })
      .addCase(fetchStatistics.rejected, (state, { payload }) => {
        state.loading = false;
        state.isSuccess = false;
        state.error = payload;
      });
  },
});

// Exporter le réducteur pour l'utiliser dans le store
export default statisticSlice.reducer;
