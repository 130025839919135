import React, { useState } from "react";
import { BtnSubmit, InputText, ModalComponent } from "../../../components";
import { Col, Row, Switch, Input, Alert, notification } from "antd";
import imgChat from "../../../assets/image/chat (2).png";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import "../login/login.css";
import Registre from "../registre/Registre.js";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/UserSlice.js";
import { createForgotPassword } from "../../../redux/PasswordOublieSlice.js";

const Login = ({userData}) => {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Email, setEmail] = useState();
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const common = t("common", { returnObjects: true });

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpened = () => {
    setOpened(true);
  };
  // Utilisation de useSelector pour extraire les états loading et error du slice user dans le store Redux
const { loading, error } = useSelector((state) => state.user);

// Utilisation de useNavigate pour permettre la navigation après une action réussie
const navigate = useNavigate();

// Utilisation de useDispatch pour dispatcher des actions Redux
const dispatch = useDispatch();

// Fonction handleSubmit pour gérer la soumission du formulaire de connexion
const handleSubmit = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire

    // Création d'un objet userData contenant le nom d'utilisateur et le mot de passe
    let userData = {
        Username: username,
        Password: password
    };
    // Dispatch de l'action loginUser avec les données utilisateur
    dispatch(loginUser(userData))
        .unwrap() // Déballer la réponse de l'action asynchrone pour accéder directement aux valeurs retournées ou aux erreurs
        .then(() => {
            // Réinitialise les champs du formulaire après une connexion réussie
            setUsername("");
            setPassword("");
            
            // Navigation vers la page du tableau de bord après une connexion réussie
            navigate("/Dashboard");
        })
        .catch((err) => {
            // Gestion des erreurs en cas d'échec de la connexion
            // console.error("Login Error:", err); // Affiche l'erreur dans la console (commentée pour l'instant)
        });
};


  const onChange = (e) => {
    if (e) {
      i18next.changeLanguage("fr");
    } else {
      i18next.changeLanguage("en");
    }
  };
  const handleForgotPassword = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page lors de la soumission du formulaire
  
    // Vérifie que l'email est fourni
    if (!Email) {
        notification.error({
            message: "Erreur",
            description: "Veuillez entrer une adresse email valide.",
            duration: 6,
        });
        return;
    }

    // Création d'un objet contenant l'email pour la réinitialisation du mot de passe
    let forgotPasswordData = {
        Email: Email,
    };

    try {
        // Appel asynchrone pour envoyer l'email
        const result = await dispatch(createForgotPassword(forgotPasswordData)).unwrap();
        
        // Si la réponse est reçue sans erreur, affiche une notification de succès
        notification.success({
            message: "Email de réinitialisation envoyé",
            description: "Vérifiez votre boîte mail pour réinitialiser votre mot de passe.",
            duration: 6,
        });

        // Réinitialiser le champ email et fermer le modal
        setEmail("");
        setOpen(false);

    } catch (err) {
        // Gérer les erreurs
        notification.error({
            message: "Erreur lors de l'envoi",
            description: err.message || "Une erreur est survenue lors de l'envoi de l'email.",
            duration: 6,
        });
    }
};

  
  return (
    <>
      <Row>
        <Col span={12}>
          <div className="dLangue">
            <p>English</p>
            <Switch defaultChecked onChange={onChange} />
            <p>Français</p>
          </div>
          <div className="divForm">
            <form className="form" onSubmit={handleSubmit}>
              <Row>
                <Col span={15}>
                  <div className="titreEtP">
                    <h4 className="h4Titre">{common.signin}</h4>
                    <p className="pInput">{common.textWrite}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={15}>
                  <InputText required
                    className="inputText"
                    type="text"
                    placeholder_={common.Username}
                    value={username}
                    name="Username"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Input.Password required
                    className="inputText"
                    placeholder={common.Password}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    value={password}
                    name="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <div className="mpo" onClick={() => setOpen(true)}>
                    {common.forgetPassword}
                  </div>
                </Col>
              </Row>
              <ModalComponent
              _maskClosable={false}
                _width="28vw"
                _open={open}
                onCancel={() => setOpen(false)}
                _title="Veuillez saisir votre email"
                form={
                  <form onSubmit={handleForgotPassword}>
                    <div style={{ padding: "5px" }}>
                      <InputText 
                        required
                        type="email"
                        width_="100%"
                        height_="7vh"
                        placeholder_="exemple@gmail.com"
                        name="Email"
                        value={Email} 
                        onChange={(e) => setEmail(e.target.value)} // Capturez la valeur de l'email
                      />
                      <div style={{ textAlign: "right" }}>
                        <BtnSubmit 
                          type_="submit"
                          className="btnSubmitMpo"
                          disable="none"
                          bgNormalColor="#78953E"
                          buttonText="Valider"
                          boldColor={400}
                          // onClick={handleForgotPassword}
                        />
                      </div>
                    </div>
                  </form>
                }
              />
              <Row>
                <Col span={15}>
                  <BtnSubmit
                    className="btnSubmit"
                    type_="submit"
                    margin_="30px auto 0"
                    bgNormalColor="linear-gradient( 310deg, rgb(33, 82, 255), rgb(33, 212, 253))"
                    bgHoverColor="#34A853"
                    buttonText={loading ? 'Loading...' : common.login}
                    color="#fff"
                    boldColor={700}
                    border_="none"
                    borderRadius_="0.5rem"
                  /><br/><br/>
                    {error && (
                    <Alert 
                        message={typeof error === 'object' ? error.message : error}
                        type="error"
                        showIcon
                    />
                )}
                  <p className="spanP">{common.acountText} ? <span className="spanDeP" onClick={handleOpened} >{common.register}</span> <Registre _open={opened} onCancel={() => setOpened(false)} /></p>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
        <Col span={7}>
          <div className="divImageLogo">
            <img src={imgChat} className="virusImg" alt="image" />
            <div>
              <h4 className="h4PAtt"><p className="h4PAttP"> {common.ApplicationTitle}</p></h4>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Login;
