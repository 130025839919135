import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les spécialités depuis l'API
export const fetchSpecialite = createAsyncThunk(
  "Specialite/getSpecialite",
  async () => {
    try {
      const response = await api.getAllSpecialite();
      // return response.data;
      
      if (response.data.status === 1) {
        // console.log(response.data.data);
        return response.data.data;
      } else {
        // console.log(response.data.message);
        return response?.data.message || [];
      }
      
    } catch (error) {
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les spécialités
export const specialiteSlice = createSlice({
    name: "Specialite",
    initialState: {
      data: [], 
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchSpecialite.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchSpecialite.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchSpecialite.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default specialiteSlice.reducer;
