import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Rcps depuis l'API
export const fetchRcps = createAsyncThunk(
  "Rcps/getRcps",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getAllRcps();
      // console.log(response.data.data); 
      return response.data.data; // Retourne uniquement le tableau des RCP
    } catch (error) {
      const errorMessage = error?.response?.data?.message || "Erreur inconnue";
      return rejectWithValue(errorMessage); // Gère les erreurs proprement
    }
  }
);


// Création du slice pour les rcp
export const rcpsSlice = createSlice({
    name: "Rcps",
    initialState: {
      data: [], 
      isSuccess: false,
      loading: false,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchRcps.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
        })
        .addCase(fetchRcps.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchRcps.rejected, (state) => {
          state.loading = false;
          state.isSuccess = false;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default rcpsSlice.reducer;
