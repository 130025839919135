import React, { useEffect, useState } from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, ModalComponent, Tableau } from '../../components';
import { Col, Modal, notification, Row, Space, Tooltip, } from 'antd';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import "./../patients/patient.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecialite } from '../../redux/specialiteSlice';
import { createSpecialite } from '../../redux/CreateSpecialiteSlice';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { updateSpecialite } from '../../redux/UpdateSpecialiteSlice';
import { updateArchivSpecialite } from '../../redux/ArchivSpecialiteSlice';

        

        const Specialite = () => {

          const collapsed = useCollapsed();

          const [NomSpecialite, setNomSpecialite] = useState("");
          const [open, setOpen] = useState(false);
          const [openUpdateForm, setOpenUpdateForm] = useState("");
          const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
          const [openModalConfirmArchiv, setOpenModalConfirmArchiv] = useState(false);
          const [updatingDataId, setUpdatingDataId] = useState();
          const [specialiteData, setSpecialiteData] = useState();
          const [UpdateSpecialitedata, setUpdateSpecialitedata] = useState(null);
          const [ArchivSpecialiteId, setArchivSpecialiteId] = useState(null);
        

          // 
          const handleOpenModalConfirmArchiv = (record) => {
            setArchivSpecialiteId(record.id);
            setOpenModalConfirmArchiv(true);
          }

          const handleOpenModalConfirmation = (record) => {
            setUpdateSpecialitedata(record);
            setOpenModalConfirmation(true);
          }
          const handleCancel = () => {
            setOpenModalConfirmation(false);
            setUpdateSpecialitedata(null);
            }
            const handleCancelArchiv = () => {
              setOpenModalConfirmArchiv(false);
              setArchivSpecialiteId(null);
              }
            
                const handleOpen  = () => {
                  setOpen(true);
                  };
                  const onCancel  = () => {
                    setOpen(false);
                     // Réinitialise les champs du formulaire après enregistrement
                  setNomSpecialite("");
                };
                const onCloseUpdateForm = () => {
                  setOpenUpdateForm(false);
                  setNomSpecialite("");
              };

          const dispatch = useDispatch();
          const { data: dataSpecialite, loading, isSuccess } = useSelector((state) => state.specialite || []);

          useEffect(() => {
            dispatch(fetchSpecialite());
          }, [dispatch]);


                // Fonction handleSubmit pour gérer la soumission du formulaire 
              const handleSubmit = async (e) => {
                e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire

                // Création d'un objet specialiteData contenant le nom d specialite
                let specialiteData = {
                    NomSpecialite: NomSpecialite,
                };
                try {
                  const result = await dispatch(createSpecialite(specialiteData)).unwrap(); // Unwrap pour accéder directement à la réponse
                  notification.success({
                    message: "Création de spécialité réussie",
                    description: result.message,
                    duration: 6,
                  });
                  await dispatch(fetchSpecialite());
                  onCancel(); // Appel correct de la fonction pour fermer le modal
                }
                catch (err) {
                  notification.error({
                    message: "Erreur d'enregistrement",
                    description: err.message,
                    duration: 6,
                  });
                }
              };

              const colomnsSpecialite = [
                {
                  title: 'Nom spécialité',
                  dataIndex: 'NomSpecialite',
                  key: 'NomSpecialite',
                  width:'80%',
                  // className: 'colonText',
                },
                {
                  title: 'Options',
                  key: 'options',
                  render: (record) => (
                    <Space size="large">
                    <Tooltip title="Editer">
                    <EditFilled 
                      style={{ color: "#4096FF", fontSize: "15px" }} 
                      onClick={() => handleOpenModalConfirmation(record)}
                       />
                        </Tooltip>
    
                        <Tooltip title="Archiver">
                          <DeleteFilled style={{color:"#E00B0B", fontSize:"15px"}} 
                          onClick={() => handleOpenModalConfirmArchiv(record)}
                          />
                        </Tooltip>
                    </Space>
                  ),
                  className: 'colonText',
                },
              ];
              const [searchValue, setSearchValue] = useState('');
            const filtrData = dataSpecialite?.filter(item =>
              item.NomSpecialite.toLowerCase().includes(searchValue.toLowerCase()) 
              );


              const [inputData, setInputData] = useState({
                NomSpecialite: '',
                
              });
            
              const handleOpenUpdateForm = (record) => {
                setOpenModalConfirmation(false);
                setOpenUpdateForm(true);
                setInputData(
                {
                  NomSpecialite: record.NomSpecialite || '',
                  
                });
            
              setUpdatingDataId(record.id);
              };
              const handleChange = (value,field) => {
                setInputData({
                  ...inputData, 
                  [field]: value, 
                });
              };
            
              const handleUpdateForm = async (e) => {
                e.preventDefault();
                try {
                  const result = await dispatch(updateSpecialite({ id:updatingDataId,body:inputData })).unwrap();
                  notification.success({
                    message: "Patient mis à jour avec succès",
                    description: result.message,
                    duration: 6,
                  });
                  setSpecialiteData(inputData);
                  setOpenUpdateForm(false);
                  await dispatch(fetchSpecialite());  
                } catch (err) {
                  notification.error({
                    message: "Erreur de mise à jour",
                    description: err.message,
                    duration: 6,
                  });
                }
              };
            
              
              const handleOpenArchivSpecialite = async (id) => {
                
                try {
                  const result = await dispatch(updateArchivSpecialite(id)).unwrap();
                  
                  notification.success({
                    message: "Spécialité archivé avec succès",
                    description: result.message,
                    duration: 6,
                  });
                  setOpenModalConfirmArchiv(false);
                  await dispatch(fetchSpecialite());

                } catch (errorMessage) {
                  notification.error({
                    message: "Erreur de mise à jour",
                    description: errorMessage.message,
                    duration: 6,
                  });
                }
              };

    return (
        <div>
            <div className="buttons-container" style={{ marginLeft: collapsed ? 192 : 90 }} >
              <BtnSubmit type_="button"
                  onClick={handleOpen}
                  className="btnAjouter"
                  width_="100px"
                  margin_="0px 65px -10px 640px"
                  buttonText="Ajouter"
                  />
                    <ModalComponent 
                    _maskClosable={false}
                    onCancel={onCancel}
                    _open={open} 
                    _width="420px" 
                    _title= "Ajouter une spécialité"
                    form=
                    {
                      <form onSubmit={handleSubmit}>
                      <br/>
                        <Row>
                          <Col span={24}>
                              <InputText 
                                 size_="large"
                                  required 
                                  value={NomSpecialite} 
                                  onChange={(e) => setNomSpecialite(e.target.value)}
                                  name="NomSpecialite" 
                                  type="text"
                                  placeholder_=" Veuillez saisir le nom de votre spécialité"
                              />
                          </Col>
                        </Row><br/>
                                    <BtnSubmit
                                          type_="submit"
                                          width_="90px"
                                          bgNormalColor="#78953E"
                                          buttonText="Valider"
                                          color="#fff"
                                          boldColor={700}
                                          height_="37px"
                                          borderRadius_="0.5rem"
                                          border_="none"
                                          bgHoverColor="#4589C7"
                                          float_="right"
                                /><br/><br/>
                        </form>
                                }  
                      />
          <BtnSearch 
          margin_="5px 0px -11px -40px" height_="39px" bgNormalColor="#ffffff" 
          border_="1.5px solid #1677FF"
          onChange_={(e) => setSearchValue(e.target.value)} 
          allowClear/>
          </div>
            <Tableau columns={colomnsSpecialite} 
                data={filtrData ? [...filtrData].reverse() : []} 
                title={() => <div style={{ textAlign: 'left', fontWeight:"bold", marginTop:"-6px" }}>Spécialité</div>}  
                loading={loading}/> 


                <ModalComponent 
                    _maskClosable={false}
                    onCancel={onCloseUpdateForm}
                    _open={openUpdateForm} 
                    _width="420px" 
                    _title="Ajouter une spécialité"
                    form=
                    {<form onSubmit={handleUpdateForm}>    
                          <Row>
                            <Col span={24}>
                                <p>Nom spécialité</p>
                                <InputText 
                                 required 
                                  size_="large"
                                  name="NomSpecialite" 
                                  type="text"
                                  placeholder_=" Veuillez saisir le nom de votre spécialité"
                                  onChange={(e) => handleChange(e.target.value, 'NomSpecialite')}  
                                  value={inputData.NomSpecialite || ""} 
                                />
                            </Col>
                          </Row><br/>
                                  <BtnSubmit  
                                      type_="submit"
                                      width_="6.5vw"
                                      float_="right"
                                      bgNormalColor="#78953E"
                                      buttonText="Valider"
                                      color="#fff"
                                      boldColor={700}
                                      height_="37px"
                                      borderRadius_="0.5rem"
                                      border_="none"
                                      bgHoverColor="#4589C7"
                          /><br/><br/>
                      </form>
                                }  
                                />
            <Modal
              maskClosable={false}
              closable={false}
              title="Modifier une spécialité" 
              open={openModalConfirmation} 
              onOk={() => handleOpenUpdateForm(UpdateSpecialitedata)}       
              onCancel={handleCancel}
              okText="Oui" 
              cancelText="Non">
              <p>Voulez-vous modifier cette spécialité?</p>
            </Modal>


            <Modal
              maskClosable={false}
              closable={false}
              title="Archiver une spécialité" 
              open={openModalConfirmArchiv} 
              onOk={() => handleOpenArchivSpecialite(ArchivSpecialiteId)}       
              onCancel={handleCancelArchiv}
              okText="Oui" 
              cancelText="Non">
              <p>Voulez-vous archiver cette spécialité?</p>
            </Modal>
        </div>
    );
};

export default Specialite; 