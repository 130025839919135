import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../service/api";

// Fonction pour récupérer les Centre depuis l'API
export const fetchStatisticSpecialitePatient = createAsyncThunk(
  "StatisticSpecialitePatient/getStatisticSpecialitePatient",
  async () => {
    try {
      const response = await api.getAllStatisticSpecialitePatient();
      // console.log(response.data);
      // return response.data; 
      if (response.data.status === 1) {
        console.log(response.data.data);
        return response.data.data;
      } else {
        console.log(response.data.message);
        return response?.data.message || [];
      }

    } catch (error) {
      console.error(error);
      throw error; // Lancer l'erreur pour être capturée dans le reducer
    }
  }
);

// Création du slice pour les StatisticSpecialitePatient
export const statisticSpecialitePatientSlice = createSlice({
    name: "StatisticSpecialitePatient",
    initialState: {
      data: [], 
      isSuccess: false,
      loading: false,
      error: null,
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchStatisticSpecialitePatient.pending, (state) => {
          state.loading = true;
          state.isSuccess = false;
          state.error = null;
          
        })
        .addCase(fetchStatisticSpecialitePatient.fulfilled, (state, { payload }) => {
          state.loading = false;
          state.isSuccess = true;
          state.data = payload;
        })
        .addCase(fetchStatisticSpecialitePatient.rejected, (state, action) => {
          state.loading = false;
          state.isSuccess = false;
          state.error = action.error.message;
        });
    },
  });
  
// Exporter le réducteur pour l'utiliser dans le store
export default statisticSpecialitePatientSlice.reducer;
