import React, {useEffect, useState} from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, ModalComponent, SelectInput, Tableau } from '../../components';
import { Button, Col, Modal, notification, Row, Space, } from 'antd';
import "../../components/tableaux/tableau.css";
import { SelectCentresOptions, SelectRolesOptions, SelectSpecialiteOptions, SelectTitreOptions } from '../../service';
import "./../patients/patient.css";
import { fetchUtilisateur } from '../../redux/UtilisateurSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { updateActiverUser } from '../../redux/ActiverUserSlice';
import { updateDesactiverUser } from '../../redux/DesactiverUserSlice';
import { fetchSpecialite } from '../../redux/specialiteSlice';
import { fetchCentre } from '../../redux/centreSlice';
import { register } from '../../redux/RegistreSlice';
import { useFormik } from 'formik';

const Utilisateurs = () => {
 
  const [openModal, setopenModal] = useState();

  const handleChangeTitre = (value) => {
    setFieldValue("Titre", value);
  };
  const handleOpenModal  = () => {
    setopenModal(true);
    };
    const onCancel  = () => {
      setopenModal(false);
       // Réinitialise les champs du formulaire après enregistrement
   
  };
 
   const [activatingUserId, setActivatingUserId] = useState(null);
   const [refreshTable, setRefreshTable] = useState(false);
   

   const colomnsUtilisateur = [
    {
      title: 'Titre',
      dataIndex: 'Titre',
      key: 'Titre',
      className: 'colonText',
      width:'5%',
    },
    {
      title: 'Prénom',
      dataIndex: 'Prenom',
      key: 'Prenom',
      className: 'colonText',
    },
    {
      title: 'Nom',
      dataIndex: 'Nom',
      key: 'Nom',
      className: 'colonText',
    },
    {
      title: 'Username',
      key: 'Username',
      dataIndex: 'Username',
      className: 'colonText',
    },
    {
        title: 'Email',
        key: 'Email',
        dataIndex: 'Email',
        className: 'colonText',
      },
      {
        title: 'Centre',
        key: 'Centres',
        dataIndex: 'Centres',
        className: 'colonText',
        render: (Centres) => (
          <div>
            {Centres.map((centre, index) => (
              <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                {centre.NomCentre}
              </p>
            ))}
          </div>
        ),
      },
      
      {
        title: 'Roles',
        key: 'Roles',
        dataIndex:'Roles',
        className: 'colonText',
        render: (Roles) => (
          <div>
            {Roles.map((role, index) => (
              <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                {role.name}
              </p>
            ))}
          </div>
        ),
      },
      {
        title: 'Options',
        key: 'options',
        render: (record) => (
          <Space size="large">
            <BtnSubmit
              buttonText="Activer"
              onClick={() => handleOpenModalActivate(record)} // Passer l'ID de l'utilisateur
              border_="none"
              color="#ffff"
              type_="button"
              width_="5.5vw"
              height_="4vh"
              borderRadius_="0.2rem"
              bgNormalColor="linear-gradient( 310deg, rgb(33, 202, 253), rgb(33, 82, 255))"
              disabled={record.Active} // Désactiver le bouton si "Active" est true
              loading={activatingUserId === record.id} // Désactiver le bouton si l'utilisateur est en cours d'activation
            />
            {/*  */}
            <Button
            onClick={() => handleOpenModalDesactivate(record)}
            // onClick={() => handleDesactivateUser(record.id)} // Passer l'ID de l'utilisateur
            size="small"
            style={{
              fontSize: "12px",
              color: record.Active ? "#1677FF" : "#A9A9A9", // Couleur de texte grisée si désactivé
              border: record.Active ? "1.5px solid #1677FF" : "none", // Pas de bordure si désactivé
              width: "5.5vw",
              height: "4vh",
              borderRadius: "0.2rem",
              backgroundColor: record.Active ? "white" : "#E0E0E0", // Couleur grisée pour le fond si désactivé
            }}
            variant="filled"
            disabled={!record.Active} // Désactiver le bouton si "Active" est false
          >
            <div>Désactiver</div>
          </Button>
          </Space>
        ),
        className: 'colonText',
      },
  ];
   const dispatch = useDispatch();
  const { data: dataUtilisateur, loading, isSuccess } = useSelector((state) => state.utilisateur || []);
  useEffect(() => {
    dispatch(fetchUtilisateur());
  }, [dispatch,refreshTable]);

// console.log(dataUtilisateur);

  const handleActivateUser = async (userId) => {
    try {
      // Dispatch de l'action pour activer l'utilisateur et récupérer la réponse
      const response = await dispatch(updateActiverUser({ userId })).unwrap();
  
      // Afficher une notification de succès avec la réponse
      notification.success({
        message: 'Utilisateur activé',
        description: response.message, // Utilise la réponse du slice
      });
      await dispatch(fetchUtilisateur());
      handleCancel();
    } catch (err) {
      // Afficher une notification d'erreur
      notification.error({
        message: "Erreur d'activation",
        description: err.message,
        duration: 5,
      });
    }
  };
  
        const handleDesactivateUser = async (userId) => {
          try {
            // Dispatch de l'action pour desactiver l'utilisateur
            const response = await dispatch(updateDesactiverUser({ userId })).unwrap();
            // Afficher une notification de succès
            notification.success({
              message: 'Utilisateur Désactivé',
              description: response.message,
            });
            await dispatch(fetchUtilisateur());
            handleCancel();
          } catch (err) {
            // Afficher une notification d'erreur
            notification.error({
              message: "Erreur de Désactivation",
              description: err.message,
              duration: 5,
            });
          }
        };
  
  const collapsed = useCollapsed();
  const [openModalActivate, setOpenModalActivate] = useState(false);
  const [openModalDesactivate, setOpenModalDesactivate] = useState(false);
  
  const handleOpenModalActivate = (record) => {
    setActivatingUserId(record.id);
    setOpenModalActivate(true);
  }
  const handleOpenModalDesactivate = (record) => {
    setActivatingUserId(record.id);
    setOpenModalDesactivate(true);
  }
  
  const handleCancel = () => {
    setOpenModalActivate(false);
    setOpenModalDesactivate(false);
    setActivatingUserId(null);
    }

   

  const specialites = useSelector((state) => state.specialite || []);
   // Récupérer les spécialités depuis l'API
   useEffect(() => {
    dispatch(fetchSpecialite());
  }, [dispatch]);
  const specialitesOptions = specialites?.data?.map((specialite) => ({
    label: specialite.NomSpecialite,
    value: specialite.id,
  }));
  

  const centres = useSelector((state) => state.centre.data || []);
  // Récupérer les centres depuis l'API
  useEffect(() => {
    dispatch(fetchCentre());
  }, [dispatch]);
    const centresOptions = centres?.map((centre) => ({
      label: centre.NomCentre,
      value: centre.NomCentre,
    }));
   
    const handleChangeRole = (value) => {
      setFieldValue("roles", value);
    };
    const onSubmit = async (values, actions) => {
      const result = await dispatch(register({
        values,
        roles: values.roles,
        Titre: values.Titre,
        centres: values.centres,
        SpecialiteId: values.SpecialiteId
      }));
      if (values.Password !== values.Password_confirmation) {
        notification.error({
          message: "Erreur",
          description: "Les mots de passe ne correspondent pas.",
          duration: 6,
        });
        return;
      }
  
      if (result.meta.requestStatus === 'fulfilled') {
        notification.success({
          message: "Utilisateur crée avec success",
          description: result.payload.message,
          duration: 6
        });
        await dispatch(fetchUtilisateur())
        actions.resetForm();
        onCancel();
      } else {
        notification.error({
          message: "Erreur d'inscription",
          description: result.payload,
          duration: 6
        });
      }
    };
  
    const { values, handleChange, handleSubmit, setFieldValue, resetForm } = useFormik({
      initialValues: {
        Prenom: "",
        Nom: "",
        Username: "",
        Email: "",
        Password: "",
        Password_confirmation: "",
        Titre: "",
        roles: [],
        centres: "",
        SpecialiteId: "",
      },
      onSubmit,
    });
   
    const [searchValue, setSearchValue] = useState('');

    const filtrData = dataUtilisateur?.filter(item => {
      const nomRoles = item.Roles?.map(role => role.name).join(', ') || '';
      const nomCentres = item.Centres?.map(centre => centre.NomCentre).join(', ') || ''; 
      return (
        item.Titre.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Nom.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Email.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Prenom.toLowerCase().includes(searchValue.toLowerCase()) ||
        nomCentres.toLowerCase().includes(searchValue.toLowerCase()) || 
        nomRoles.toLowerCase().includes(searchValue.toLowerCase()) || 
        item.Username.toLowerCase().includes(searchValue.toLowerCase())
      );
    });


    return (
        <div className='main'>
          <div className="buttons-container"  style={{ marginLeft: collapsed ? 192 : 90 }} >
              <BtnSubmit type_="button"
                  onClick={handleOpenModal}
                  className="btnAjouter"
                  width_="100px"
                  margin_="0px 65px -10px 640px"
                  buttonText="Ajouter"
                  />
                    <ModalComponent
                    _maskClosable={false}
                    onCancel={onCancel}
                    _open={openModal} 
                    _width="700px" 
                    _title= "Ajouter un utilisateur"
                    form={
                      <form onSubmit={handleSubmit}>
                      <Row gutter={10}>
                        <Col span={12}>
                          <p className="inputRegistre">Titre</p>
                          <SelectInput 
                            required 
                            name="Titre" 
                            placeholder_="Titre" 
                            options={SelectTitreOptions} 
                            value={values.Titre} 
                            onChange={handleChangeTitre} 
                          />
                        </Col>
                        <Col span={12}>
                          <p className="inputRegistre">Rôle</p>
                         <SelectInput
                            mode="tags"
                            tokenSeparators={[',']}
                            // height_="10vh"
                            name="roles"
                            placeholder_="Veuillez choisir le rôle"
                            options={SelectRolesOptions}
                            value={values.roles}
                            onChange={handleChangeRole}
                         />                         
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={12}>
                          <p className="inputRegistre">Prénom</p>
                          <InputText 
                            required 
                            value={values.Prenom} 
                            name="Prenom" 
                            type="text" 
                            placeholder_="Prénom" 
                            onChange={handleChange}
                          />
                        </Col>
                        <Col span={12}>
                          <p className="inputRegistre">Nom</p>
                          <InputText 
                            required 
                            value={values.Nom} 
                            name="Nom" 
                            type="text" 
                            placeholder_="Nom" 
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={12}>
                          <p className="inputRegistre">Username</p>
                          <InputText 
                            required 
                            value={values.Username} 
                            name="Username" 
                            type="text" 
                            placeholder_="Identifiant" 
                            onChange={handleChange}
                          />
                        </Col>
                        <Col span={12}>
                          <p className="inputRegistre">Email</p>
                          <InputText 
                            required 
                            value={values.Email} 
                            name="Email" 
                            type="email" 
                            placeholder_="exemple@gmail.com" 
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      <Row gutter={10}>
                        <Col span={12}>
                          <p className="inputRegistre">Mot de passe</p>
                          <InputText 
                            required 
                            value={values.Password} 
                            name="Password" 
                            type="password" 
                            placeholder_="Mot de passe" 
                            onChange={handleChange}
                          />
                        </Col>
                        <Col span={12}>
                          <p className="inputRegistre">Confirmer mot de passe</p>
                          <InputText 
                            required 
                            value={values.Password_confirmation} 
                            name="Password_confirmation" 
                            type="password" 
                            placeholder_="Confirmer mot de passe" 
                            onChange={handleChange}
                          />
                        </Col>
                      </Row>
                      
                      <Row gutter={10}>
                        <Col span={12}>
                          <p className="inputRegistre">Centre</p>
                          <SelectInput 
                            required 
                            name="centres" 
                            placeholder_="Centre" 
                            options={centresOptions} 
                            value={values.centres} 
                            onChange={(value) => setFieldValue("centres", value)}
                          />
                        </Col>
                        <Col span={12}>
                          <p className="inputRegistre">Spécialité</p>
                          <SelectInput 
                            required 
                            name="SpecialiteId" 
                            placeholder_="Spécialité" 
                            options={specialitesOptions} 
                            value={values.SpecialiteId} 
                            onChange={(value) => setFieldValue("SpecialiteId", value)} 
                          />
                        </Col>
                      </Row>
                      <br/>
                      <br/>
                      <BtnSubmit
                        className="btnSubmitRegistre"
                        type_="submit"
                        disable='none'
                        bgNormalColor="#78953E"
                        bgHoverColor="#4589C7"
                        buttonText="S'inscrire"
                        position_="relative"
                        // onClick={handleSubmitUstilisateur}
                      />
                    </form>
                    } 
                      />
         


            <BtnSearch 
            margin_="5px 0px -11px -40px" 
            height_="39px" 
            bgNormalColor="#ffffff" 
            border_="1.5px solid #1677FF" 
            onChange_={(e) => setSearchValue(e.target.value)}
            allowClear
            />
          </div>
          <div>
            <Tableau 
                rowKey={(record) => record.id}
                columns={colomnsUtilisateur} 
                data={filtrData ? [...filtrData].reverse() : []} 
                title={() => <div style={{ textAlign: 'left', fontWeight:"bold", marginTop:"-6px" }}>Utilisateurs</div>} 
                loading={loading} /> 
          </div>

          <Modal 
          maskClosable={false}
          closable={false}
          title="Activer un utilisateur" 
          open={openModalActivate} 
          // onOk={(record) => handleActivateUser(record.id)} 
          onOk={() => handleActivateUser(activatingUserId)}
          onCancel={handleCancel}
          okText="Oui" 
          cancelText="Non">
              <p>Voulez-vous activer cet utilisateur?</p>
          </Modal>

          <Modal 
          maskClosable={false}
          closable={false}
          title="Désactiver un utilisateur" 
          open={openModalDesactivate} 
          // onOk={(record) => handleActivateUser(record.id)} 
          onOk={() => handleDesactivateUser(activatingUserId)}
          onCancel={handleCancel}
          okText="Oui" 
          cancelText="Non">
              <p>Voulez-vous désactiver cet utilisateur?</p>
          </Modal>

        </div>
    );
   
};

export default Utilisateurs;