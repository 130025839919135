import { CalendarFilled, CalendarOutlined, ContactsFilled, ContactsOutlined, EditOutlined, HomeFilled, HomeOutlined, IdcardFilled, SettingFilled, SmileFilled, TeamOutlined, UploadOutlined, UpSquareFilled, UsergroupAddOutlined, UserOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { Avatar, Button } from "antd";
import "./../components/topbar/topbar.css";


export const SelectOptions = [
  {
    label: (
      <div className="selectOptionUser">
        <Avatar size={50} icon={<UserOutlined />} className="userIcon" /><br />
        {/* className="userIcon"  */}
        <Button type="button" className="btnProfil">Profil utilisateur</Button>
      </div>
    ),
    value: 'option1'
  },
];


export const items = [
  {
    key: '1',
    label: (
      <a>
        Profil
      </a>
    ),
  },
  {
    key: '2',
    label: (
      <a >
        Déconnexion
      </a>
    ),
  },

];


export const Specialite = ["Specialite1", "Specialite2", "Specialite3", "Specialite4"];
export const SelectSpecialiteOptions = [
  { label: 'Specialite1', value: 'specialite1' },
  { label: 'Specialite2', value: 'specialite2' },
  { label: 'Specialite3', value: 'specialite3' },
  { label: 'Specialite4', value: 'specialite4' },
];

export const SelectOrganeDigestifOptions = [
  { label: 'Anus', value: 'Anus' },
  { label: 'Colon et rectum', value: 'Colon et rectum' },
  { label: 'Estomac', value: 'Estomac' },
  { label: 'Foie', value: 'Foie' },
  { label: 'Oesophage', value: 'Oesophage' },
  { label: 'Pancreas', value: 'Pancreas' },
  { label: 'Voies biliaires', value: 'Voies biliaires' }
];
// SelectOrganeHematologieOptions
export const SelectOrganeHematologieOptions = [
  { label: 'Néoplasmes des cellules histiocytaires/dendritiques', value: 'Néoplasmes des cellules histiocytaires/dendritiques' },
  { label: 'Lymphome Hodgkin adulte', value: 'Lymphome Hodgkin adulte' },
  { label: 'LA Adulte', value: 'LA Adulte' },
  { label: 'Leucémie Lymphoïde Chronique', value: 'Leucémie Lymphoïde Chronique' },
  { label: 'Leucémie myéloïde Chronique', value: 'Leucémie myéloïde Chronique' },
  { label: 'Lymphome non hodgkinien adulte', value: 'Lymphome non hodgkinien adulte' },
  { label: 'Myélome multiple', value: 'Myélome multiple' },
  { label: 'Syndromes myélodysplasiques', value: 'Syndromes myélodysplasiques' },
  { label: 'Syndrome Myéloprolifératif Ph1 négative', value: 'Syndrome Myéloprolifératif Ph1 négative' }
]
// SelectOrganeGynecologieOptions
export const SelectOrganeGynecologieOptions = [
  { label: 'Néoplasies trophoblastiques gestationnelles', value: 'Néoplasies trophoblastiques gestationnelles' },
  { label: 'Ovaires', value: 'Ovaires' },
  { label: 'Col', value: 'Col' },
  { label: 'Utérus', value: 'Utérus' },
  { label: 'Vagin', value: 'Vagin' },
  { label: 'Vulve', value: 'Vulve' },
  { label: 'Sein', value: 'Sein' },
]


export const SelectOrganeCancersRaresOptions = [ 
  { label: 'Parathyroïde', value: 'Parathyroïde' },
  { label: 'Medullosurrenale', value: 'Medullosurrenale' },
  { label: 'Ganglion sympathique', value: 'Ganglion sympathique' },
  { label: 'Ganglion parasympathique', value: 'Ganglion parasympathique' },
  { label: 'Cortex de la glande surrénale', value: 'Cortex de la glande surrénale' },
];


export const SelectOrganeORLOptions = [
  { label: 'Cavité buccale', value: 'Cavité buccale' },
  { label: 'Pharynx', value: 'Pharynx' },
  { label: 'Larynx', value: 'Larynx' },
  { label: 'Cavité nasale', value: 'Cavité nasale' },
  { label: 'Lévre', value: 'Lévre' },
  { label: 'Sinus', value: 'Sinus' },
  { label: 'Glandes salivaire', value: 'Glandes salivaire' },
  { label: 'Cavum', value: 'Cavum' },
  { label: 'Nasopharynx', value: 'Nasopharynx' },
  { label: 'Thyroide', value: 'Thyroide' },
]

export const SelectOrganeOncoPediatrieOptions = [
  { label: 'Néoplasmes des cellules histiocytaires/dendritiques', value: 'Néoplasmes des cellules histiocytaires/dendritiques' },
  // { label: 'LA', value: 'laOncopediatrie' },
  // { label: 'Lymphome', value: 'LymphomeOncopediatrie' },
  { label: 'Tumeurs cerebrales', value: 'Tumeurs cerebrales' },
  { label: 'Tumeurs orbitaires', value: 'Tumeurs orbitaires' },
  { label: 'Tumeurs maxillo faciales', value: 'Tumeurs maxillo faciales' },
  { label: 'Tumeurs mediastinales', value: 'Tumeurs mediastinales' },
  { label: 'Foie', value: 'Foie' },
  { label: 'Ovaire', value: 'Ovaire' },
  { label: 'Voie excretrice', value: 'Voie excretrice' },
  { label: 'Prostate', value: 'Prostate' },
  { label: 'Rein', value: 'Rein' },
  { label: 'Testicul/para-testicule', value: 'Testicul/para-testicule' },
  { label: 'Vessie', value: 'Vessie' },
  { label: 'Rétropéritoine', value: 'Rétropéritoine' },
  { label: 'OS', value: 'OS' },
  { label: 'T. Sacro-coccygienne', value: 'T. Sacro-coccygienne' },
  { label: 'Corticosurrenale', value: 'Corticosurrenale' },
  { label: 'Oesophage', value: 'Oesophage' },
  { label: 'Colo-rectum', value: 'Colo-rectum' },

]

export const SelectOrganeThoraxOptions = [
  { label: 'Poumon', value: 'Poumon' },
  { label: 'Médiastin', value: 'Médiastin' },
  { label: 'Plèvre', value: 'Plèvre' },
  { label: 'Parois thoracique', value: 'Parois thoracique' },
]

export const SelectOrganeUrologieOptions = [
  { label: 'Prostate', value: 'Prostate' },
  { label: 'Vessie/Arbre urinaire', value: 'Vessie/Arbre urinaire' },
  { label: 'Testicule', value: 'Testicule' },
  { label: 'Penis', value: 'Penis' },
  { label: 'Surrenale', value: 'Surrenale' },
  { label: 'Reins', value: 'Reins' },
]

export const SelectMenopauseOptions = [
  { label: 'Oui', value: 'Oui' },
  { label: 'Non', value: 'Non' }
]

export const roles = ["role1", "role2", "role3"];
export const SelectRolesOptions = [
  { label: 'Admin', value: 'admin' },
  // { label: 'User', value: 'user' },
  { label: 'Médecin', value: 'medecin' },
];

export const SelectCentresOptions = [
  { label: 'Centre1', value: 'centre1' },
  { label: 'Centre2', value: 'centre2' },
];

export const SelectTitreOptions = [
  { label: 'Dr', value: 'Dr' },
  { label: 'Pr', value: 'Pr' },
  { label: 'Mr', value: 'Mr' },
  { label: 'Mme', value: 'Mme' },
];

export const SelectTypeOptions = [
  { label: 'Type 1', value: 'type 1' },
  { label: 'Type 2', value: 'type 2' },
];
export const SelectAntecedantOptions = [
  { label: 'Antécédant 1', value: 'Antecedant 1' },
  { label: 'Antécédant 2', value: 'Antecedant 2' },
  { label: 'Antécédant 3', value: 'Antecedant 3' },
  { label: 'Antécédant 4', value: 'Antecedant 4' },
]

export const SelectSexeOptions = [
  { label: 'Masculin', value: 'Masculin' },
  { label: 'Féminin', value: 'Féminin' },
];
export const SelectSituationMatOptions = [
  { label: 'Marié(e)', value: 'Marié(e)' },
  { label: 'Célibataire', value: 'Célibataire' },
  { label: 'Divorcé(e)', value: 'Divorcé(e)' },
  { label: 'Veuf(ve)', value: 'Veuf' },
];
export const SelectMedcinOptions = [
  { label: 'Dr Seck', value: 'Dr Seck' },
  { label: 'Dr Lopez', value: 'Dr Lopez' },
  { label: 'Dr Mbengue', value: 'Dr Mbengue' },
];

export const TerrainComorbiditesOptions = [
  'VIH',
  'Relations sexuelles anales',
  'Lésions dysplasiques anales ou génitales HPV induites',
  'Carcinome HPV induit génital ou ORL',
  'Transplantation d’organe solide',
  'Maladie de Crohn',
  'Maladies auto-immunes (lupus)',
  'Immunodépression acquise ou induite',
  //colon et r
  'Polypose adenomateuse familiale',
  'Syndrome de lynch',
  'Mici',
  'Tabac',
  'Obesite',
  // POUR FOIE
  'Hepatite virale',
  'Nash',
  'Cirrhose',
  //  Pour oesophage
  'Oesophagite caustique',
  'Oesophagite radique',
  'Oesophagite peptique',
  'RGO',
  'HPV',
  'Diverticule zenker',
  'Obesite',
  // Pour voies biliaires
  'Cholangite sclérosante',
  ' Syndrome de lynch',
  'Mutation BRCA',
  'Formes familiailes'
];
// Les options pour l'organe "Anus"
export const TerrainComorbiditesOptionsAnus = [
  'VIH',
  'Relations sexuelles anales',
  'Lésions dysplasiques anales ou génitales HPV induites',
  'Carcinome HPV induit génital ou ORL',
  'Transplantation d’organe solide',
  'Maladie de Crohn',
  'Maladies auto-immunes (lupus)',
  'Immunodépression acquise ou induite',
];
// Les options pour l'organe "Colon et rectum"
export const TerrainComorbiditesOptionsConRectum = [
  'Polypose adenomateuse familiale',
  'Syndrome de lynch',
  'Mici',
  'Tabac',
  'Obesite'
];
// TerrainComorbiditesOptionsFoie
export const TerrainComorbiditesOptionsFoie = [
  'Hepatite virale',
  'Nash',
  'Cirrhose',
];
// TerrainComorbiditesOptionsOesophage
export const TerrainComorbiditesOptionsOesophage = [
  'Oesophagite caustique',
  'Oesophagite radique',
  'Oesophagite peptique',
  'RGO',
  'HPV',
  'Diverticule zenker',
  'Obesite',
];
// TerrainComorbiditesOptionsVoiesBiliaire
export const TerrainComorbiditesOptionsVoiesBiliaire = [
  'Cholangite sclérosante',
  ' Syndrome de lynch',
  'Mutation BRCA',
  'Formes familiailes'
];

// export const TerrainComorbiditesOptionsCancers = [

// ];
export const TyroideOptions = [
  "Irradiation ionisante", "NEM2", "Accidents nucléaires", "obésité", "alimentation"
]
export const TumeursCerebralesOptions = [
  "Drépanocytose", "hypertrophie corporelle", "Aniridie", "Anomalie genitourinaire", "Widman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Noonan syndrome", "Neurofibromatosis type 1", "Li-Fraumeni syndrome",
  "DICER1 syndrome", "Costello syndrome", "Retinoblastome hereditaire", "sclerose tubereuse", "Polypose adenomateuse familiale", "Autres"
]
export const TumeursOrbitairesOptions = [
  "Drépanocytose", "hypertrophie corporelle", "Aniridie", "Anomalie genitourinaire", "Widman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Autres", "Costello syndrome", "Noonan syndrome"
  , "Neurofibromatosis type 1", "Li-Fraumeni syndrome"
]
export const TumeursMediastinalesOptions = [
  "Drépanocytose", "hypertrophie corporelle", "Aniridie", "Anomalie genitourinaire", "Widman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Autres", "Costello syndrome", "Noonan syndrome",
  "Neurofibromatosis type 1", "Li-Fraumeni syndrome"
]
export const TumeursMaxilloFacialesOptions = [
  "Drépanocytose", "hypertrophie corporelle", "Aniridie", "Anomalie genitourinaire", "Widman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Autres", "Noonan syndrome", "Neurofibromatosis type 1",
  "Li-Fraumeni syndrome", "DICER1 syndrome", "Costello syndrome"
]
export const FoiePédiatrieOptions = [
  "Beckwith-Wiedmann", "Polyadénomatose familiale", "Hémihypertrophie", "Petit poids de naissance (<1500g)",
  "Prématurité <33 SA", "Polypose colique", "Syndrome de Gardner", "Exposition aux métaux", "Exposition maternelle aux dérivés du pétrole et peinture",
  "Hépatite virale chronique B", "Glycogénose de type 1 ou 3", "Tyrosinémie", "Maladie de Byler", "Cholestase fibrogène familiale",
  "Atrésie des voies biliaires", "Déficit en a1-antitrypsine", "Hémochromatose", "Shunt portocave spontané ou chirurgical",
]
export const OvaireOptions = [
  "Turner", "Sd Li", "Fraumeni", "Autres"
]
export const ProstateOptions = [
  "Syndrome de Li Fraumeni", "Syndrome de Beckwith", "Wiedemann", "Syndrome de Costello", "Neurofibromatose", "Autres",
]
export const ReinOptions = [
  "Drépanocytose", "Wedman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Autres",
]
export const RetroperitoineOptions = [
  "Von Hippel Lindau", "Li-Fraumeni",
]
export const OSOptions = [
  "Drépanocytose", "Wedman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Autres",
]
export const CorticosurrenaleOptions = [
  "Drépanocytose", "hypertrophie corporelle", "Aniridie", "Anomalie genitourinaire", "Widman Beckwith", "Denys Drash", "Wagr", "Smith Lemli Opitz", "Autres ", "Costello syndrome", "Noonan syndrome",
  "Neurofibromatosis type 1", "Li-Fraumeni syndrome",
]
export const ColoRectumOptions = [
  "RCH", "Crohn", "polypose digestive", "syndrome de LINCH I et II",
]
export const MediastinOptions = [
  "Diabète", "VIH", "Traitement immunosuppresseur", "Autres",
]
export const OesophageOptions = [
  "Oesophagite de Barret", "Ingestion de caustique",
]

export const cavitePharynxLarynxCaviteNLevreSinusGlandOptions = [
  "HPV", "P16+"
]
export const TerrainComorbiditeVideOptions = [

]
//  FacteursEnvironnementauxCavumNasopharynxOptions
export const FacteursEnvironnementauxCavumNasopharynxOptions = [
  "Consommation aliments salés et fumés", "Plantes médicinales chinoises", "Consommation de viande de mouton salé / beurre rance"
]
export const FacteursEnvironnementauxOptions = [
  'Dérivés nitrés', 'Reflux gastro-oesophagien aliments salés,fumés ou frits',
  'Consommation de viandes rouges',
  'Alcool et Tabac +/-'
];
export const FacteursInfectieuxOptions = [
  'Hélicobacter pylori(HP):Risque x 2', 'Virus Epstein-Barr', 'Certains parasites et champignons',
];
export const FacteursGenetiquesOptions = [
  "Cas familiaux de cancers de l'estomac: 2 apparentés de premier degré", "Antécédent familial de cancer de l'estomac","Syndromes de LYNCH de type 2","Polypose adénomateuse familiale"
];
export const OvairColUtVaVuOptions = ["Obésité", "Infection au VIH", "Sd de Lynch"];
export const SeinOptions = ["Obésité", "Infection au VIH"];

export const SelectFacteursEnvironnementauxOptions = [
  { label: 'Dérivés nitrés', value: 'Dérivés nitrés' },
  { label: 'Reflux gastro-oesophagien', value: 'Reflux gastro-oesophagien' },
  { label: 'Aliments salés,fumés ou frits', value: 'Aliments salés,fumés ou frits' },
  { label: 'Consommation de viandes rouges', value: 'Consommation de viandes rouges' },
  { label: 'Alcool et Tabac +/-', value: 'Alcool et Tabac +/-' },
];
export const SelectFacteursInfectieuxOptions = [
  { label: 'Hélicobacter pylori(HP):Risque x 2', value: 'Hélicobacter pylori(HP):Risque x 2' },
  { label: 'Virus Epstein-Barr', value: 'Virus Epstein-Barr' },
  { label: 'Certains parasites et champignons', value: 'Certains parasites et champignons' },
];
export const SelectFacteursGenetiquesOptions = [
  { label: "Cas familiaux de cancer de l'estomac:2 apparentés de premier degré", value: "Cas familiaux de cancer de l'estomac:2 apparentés de premier degré" },
  { label: "Antécédent familial de cancer de l'estomac", value: "Antécédent familial de cancer de l'estomac" },
];


export const SelectDesirF = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const SelectAntecedentsGynecoObs = [
  { label: 'Gestite', value: 'Gestite' },
  { label: 'Parité', value: 'Parité' },
  { label: 'Enfants vivants', value: 'Enfants vivants' },
  { label: 'Menopause ', value: 'Menopause' },
];
export const SelectPreservation = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const Select = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const SelectOuiNon = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const SelectAlcoolOptions = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const SelectTabacOptions = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const SelectGrossesseEvoOptions = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
]
export const SelectChanvreCannabisOptions = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
];
export const SelectScolarisationOptions = [
  { label: 'Oui', value: 'oui' },
  { label: 'Non', value: 'non' },
]
export const SelectStatutOptions = [
  { label: 'Actuel', value: 'Actuel' },
  { label: 'Sevré', value: 'Sevré' },
]