import axios from "axios";
import { BaseUrl } from "../config";

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      console.log("Token ajouté à la requête:", token); // Vérifier le token ajouté
      config.headers["x-access-token"] = token; // le bon en-tête attendu par votre API
    } else {
      console.log("Aucun token trouvé");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


const api = {
  // inscription de l'utilisateur
  signup: (body) => {
    const { values, roles, SpecialiteId, Titre, centres } = body;

    return axios.post(`${BaseUrl}api/auth/signup`, {
      Prenom: values?.Prenom,
      Nom: values?.Nom, //  la clé correspond à ce qui est attendu par l'API
      Username: values?.Username,
      Email: values?.Email,
      Password: values?.Password,
      Password_confirmation: values?.Password_confirmation,
      Titre: Titre, // Utilisation directe du Titre
      roles: roles, // 'roles' est correctement passé
      centres: [centres], // Utilisation directe de centres
      SpecialiteId: SpecialiteId, // Utilisation directe de Specialite
    });
  },

  // récupérer toutes les spécialités
  getAllSpecialite: () => {
    return axios.get(`${BaseUrl}api/specialites`); // Sans barre oblique avant 'api/specialites'
  },

  //  récupérer toutes les spécialités
  getAllCentre: () => {
    return axios.get(`${BaseUrl}api/centres`);
  },

  createSpecialite: (body) => {
    return axios.post(`${BaseUrl}api/specialites`, body)
      .then(response => response.data)
      .catch(error => {
        throw error; // Relancer l'erreur pour qu'elle soit gérée ailleurs
      });
  },
  createCentre: (body) => {
    return axios.post(`${BaseUrl}api/centres`, body)
  },
  //  récupérer toutes les utilisateurs
  getAllUtilisateur: () => {
    return axios.get(`${BaseUrl}api/auth/users`);
  },
  //  récupérer tout les antecedant    
  getAllAntecedants: () => {
    return axios.get(`${BaseUrl}api/antecedents`);
  },
  //  récupérer tout les antecedant    
  getAllTypeAntecedants: () => {
    return axios.get(`${BaseUrl}api/typeants`);
  },
  createAntecedant: (body) => {
    return axios.post(`${BaseUrl}api/antecedents`, body)
  },
  createPatient: (body) => {
    return axios.post(`${BaseUrl}api/patients`, body)
  },
  // recuperer un patient
  getOnePatient: (id) => {
    return axios.get(`${BaseUrl}api/patients/${id}`);
  },

  createTypeAntecedant: (body) => {
    return axios.post(`${BaseUrl}api/typeants`, body)
  },
  //  récupérer tout les patients    
  getAllPatients: () => {
    return axios.get(`${BaseUrl}api/patients`);
  },
  //  récupérer tout les medecins    
  getAllMedecins: () => {
    return axios.get(`${BaseUrl}api/auth/users/medecins`);
  },


  //  récupérer tout les pathologies  
  getAllPathologies: () => {
    return axios.get(`${BaseUrl}api/pathologies`);
  },
  // Créer une pathologie
  createPathologie: (body) => {
    return axios.post(`${BaseUrl}api/pathologies`, body)
  },
  //  récupérer tout les RCP  
  getAllRcps: () => {
    return axios.get(`${BaseUrl}api/rcp`);
  },
  // 
  // Créer un RCP
  createRCP: (body) => {
    return axios.post(`${BaseUrl}api/rcp`, body)
  },

  // créer un fiche RCP
  createFicheRcp: (body) => {
    return axios.post(`${BaseUrl}api/fichercp`, body)
  },


  // créer un diagniostic RCP
  createDiagniosticRcp: (body) => {
    return axios.post(`${BaseUrl}api/diagnostics`, body)
  },

  //  récupérer tout les patients RCP   
  //  getAllPatientsRCP: () => {
  //   return axios.get(`${BaseUrl}rcp/3/patients`);
  // },
  getAllPatientsRCP: (rcpId) => {
    return axios.get(`${BaseUrl}rcp/${rcpId}/patients`);
  },
  //  récupérer tout les medecins invités   
  getAllMedecinsInvite: (rcpId) => {
    return axios.get(`${BaseUrl}rcp/${rcpId}/medecins`);
  },
  createAjoutPatientRCP: (patientIds, rcpId) => {
    // Envoie un tableau d'ID en tant que corps de la requête
    return axios.post(`${BaseUrl}rcp/${rcpId}/patients`, { patientIds });
  },
  createAjoutParticipantRCP: (userIds, rcpId) => {
    // Envoie un tableau d'ID en tant que corps de la requête
    return axios.post(`${BaseUrl}rcp/${rcpId}/medecins`, { userIds });
  },
  //  récupérer tout les antecedent d'un patient  
  getAllAntecedentsPatient: (PatientId) => {
    return axios.get(`${BaseUrl}patients/${PatientId}/antecedents`);
  },
  updateDetailsUser: (id, updatedData) => {
    return axios.put(`${BaseUrl}users/update/${id}`, updatedData);
  },
  updatePassword: (id, updatedPassword) => {
    return axios.put(`${BaseUrl}api/auth/update-password/${id}`, updatedPassword);
  },
  updateActiverUser: (userId) => {
    return axios.put(`${BaseUrl}validate/${userId}`);
  },
  updateDesactiverUser: (userId) => {
    return axios.put(`${BaseUrl}deactivate/${userId}`);
  },
  // 
  createForgotPassword: (body) => {
    return axios.post(`${BaseUrl}api/auth/forgot-password`, body);
  },
  createResetPassword: (token, body) => {
    return axios.post(`${BaseUrl}api/auth/reset-password/${token}`, body);
  },
  createAntecedantPatient: (body) => {
    return axios.post(`${BaseUrl}patientAntecedents`, body)
  },
  createAGO: (body) => {
    return axios.post(`${BaseUrl}api/agos`, body)
  },
  getAllAgos: (PatientId) => {
    return axios.get(`${BaseUrl}api/agos/patient/${PatientId}`);
  },
  // getAllFichesRCP: () => {
  //   return axios.get(`${BaseUrl}api/fichercp`);
  // },
  getAllFichesRCP: (id) => {
    return axios.get(`${BaseUrl}patients/fichesrcp/${id}`);
  },

  updatePatient: (id, body) => {
    return axios.put(`${BaseUrl}api/patients/${id}`, body)
  },
  // 
  updateCentre: (id, body) => {
    return axios.put(`${BaseUrl}api/centres/${id}`, body)
  },
  // 
  updateSpecialite: (id, body) => {
    return axios.put(`${BaseUrl}api/specialites/${id}`, body)
  },
  // 
  updatePathologie: (id, body) => {
    return axios.put(`${BaseUrl}api/pathologies/${id}`, body)
  },
  // 
  updateArchivSpecialite: (id) => {
    return axios.put(`${BaseUrl}api/specialites/archives/${id}`)
  },
  // 
  updateArchivCentre: (id) => {
    return axios.put(`${BaseUrl}api/centres/archives/${id}`)
  },
  // 
  updateArchivPathologie: (id) => {
    return axios.put(`${BaseUrl}api/pathologies/archives/${id}`)
  },
  // 
  updateArchivPatient: (id) => { 
    return axios.put(`${BaseUrl}api/patients/archives/${id}`)
  },
  // 
  deletPatientRcp: (rcpId, idPatientDelet) => {
    return axios.delete(`${BaseUrl}rcp/${rcpId}/patients/${idPatientDelet}`)
  },
  // 
  deletParticipantRcp: (rcpId, idParticipantDelet) => {
    return axios.delete(`${BaseUrl}rcp/${rcpId}/medecins/${idParticipantDelet}`)
  },        
  // 
   getOneResumePatient: (id) => {
    return axios.get(`${BaseUrl}patients/${id}/summary`);
  },
  //  récupérer tout les Diagnostiques    
  getAllDiagnostiques: () => {
    return axios.get(`${BaseUrl}api/diagnostics`);
  },
  // 
  getAllDiagnosticsPatient: (id) => {
    return axios.get(`${BaseUrl}patients/diagnostics/${id}`);
  },
  // 
  updateDetailRCP: (id, body) => {
    return axios.put(`${BaseUrl}api/fichercp/${id}`, body)
  },
  // 
  updateDiagnostic: (id, body) => {
    return axios.put(`${BaseUrl}api/diagnostics/${id}`, body)
  },
  // 
  updateAntecedentP: (id, body) => {
    return axios.put(`${BaseUrl}patientAntecedents/${id}`, body)
  },
  // 
  updateAGOPatient: (id, body) => {
    return axios.put(`${BaseUrl}api/agos/${id}`, body)
  },
  // 
  postStatistics: (body) => {
    return axios.post(`${BaseUrl}api/patients/parorganes`, body)
  },
  // AllStatisticSpecialitePatient
  getAllStatisticSpecialitePatient: () => {
    return axios.get(`${BaseUrl}api/specialitebypatients`);
  },

};

export default api;
