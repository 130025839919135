import React, { useState, useEffect } from 'react';
import { BtnAjout, BtnSearch, BtnSubmit, InputText, ModalComponent, } from '../../components';
import { Avatar, Button, Card, Checkbox, Col, DatePicker, Divider, Input, message, Modal, notification, Row, Select, Space, Spin, Table, Tabs, Tooltip, Typography } from 'antd';
import "../../components/tableaux/tableau.css";
import "../rcp/rcp.css";
import { CalendarOutlined, DeleteFilled, FolderOutlined, IdcardOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { useCollapsed } from '../dashBoard/CollapsedContext';
import { fetchMedecins } from '../../redux/MedecinSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMedecinsInvite } from '../../redux/MedecinInviteSlice';
import { fetchSpecialite } from '../../redux/specialiteSlice';
import { fetchPatientsRCP } from '../../redux/PatientsRCPSlice';
import { fetchPatients } from '../../redux/patientsSlice';
import { fetchRcps } from '../../redux/RcpsSlice';
import { createRCP } from '../../redux/CreateRCPSlice';
import dayjs from 'dayjs';
import { createAjoutParticipantRCP } from '../../redux/AjoutParticipantRCPSlice';
import { createAjoutPatientRCP } from '../../redux/AjoutPatientRCPSlice';
import Fiche from '../../components/Fiche & Diagnistique/Fiche';
import Diagniostique from '../../components/Fiche & Diagnistique/Diagniostique';
import { deletePatientRcp } from '../../redux/ArchivPatientRcpSlice';
import { deleteParticipantRcp } from '../../redux/DeleteParticipantRcpSlice';
import PdfView from '../../DocumentPrint/PdfView';
import TextArea from 'antd/es/input/TextArea';
import { updateDetailRCP } from '../../redux/UpdateDetailRCPSlice';
import { fetchFichesRCP } from '../../redux/FicheRCPSlice';

const onChange = (key) => {
  console.log(key);
};
const Rcp = () => {
  const { Option, OptGroup } = Select;
  const collapsed = useCollapsed();
  const [open, setOpen] = useState();
  const [DateRcp, setDateRcp] = useState(null);
  const [IntituleRcp, setIntituleRcp] = useState();
  const [Description, setDescription] = useState();
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchValueRCP, setSearchValueRCP] = useState('');
  const [searchValueParticipant, setSearchValueParticipant] = useState('');
  const [openModalConfirmDeletPatientRCP, setOpenModalConfirmDeletPatientRCP] = useState(false);
  const [openModalConfirmDeletParticipantRCP, setOpenModalConfirmDeletParticipantRCP] = useState(false);
  const [idPatientDelet, setIdPatientDelet] = useState(null);
  const [idParticipantDelet, setIdParticipantDelet] = useState(null);
  const [activeTabParticipant, setActiveTabParticipant] = useState('2');
const [openUpdateForm, setOpenUpdateForm] = useState("");
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [UpdateDetailRCPdata, setUpdateDetailRCPdata] = useState(null);
   const [motifRcp, setMotifRcp] = useState([]);
      const [traitementRealise, setTraitementRealise] = useState([]);
      const [typeProposition, setTypeProposition] = useState([]);
      const [necessiteExam, setNecessiteExam] = useState('');
      const [miseEnTraitement, setMiseEnTraitement] = useState([]);
      const [motifDecision, setMotifDecision] = useState([]);
      const [typeChirurgie, setTypeChirurgie] = useState('');
      const [typeHepatectomie, setTypeHepatectomie] = useState('')
      const [typeTransplantation, setTypeTransplantation] = useState('');
      const [typeExereseAtypique, setTypeExereseAtypique] = useState('');
      const [TypeHepatoblasmeBasRisque, setTypeHepatoblasmeBasRisque] = useState('');
      const [typeHepatoblasmeHautRisque, setTypeHepatoblasmeHautRisque] = useState('');
      const [TypeHepatoblasmeRisqueStandard, setTypeHepatoblasmeRisqueStandard] = useState('');
      const [TypeHepatocarcinomes, setTypeHepatocarcinomes] = useState('');
      const [typeMetastatiques, setTypeMetastatiques] = useState('')
      const [TypeHCC, setTypeHCC] = useState('');
      const [resectionComplete, setResectionComplete] = useState(false);
      const [resectionIncomplete, setResectionIncomplete] = useState(false);
      const [segmentectomies, setSegmentectomies] = useState(false);
      const [hepatectomies, setHepatectomies] = useState(false);
      const [lobectomies, setLobectomies] = useState(false);
      const [enucleation, setEnucleation] = useState(false);
      const [resectionCuneiforme, setResectionCuneiforme] = useState(false);
      const [chimio, setChimio] = useState(false);
      const [cisPlatPre, setCisPlatPre] = useState(false);
      const [postOp, setPostOp] = useState(false);
      const [cispla, setCispla] = useState(false);
      const [plado, setPlado] = useState(false);
      const [cisplaChimio, setCisplaChimio] = useState(false);
      const [pladoChimio, setPladoChimio] = useState(false);
      const [carboplatine, setCarboplatine] = useState(false);
      const [adria, setAdria] = useState(false);
      const [cisplat, setCisplat] = useState(false);
      const [pladoHepatocarcinome, setPladoHepatocarcinome] = useState(false);
      const [cisplatMetastatique, setCisplatMetastatique] = useState(false);
      const [adriaMetastatique, setAdriaMetastatique] = useState(false);
      const [sorafenib, setSorafenib] = useState(false);
    const [dataFiche, setDataFiche] = useState([]);
    const [openDetailsRCP, setOpenDetailsRCP] = useState();

    
    const [PatientIdDetailRCP, setPatientIdDetailRCP] = useState();
    const [detailRCPData, setDetailRCPData] = useState();

// DETAILS RCP
  const [inputDataDetailRCP, setInputDataDetailRCP] = useState({
      MotifRcp: [],
      TraitementRealises: [],
      TypeProposition: [],
      DecisionRcpExamensComplementaires: '',
      DecisionRcpMiseEnTraitement: [],
      MotivationDecisionRcp: [],
    });
// console.log(inputDataDetailRCP.RcpId);
    const [DataDiagnostic, setDataDiagnostic] = useState([]);
  
    const handleOpenUpdateDetailRCPForm = (record) => {
      // console.log(record)
      const idRcp = rcpId;
      const donneeRcpConcerner= record.fichesPatient.filter(fiche => fiche.RcpId === idRcp);

      // console.log(donneeRcpConcerner[0].id);

      setOpenModalConfirmation(false);
      setOpenUpdateForm(true);
      setInputDataDetailRCP({
        MotifRcp: donneeRcpConcerner[0].MotifRcp || [],
        TraitementRealises: donneeRcpConcerner[0].TraitementRealises || [],
        TypeProposition: donneeRcpConcerner[0].TypeProposition || [],
        DecisionRcpExamensComplementaires: donneeRcpConcerner[0].DecisionRcpExamensComplementaires || '',
        DecisionRcpMiseEnTraitement: donneeRcpConcerner[0].DecisionRcpMiseEnTraitement || [],
        MotivationDecisionRcp: donneeRcpConcerner[0].MotivationDecisionRcp || [],
        PatientId: donneeRcpConcerner[0].PatientId || '',
        RcpId: donneeRcpConcerner[0].RcpId || '',
        UserId: donneeRcpConcerner[0].UserId || '',
        DiagnosticId: donneeRcpConcerner[0].DiagnosticId || '',
      });
    setPatientIdDetailRCP(donneeRcpConcerner[0].id);
    setDataDiagnostic(record.diagnosticsPatient);
    };
    

    // console.log(PatientIdDetailRCP);

  



// DETAILRCP




const [data, setData] = useState([]);
const [PatientId, setPatientId] = useState('');

const handleOpenModalConfirmation = (record) => {
  setUpdateDetailRCPdata(record);
  setOpenModalConfirmation(true);
  setData(record);
  setPatientId(record.id);

};


// selector
const dispatch = useDispatch();



// const { data: dataRcps, loading } = useSelector((state) => state.Rcps.data);
const { data: dataRcps, loading } = useSelector((state) => ({
  data: state.Rcps.data,
  loading: state.Rcps.loading,
}));

useEffect(() => {
  dispatch(fetchRcps());
  dispatch(fetchFichesRCP(PatientId)); 
  dispatch(fetchPatientsRCP);
}, [dispatch, PatientId]);

// useEffect(() => {
//   console.log("Données RCP :", dataRcps); // Vérifie les données
// }, [dataRcps]);

 

const RCPsOptions = dataRcps
  ? dataRcps.map((rcp) => ({
      label: rcp.IntituleRcp, // Assurez-vous que `IntituleRcp` existe dans vos objets
      value: rcp.id, // Assurez-vous que `id` existe dans vos objets
    }))
  : [];

//    let listRCP;
     //  if (dataRcps.status === 1) {
     //   listRCP = dataRcps.data; 
     //  } else {
     //   listRCP = []; 
     //  } 

// const {data:dataFichesRCP }= useSelector((state) => state.FichesRCP.data); //fichesPatient
const { data: dataFichesRCP } = useSelector((state) => state.FichesRCP.data);
const fichesPatientdata = dataFichesRCP?.fichesPatient || [];
const {data:DignostiquePatientdata} = useSelector((state) => state.diagnosticsPatient.data);


const handleCancelModalConfirmation = () => {
  setOpenModalConfirmation(false);
  setUpdateDetailRCPdata(null);
  }
  
  const handleChange = (value, key) => {
    // Mettre à jour l'état `MotifRcp` dans `inputDataDetailRCP`
    setInputDataDetailRCP((prevState) => ({
      ...prevState,
      [key]: value, // Mise à jour de la valeur sélectionnée
    }));
  };
  const onCloseUpdateForm = () => {
    setOpenUpdateForm(false);
    // setNomPathologie("");
    // setSpecialiteId("");
};

const optionMotif = [
  { value: 'Avis diagnostique', label: 'Avis diagnostique' },
  { value: '⁠Avis thérapeutique', label: '⁠Avis thérapeutique' },
  ...(((data.Specialite && data.Specialite === 'Pédiatrie' && data.Specialite === 'Cancers Rares' && data.Specialite === 'Urologie') && (data.Organe === 'Tumeurs cerebrales' || data.Organe === 'Tumeurs orbitaires' || data.Organe === 'Tumeurs maxillofaciales' || data.Organe === 'Tumeurs mediastinales ')) ? [{ value: '⁠Suivi/surveillance', label: '⁠Suivi/surveillance' }] : []),
];
const optionTraitement = [
  ...(data.Specialite && data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne' ? [] : [{ value: 'Radio-chimiothérapie', label: 'Radio-chimiothérapie' }]),
  { value: 'Chirurgie', label: 'Chirurgie' },

  ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Foie'
      ? []
      : [
          ...(data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne'
              ? [] : [
                  ...(data.Specialite === 'Pédiatrie' && (data.Organe === 'Rétropéritoine' || data.Organe === 'Oesophage') && (data.Specialite === 'Cancers Rares' && data.Organe !== 'Cortex de la glande surrénale') ? [] : [{ value: 'Curiethérapie', label: 'Curiethérapie' }]),
                  { value: 'Radiothérapie', label: 'Radiothérapie' }
              ]
          ),
          ...(data.Specialite === 'Pédiatrie' && data.Organe === 'Rétropéritoine' && (data.Specialite === 'Cancers Rares' && data.Organe !== 'Cortex de la glande surrénale') && (data.Specialite === 'Urologie' && (data.Organe === 'Vessie /Arbre urinaire' || data.Organe === 'Testicule')) ? [] : [{ value: 'Hormonothérapie', label: 'Hormonothérapie' }]),
      ]
  ),
  { value: 'Chimiothérapie', label: 'Chimiothérapie' },

  ...(data.Specialite && data.Specialite === 'Pédiatrie' && data.Organe === 'T. Sacro-coccygienne' ? [] : [
      { value: 'Immunothérapie', label: 'Immunothérapie' },
      ...((data.Specialite === 'Pédiatrie' && data.Organe === 'Oesophage') || (data.Specialite === 'Cancers Rares' && data.Organe === 'Parathyroïde')
          ? [] : [{ value: 'Radiologie interventionnelle', label: 'Radiologie interventionnelle' }]),
      ...((data.Specialite === 'Urologie' && data.Organe === 'Rein') || (data.Specialite === 'Cancers Rares' && data.Organe === 'Parathyroïde') ? [] : [
          { value: 'Endoscopie interventionnelle', label: 'Endoscopie interventionnelle' }
      ])
  ]),

  { value: 'Soin de support', label: 'Soin de support' },

  ...(data.Specialite && (
      (data.Specialite === 'Gynécologie') ||
      (data.Specialite === 'Pédiatrie' && data.Organe === 'Néoplasmes des cellules histiocytaires/dendritiques') ||
      (data.Organe === 'Corticosurrenale') ||
      (data.Organe === 'Colo-rectum') ||
      (data.Specialite === 'Hématologie') ||
      (data.Specialite === 'Thorax' && data.Organe === 'Poumon') ||
      (data.Specialite === 'Urologie' && (
          data.Organe === 'Prostate' ||
          data.Organe === 'Testicule' ||
          data.Organe === 'Penis' ||
          data.Organe === 'Surrenale' ||
          data.Organe === 'Rein'
      )) || (data.Specialite === 'Cancers Rares' && (data.Organe === 'Cortex de la glande surrénale' || data.Organe === 'Parathyroïde'))
  ) ? [{ value: 'Thérapie ciblée', label: 'Thérapie ciblée' }] : []),

  ...((data.Specialite === 'Urologie' && (data.Organe === 'Vessie /Arbre urinaire')) || (data.Specialite === 'Cancers Rares' && (data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale')) ? [
      { value: 'BCG thérapie', label: 'BCG thérapie' }
  ] : []),

  ...(data.Specialite && data.Specialite === 'Thorax' && (
      data.Organe === 'Plèvre' || data.Organe === 'Parois thoracique'
  ) ? [{ value: 'Radiothérapie thoracique', label: 'Radiothérapie thoracique' }] : []),

  ...(data.Specialite === 'Thorax' ? [
      { value: 'Radio-chimiothérapie concomitante', label: 'Radio-chimiothérapie concomitante' },
      { value: 'Radio-chimiothérapie séquentielle', label: 'Radio-chimiothérapie séquentielle' }
  ] : []),

  ...(data.Specialite === 'Cancers Rares' && (data.Organe !== 'Parathyroïde ') ? [
      { value: 'Radio fréquence', label: 'Radio fréquence' },
      { value: 'Chimio-embolisation des métastases', label: 'Chimio-embolisation des métastases' }
  ] : []),

  ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale') ? [
      { value: 'Radiothérapie interne vectorisée', label: 'Radiothérapie interne vectorisée' },
  ] : []),

  ...(data.Specialite && (
      data.Specialite === "Hématologie" ||
      (data.Specialite === "Pédiatrie" && data.Organe === "Néoplasmes des cellules histiocytaires/dendritiques") ||
      data.Organe === "Corticosurrenale" ||
      data.Organe === "Colo-rectum"
  ) ? [{ value: "Greffe de moelle", label: "Greffe de moelle" }] : []),

  { value: "Autre traitement", label: "Autre traitement" },

  // Foie
  ...(data.Specialite === "Pédiatrie" && data.Organe === "Foie" ? [
      { value: "Hépatectomies réglées", label: "Hépatectomies réglées" },
      { value: "Exérèses atypiques", label: "Exérèses atypiques" },
      { value: "Transplantation", label: "Transplantation" },
      { value: "Hépatoblastome de bas risque", label: "Hépatoblastome de bas risque" },
      { value: "Hépatoblastome de risque standard", label: "Hépatoblastome de risque standard" },
      { value: "Hépatoblastome de haut risque", label: "Hépatoblastome de haut risque" },
      { value: "Hépatocarcinomes", label: "Hépatocarcinomes" },
      { value: "Métastatiques, rupture", label: "Métastatiques, rupture" },
      { value: "HCC", label: "HCC" }
  ] : []),
];
const optionTypeProposition = [
  ...(data.Specialite && data.Specialite === 'Thorax' ? [] : [
      { value: "Application d’une recommandation de XXX", label: "Application d’une recommandation de XXX" },
      { value: "Application référentiel", label: "Application référentiel" }
  ]),
  { value: "Traitement hors référentiel", label: "Traitement hors référentiel" },
  { value: "Essai thérapeutique", label: "Essai thérapeutique" },
  { value: "Recours à une autre RCP", label: "Recours à une autre RCP" },

  ...(data.Specialite && data.Specialite === 'Thorax' ? [
      { value: "Application d’un référentiel international", label: "Application d’un référentiel international" },
      { value: "Application d’un référentiel national", label: "Application d’un référentiel national" }
  ] : []),
];
const optionDecision = [
  { value: "Chirurgie", label: "Chirurgie" },

  ...(data.Specialite && data.Specialite === "Pédiatrie" && data.Organe === "Foie" ? [] :
      [
          ...(data.Specialite === "Pédiatrie" && data.Organe === "Corticosurrenale" ? [] : [
              ...(data.Specialite && data.Specialite === "Urologie" && (
                  data.Organe === "Rein" ||
                  data.Organe === "Vessie /Arbre urinaire" ||
                  data.Organe === "Testicule"
              ) && (data.Specialite === 'Cancers Rares' && data.Organe !== 'Cortex de la glande surrénale') ? [] : [
                  { value: "Curiethérapie", label: "Curiethérapie" }
              ]),
              { value: "Radio-Chimiothérapie", label: "Radio-Chimiothérapie" }
          ]),
          { value: "Radiothérapie", label: "Radiothérapie" }
      ]
  ),

  ...(data.Specialite === "Pédiatrie" && data.Organe === "Corticosurrenale" ? [] : [
      { value: "Immunothérapie", label: "Immunothérapie" },
      ...(data.Specialite === "Cancers Rares" && data.Organe === "Parathyroïde " ? [] : [

          { value: "Radiologie interventionnelle", label: "Radiologie interventionnelle" }
      ]),

  ]),

  ...(data.Specialite === 'Cancers Rares' && (data.Organe !== 'Parathyroïde ') ? [
      { value: 'Radio fréquence', label: 'Radio fréquence' },
      { value: 'Chimio-embolisation des métastases', label: 'Chimio-embolisation des métastases' }
  ] : []),

  ...(data.Specialite === 'Cancers Rares' && (data.Organe === 'Ganglion parasympathique' || data.Organe === 'Ganglion sympathique' || data.Organe === 'Medullosurrenale') ? [
      { value: 'Radiothérapie interne vectorisée', label: 'Radiothérapie interne vectorisée' },
  ] : []),


  { value: "Chimiothérapie", label: "Chimiothérapie" },

  (
      (data.Specialite === "Urologie" && (
          data.Organe === "Rein" ||
          data.Organe === "Vessie /Arbre urinaire" ||
          data.Organe === "Testicule" ||
          data.Organe === "Penis" ||
          data.Organe === "Surrenale"
      )) ||
      (data.Specialite === 'Cancers Rares' && data.Organe !== 'Parathyroïde')
  ) ? [] : [
      { value: "Endoscopie interventionnelle", label: "Endoscopie interventionnelle" },
      ...(data.Specialite === 'Cancers Rares' && data.Organe === 'Cortex de la glande surrénale' ? [
          { value: "Hormonothérapie", label: "Hormonothérapie" }
      ] : [])
  ],
  { value: "Soins de support", label: "Soins de support" },
  { value: "Surveillance", label: "Surveillance" },

  ...(data.Specialite && data.Specialite === 'Hématologie' ? [{ value: 'Thérapie ciblée', label: 'Thérapie ciblée' }] : []),

  ...(data.Specialite && data.Specialite === 'Thorax' ? [
      { value: 'Radio-chimiothérapie concomitante', label: 'Radio-chimiothérapie concomitante' },
      { value: 'Radio-chimiothérapie séquentielle', label: 'Radio-chimiothérapie séquentielle' }
  ] : []),

  ...(data.Specialite && (data.Specialite === 'Hématologie' || data.Specialite === 'Pédiatrie') ? [{ value: 'Greffe de moelle', label: 'Greffe de moelle' }] : []),

  { value: 'Autre traitement', label: 'Autre traitement' },
];


// Gestion du changement d'état pour chaque checkbox
const handleResectionCompleteChange = (e) => {
  setResectionComplete(e.target.checked);
  if (e.target.checked) {
      setTypeChirurgie('résection complète'); // Met à jour typeChirurgie si résection complète est cochée
  } else if (!resectionComplete) {
      setTypeChirurgie(''); // Réinitialise typeChirurgie si aucune case n'est cochée
  }
};

const handleResectionIncompleteChange = (e) => {
  setResectionIncomplete(e.target.checked);
  if (e.target.checked) {
      setTypeChirurgie('résection incomplète'); // Met à jour typeChirurgie si résection incomplète est cochée
  } else if (!resectionIncomplete) {
      setTypeChirurgie(''); // Réinitialise typeChirurgie si aucune case n'est cochée
  }
};

const handleChimioChange = (e) => {
  setChimio(e.target.checked);
  if (e.target.checked) {
      setTypeTransplantation(' Chimiothérapie');
  } else if (!chimio) {
      setTypeTransplantation('');
  }

};

// Gestion du changement d'état pour chaque checkbox typeHepatectomie
const handleSegmentectomiesChange = (e) => {
  setSegmentectomies(e.target.checked);
  if (e.target.checked) {
      setTypeHepatectomie('Segmentectomies'); // Met à jour TypeHepatectomie si Hépatectomies est cochée
  } else if (!segmentectomies) {
      setTypeHepatectomie(''); // Réinitialise TypeHepatectomie si aucune case n'est cochée
  }
};

const handleHepatectomiesChange = (e) => {
  setHepatectomies(e.target.checked);
  if (e.target.checked) {
      setTypeHepatectomie('Hépatectomies droite ou gauche'); // Met à jour TypeHepatectomie si Hépatectomies est cochée
  } else if (!hepatectomies) {
      setTypeHepatectomie(''); // Réinitialise TypeHepatectomie si aucune case n'est cochée
  }
};

const handleLobectomiesChange = (e) => {
  setLobectomies(e.target.checked);
  if (e.target.checked) {
      setTypeHepatectomie('Lobectomies droite ou gauche');
  } else if (!lobectomies) {
      setTypeHepatectomie('');
  };
};

// ////////////
const handleEnucleationChange = (e) => {
  setEnucleation(e.target.checked);
  if (e.target.checked) {
      setTypeExereseAtypique(' Énucléation');
  } else if (!enucleation) {
      setTypeExereseAtypique('');
  };
};

const handleResectionCuneiformeChange = (e) => {
  setResectionCuneiforme(e.target.checked);
  if (e.target.checked) {
      setTypeExereseAtypique('Résection cunéiforme');
  } else if (!resectionCuneiforme) {
      setTypeExereseAtypique('');
  };
};

// ////////////////
const handleCisPlatPreChange = (e) => {
  setCisPlatPre(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeBasRisque('cis-plat en pré');
  } else if (!cisPlatPre) {
      setTypeHepatoblasmeBasRisque('');
  };
};

const handlePostOpChange = (e) => {
  setPostOp(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeBasRisque('post-op');
  } else if (!postOp) {
      setTypeHepatoblasmeBasRisque('');
  };
};

//////////////

const handleCisplaChange = (e) => {
  setCispla(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeRisqueStandard('cispla');
  } else if (!cispla) {
      setTypeHepatoblasmeRisqueStandard('');
  };
};

const handlePladoChange = (e) => {
  setPlado(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeRisqueStandard('PLADO');
  } else if (!plado) {
      setTypeHepatoblasmeRisqueStandard('');
  };
};

const handleCisplaChimioChange = (e) => {
  setCisplaChimio(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeRisqueStandard('cispla');
  } else if (!cisplaChimio) {
      setTypeHepatoblasmeRisqueStandard('');
  };
};

const handlePladoChimioChange = (e) => {
  setPladoChimio(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeRisqueStandard('Plado');
  } else if (!pladoChimio) {
      setTypeHepatoblasmeRisqueStandard('');
  };
};
//////////////////


const handleCarboplatineChange = (e) => {
  setCarboplatine(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeHautRisque('carboplatine');
  } else if (!carboplatine) {
      setTypeHepatoblasmeHautRisque('');
  };
};

const handleAdriaChange = (e) => {
  setAdria(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeHautRisque('adria');
  } else if (!adria) {
      setTypeHepatoblasmeHautRisque('');
  };
};

const handleCisplatChange = (e) => {
  setCisplat(e.target.checked);
  if (e.target.checked) {
      setTypeHepatoblasmeHautRisque('ciplat');
  } else if (!cisplat) {
      setTypeHepatoblasmeHautRisque('');
  };
};

const handlePladoHepatocarcinomeChange = (e) => {
  setPladoHepatocarcinome(e.target.checked);
  if (e.target.checked) {
      setTypeHepatocarcinomes('PLADO');
  } else if (!pladoHepatocarcinome) {
      setTypeHepatocarcinomes('');
  };
};

const handleCisplatMetastatiqueChange = (e) => {
  setCisplatMetastatique(e.target.checked);
  if (e.target.checked) {
      setTypeMetastatiques('cisplat');
  } else if (!cisplatMetastatique) {
      setTypeMetastatiques('');
  };
};
const handleAdriaMetastatiqueChange = (e) => {
  setAdriaMetastatique(e.target.checked);
  if (e.target.checked) {
      setTypeMetastatiques('Adria');
  } else if (!adriaMetastatique) {
      setTypeMetastatiques('');
  };
};
const handleSorafenibChange = (e) => {
  setSorafenib(e.target.checked);
  if (e.target.checked) {
      setTypeHCC('Sorafenib (Nexavar°)');
  } else if (!sorafenib) {
      setTypeHCC('');
  };
};
 

 const DiagnostiqueOptions = DataDiagnostic ? DataDiagnostic.flatMap(item => 
  Array.isArray(item.StadeClinique) // Vérifie si StadeClinique est un tableau
    ? item.StadeClinique.map(diagnostic => ({
        label: diagnostic,
        value: item.id,
      }))
    : [] // Si ce n'est pas un tableau, retourne un tableau vide
)
: [];

  const userData =JSON.parse(localStorage.getItem('user'));
  const NomComplet = userData.user.Prenom + ' ' + userData.user.Nom
const role = userData.user.roles.map(item => item.name).join(', ');

  const handleOpenModalConfirmDeletPatientRCP = (record) => {
    setIdPatientDelet(record.id);
    setOpenModalConfirmDeletPatientRCP(true);
  }
  const handleCancelDeletPatient = () => {
    setOpenModalConfirmDeletPatientRCP(false);
    setIdPatientDelet(null);
    // rcpId=null;
  }
  const handleOpenModalConfirmDeletParticipantRCP = (record) => {
    setIdParticipantDelet(record.id);
    setOpenModalConfirmDeletParticipantRCP(true);
  }
  const handleCancelDeletParticipant = () => {
    setOpenModalConfirmDeletParticipantRCP(false);
    setIdParticipantDelet(null);
    // rcpId=null;
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const onCancel = () => {
    setIntituleRcp("");
    setDescription("");
    setDateRcp(null);
    setResponsableRcp("");
    setUserId("");
    setOpen(false);

  };
  const [selectedData, setSelectedData] = useState({
    IntituleRcp: '',
    Description: '',
    DateRcp: '',
    joinUrl: '',
    startUrl: '',
    ResponsableRcp: '',
    rcpId: null,
    Patient: '',
    Participant: '',

  });
  const [selectedRowId, setSelectedRowId] = useState(null);


  useEffect(() => {
    if (dataRcps && dataRcps.length > 0) {
      const lastIndex = dataRcps.length - 1; // Obtenir l'index du dernier élément
      setSelectedRowId(dataRcps[lastIndex].id); // Sélectionner la dernière ligne
      handleOpenRCP(dataRcps[lastIndex]); // Ouvrir la dernière ligne par défaut
    }
  }, [dataRcps]);


  // Récupérer les données des patients RCP depuis le store
  const { data: dataPatientsRCP } = useSelector((state) => state.patientsRCP.data);
  const { data: dataPatients } = useSelector((state) => state.patients.data);
  const rcpId = selectedData.rcpId;
  useEffect(() => {
    // dispatch(fetchPatients());
    dispatch(fetchPatientsRCP(rcpId));
  }, [dispatch, rcpId]);
  const [ordoneeListPatientsRCP, setOrdoneeListPatientsRCP] = useState([]);
   // Extraire les IDs des médecins invités pour comparaison
  const invitedPatientsIds = ordoneeListPatientsRCP.map((patient) => patient.id);
  // Fonction pour vérifier si une ligne doit être inactivée
  const getRowClassNamePatient = (record) => {
    return invitedPatientsIds.includes(record.id) ? 'row-inactive' : '';
  };

  const rowSelectionPatient = {
    selectedRowKeys: selectedPatients,
    onChange: (selectedRowKeys) => {
      setSelectedPatients(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: invitedPatientsIds.includes(record.id),
    }),
  };
  const tableRowPatientStyles = {
    '.row-inactive': {
      backgroundColor: '#f0f0f0',
      color: '#aaa',
      cursor: 'not-allowed',
    },
  };

  useEffect(() => {
    if (rcpId) {
      dispatch(fetchPatientsRCP(rcpId));
      dispatch(fetchMedecinsInvite(rcpId));
    }
  }, [dispatch, rcpId]);
  
  useEffect(() => {
    
    if (dataPatientsRCP) {
      const sortedPatients = [...dataPatientsRCP].sort((a, b) => (b.RcpPatients?.id || 0) - (a.RcpPatients?.id || 0));
      setOrdoneeListPatientsRCP(sortedPatients);
    }
  }, [dataPatientsRCP]);

  
  const { data: dataMedecins } = useSelector((state) => state.medecins.data);
  const { data: dataMedecinsInvite } = useSelector((state) => state.medecinsInvite.data || []);
  useEffect(() => {
    dispatch(fetchMedecins());
    dispatch(fetchMedecinsInvite(rcpId)); // Passez l'ID du RCP pour récupérer les invités
  }, [dispatch, rcpId]);

  const [ordoneeListParticipantsRCP, setOrdoneeListParticipantsRCP] = useState([]);

  useEffect(() => {
    if (dataMedecinsInvite) {
      const sortedParticipants = [...dataMedecinsInvite].sort((a, b) => (b.MedecinParticipants?.id || 0) - (a.MedecinParticipants?.id || 0));
      setOrdoneeListParticipantsRCP(sortedParticipants);
    }
  }, [dataMedecinsInvite]);

  // Extraire les IDs des médecins invités pour comparaison
const invitedMedecinsIds = ordoneeListParticipantsRCP.map((medecin) => medecin.id);

// Fonction pour vérifier si une ligne doit être inactivée
const getRowClassName = (record) => {
  return invitedMedecinsIds.includes(record.id) ? 'row-inactive' : '';
};

const rowSelectionParticipant = {
  selectedRowKeys: selectedParticipants,
  onChange: (selectedRowKeys) => {
    setSelectedParticipants(selectedRowKeys);
  },
  // Désactiver la sélection pour les médecins invités
  getCheckboxProps: (record) => ({
    disabled: invitedMedecinsIds.includes(record.id),
  }),
};
// Style pour les lignes inactives
const tableRowParticipantStyles = {
'.row-inactive': {
  backgroundColor: '#f0f0f0',
  color: '#aaa',
  cursor: 'not-allowed',
},
};

  // Récupérer les spécialités depuis l'API
  const { data: dataSpecialite } = useSelector((state) => state.specialite || []);
  useEffect(() => {
    dispatch(fetchSpecialite());
  }, [dispatch]);

  const colomnsParticipantsActifs = [
    {
      title: 'Participant',
      key: 'NomComplet',
      className: 'colonText',
      width: '25%',
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "left", gap: "8px", marginLeft: "-15px" }}>
            <Avatar size="middle" style={{ marginTop: "3px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
            <div>
              <a>{record.Prenom + ' ' + record.Nom}</a><br />
              <div style={{}}>
                {record.Centres && record.Centres.map((centre, index) => (
                  <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                    {centre.NomCentre}
                  </p>
                ))}
              </div>
            </div>
          </div>

        </>
      ),
    },
    {
      title: 'Contact info',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',
      width: '10%',

    },
    {
      title: 'Date de création',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: 'colonText',
      width: 150,
      render: (createdAt) => {
        // Convertir la date en objet Date
        const date = new Date(createdAt);
        // Options pour formater la date (jour mois année)
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        // Formater la date en français
        return date.toLocaleDateString('fr-FR', options);
      },
    },
    {
      title: 'Rôle',
      dataIndex: 'Roles',
      key: 'Roles',
      className: 'colonText',
      render: (Roles) => (
        <div>
          {Roles.map((role, index) => (
            <p key={index} style={{ fontSize: '12px', margin: 0 }}>
              {role.name}
            </p>
          ))}
        </div>
      ),
    },
    {
      title: 'Profession',
      key: 'specialite',
      dataIndex: 'specialite',
      className: 'colonText',
      width: '8%',
      render: (specialite) => (
        <p style={{ fontSize: '12px', margin: 0 }}>
          {specialite?.NomSpecialite || ''}
        </p>
      ),
    },

  ];
  // Modal fichier rcp
  const [showModal, setShowModal] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecordResume, setSelectedRecordResume] = useState(null);

  const handleCancel = () => {
    setShowResume(false);
    setSelectedRecord(null);
  };

  const handleCancelFiche = () => {
    setShowModal(false);
    setSelectedRecord(null);
  };

  const handleOpenModal = (record) => {
    setShowModal(true);
    setSelectedRecord(record);
  };
  const handleOpenResume = (record) => {
    setShowResume(true);
    setSelectedRecordResume(record);
  };

  const colomnsParticipantsInvite = [
    {
      title: 'Participant',
      key: 'NomComplet',
      className: 'colonText',
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "-15px" }}>
            <Avatar size="small" style={{ fontSize: "10px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
            <div>
              <a>{record.Prenom + ' ' + record.Nom}</a><br />
              <div style={{}}>
                {record.Centres && record.Centres.map((centre, index) => (
                  <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                    {centre.NomCentre}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Contact info',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',
    },
    {
      title: 'Joining date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: 'colonText',
     
      render: (createdAt) => {
        // Convertir la date en objet Date
        const date = new Date(createdAt);
        // Options pour formater la date (jour mois année)
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        // Formater la date en français
        return date.toLocaleDateString('fr-FR', options);
      },
      width: 150,
    },
    {
      title: 'Profession',
      key: 'SpecialiteId',
      dataIndex: 'SpecialiteId',
      className: 'colonText',
      render: (specialiteId) => {
        const specialite = dataSpecialite.find((item) => item.id === specialiteId);
        return specialite ? specialite.NomSpecialite : '';
      },
    },

    ...((role === 'Admin' || role === 'Admin, Medecin' || NomComplet === selectedData.ResponsableRcp)
        ? 
        [
          {
            title: 'Options',
            key: 'options',
            render: (record) => (
                  <div>
                      <Button style={{ background: "#E00B0B", color: "#fff", fontSize: "15px" }}
                        onClick={() => handleOpenModalConfirmDeletParticipantRCP(record)}>
                        Retirer
                      </Button>                
                    </div>
            ),
            className: 'colonText',
          },
        ]
        : 
        []
      ),

  ];
 
  const handleInviteParticipantClick = async (e) => {
    e.preventDefault();

    if (selectedParticipants.length > 0) {
      const payload = {
        rcpId: selectedData.rcpId,
        userIds: selectedParticipants,
      };
      try {
        const result = await dispatch(createAjoutParticipantRCP(payload)).unwrap();
        notification.success({
          message: "Invitation envoyée avec succès",
          description: result.message,
          duration: 5,
        });
        setSelectedParticipants([]); // Vider les participants sélectionnés
        await dispatch(fetchMedecinsInvite(rcpId));
        setActiveTabParticipant('1'); // Passer à l'onglet "Participants invités"
        onCancel();
      } catch (error) {
        // Notification d'erreur
        notification.error({
          message: "Erreur lors de l'envoi de l'invitation",
          description: error.message || "Une erreur est survenue lors de l'invitation des participants.",
          duration: 5,
        });
      }
    } else {
      // Notification d'avertissement si aucun participant n'est sélectionné
      notification.warning({
        message: "Aucun participant sélectionné",
        description: "Veuillez sélectionner des participants avant d'envoyer une invitation.",
        duration: 5,
      });
    }
  };

  const handleDeletPatientRcp = async (idPatientDelet) => {
    const rcpId = selectedData.rcpId;

    try {
      const result = await dispatch(deletePatientRcp({ rcpId, idPatientDelet })).unwrap();
      notification.success({
        message: "Retirer ce patient de cette RCP",
        description: result.message,
        duration: 5,
      });

      await dispatch(fetchPatientsRCP(rcpId));  // Passer l'ID de la RCP
      handleCancelDeletPatient();

      // setOpenModalConfirmDeletPatientRCP(false);
    } catch (error) {
      notification.error({
        message: "Erreur lors de la suppression",
        description: error.message,
        duration: 5,
      });
    }

  };

  const handleDeletParticipantRcp = async (idParticipantDelet) => {
    const rcpId = selectedData.rcpId;

    try {
      const result = await dispatch(deleteParticipantRcp({ rcpId, idParticipantDelet })).unwrap();
      notification.success({
        message: "Retirer ce participant de cette RCP",
        description: result.message,
        duration: 5,
      });

      await dispatch(fetchMedecinsInvite(rcpId));  // Passer l'ID de la RCP
      handleCancelDeletParticipant();
    } catch (error) {
      notification.error({
        message: "Erreur lors de l'ejection du participant",
        description: error.message,
        duration: 5,
      });
    }

  };

  const colomnsPatientsInviteRCP = [
    {
      title: 'Participant',
      key: 'NomComplet',
      className: 'colonText',
      width: '30%',
      render: (text, record) => (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "8px", marginLeft: "-15px" }}>
            <Avatar size="small" style={{ fontSize: "10px" }}>{record.Prenom.charAt(0).toUpperCase()}{record.Nom.charAt(0).toUpperCase()}</Avatar>
            <div>
              <a>{record.Prenom + ' ' + record.Nom}</a><br />
              <div style={{}}>
                {record.Centres && record.Centres.map((centre, index) => (
                  <p key={index} style={{ fontSize: '12px', margin: 0 }}>
                    {centre.NomCentre}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: 'Contact info',
      key: 'Email',
      dataIndex: 'Email',
      className: 'colonText',
      width: '25%',
    },
    {
      title: 'Date de création',
      key: 'DateCreation',
      dataIndex: 'DateCreation',
      className: 'colonText',
      width: '25%',
      render: (createdAt) => {
        // Convertir la date en objet Date
        const date = new Date(createdAt);
        // Options pour formater la date (jour mois année)
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        // Formater la date en français
        return date.toLocaleDateString('fr-FR', options);
      },
    },

    {
      title: 'Profession',
      key: 'Professsion',
      dataIndex: 'Professsion',
      className: 'colonText',
      width: '16%',
    },
    {
      title: 'Options',
      render: (record) => (
        <div style={{ display: "flex", gridGap: "20px", alignItems: "center" }}>
            <Button onClick={() => handleOpenModalConfirmation(record)}>Editer Détails RCP</Button>
        </div>
      ),
      className: 'colonText',
    },
    {
      title: 'Options',
      render: (record) => (
        <div style={{ display: "flex", gridGap: "20px", alignItems: "center" }}>
            <Button onClick={() => handleOpenResume(record)}>Résumé</Button>
        </div>
      ),
      className: 'colonText',
    },

    ...((role === 'Admin' || role === 'Admin, Medecin' || NomComplet === selectedData.ResponsableRcp)
    ? 
    [
    {
      title: '',
      render: (record) => (
        <div>
            
              <div>
                <Button style={{ background: "#E00B0B", color: "#fff", fontSize: "15px" }}
                  onClick={() => handleOpenModalConfirmDeletPatientRCP(record)}>
                  Retirer
                </Button>                
              </div>
        </div>
      ),
    }
    ]
    : 
    []
  ),

  ];
  
  // Filtrer les participants invités
  const filtrDataMedecinsInvite = ordoneeListParticipantsRCP?.filter(item => {
    const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
    const specialite = dataSpecialite.find((spec) => spec.id === item.SpecialiteId);
    const profession = specialite ? specialite.NomSpecialite.toLowerCase() : ''; // Accéder au nom de la spécialité
    const joiningDate = new Date(item.createdAt).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }); // Formater la date pour la comparaison
    return (
      nomComplet.includes(searchValueParticipant.toLowerCase()) ||
      item.Email.toLowerCase().includes(searchValueParticipant.toLowerCase()) ||
      joiningDate.includes(searchValueParticipant.toLowerCase()) || // Recherche par date d'entrée
      profession.includes(searchValueParticipant.toLowerCase()) // Recherche par profession
    );
  });


  const filtrDataMedecins = dataMedecins?.filter(item => {
    const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
    const roles = item.Roles.map(role => role.name.toLowerCase()).join(' '); // Convertir les rôles en chaîne
    const specialite = item.specialite ? item.specialite.NomSpecialite.toLowerCase() : ''; // Accéder au nom de la spécialité
    const joiningDate = new Date(item.createdAt).toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' }); // Formater la date
    return (
      nomComplet.includes(searchValueParticipant.toLowerCase()) || // Recherche par nom complet
      item.Email.toLowerCase().includes(searchValueParticipant.toLowerCase()) || // Recherche par email
      specialite.includes(searchValueParticipant.toLowerCase()) || // Recherche par profession
      joiningDate.includes(searchValueParticipant.toLowerCase()) || // Recherche par date d'entrée
      roles.includes(searchValueParticipant.toLowerCase()) // Recherche par rôle
    );
  });


  const filtrDataRCP = dataRcps?.filter(item => {
    const intituleRcp = item.IntituleRcp || ''; // Valeur par défaut vide
    const dateRcp = item.DateRcp || ''; // Valeur par défaut vide

    // Conversion de la date en format "dd/mm/yyyy"
    const formattedDateRcp = new Date(dateRcp).toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    const searchValue = searchValueRCP ? searchValueRCP.toLowerCase() : '';
    return (
      intituleRcp.toLowerCase().includes(searchValue) ||
      formattedDateRcp.includes(searchValue)
    );
  });


  const itemsTabParticipants = [
    {
      key: '1',
      label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Participants invités</div>,
      children: <>
        <Table
          columns={colomnsParticipantsInvite}
          // dataSource={filtrDataMedecinsInvite ? [...filtrDataMedecinsInvite].reverse() : []}
          dataSource={filtrDataMedecinsInvite}
          // dataSource={filteredData.length > 0 ? filteredData : dataMedecinsInvite}
          // dataSource={filtrDataMedecinsInvite ? [...filtrDataMedecinsInvite].slice().reverse() : []}
          rowKey={(record) => record.id}
        />
      </>
    },
    {
      key: '2',
      label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Participants actifs</div>,
      children: <>
        <Table
          rowSelection={rowSelectionParticipant}
          columns={colomnsParticipantsActifs}
          dataSource={filtrDataMedecins ? [...filtrDataMedecins].reverse() : []}
          rowKey={(record) => record.id}
          rowClassName={getRowClassName}
          style={tableRowParticipantStyles}        
        />
      </>
    }
  ];

  const filtrDataPatientsInvites = searchValue
    ? ordoneeListPatientsRCP?.filter(item => {
      const nomComplet = `${item.Prenom} ${item.Nom}`.toLowerCase();
      return (
        nomComplet.includes(searchValue.toLowerCase()) ||
        item.Email.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.Professsion.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.DateCreation.toLowerCase().includes(searchValue.toLowerCase())
      );
    })
    : ordoneeListPatientsRCP; // Si searchValue est vide, retourner toutes les données


  const itemsTabPatients = [
    {
      key: '1',
      label: <div style={{ marginLeft: "25px", fontSize: "12px", color: "#1677FF" }}>Patients invités</div>,
      children:
        <>
          <Table
            columns={colomnsPatientsInviteRCP}
            // dataSource={ordoneeList}
            dataSource={filtrDataPatientsInvites}
            // dataSource={filtrDataPatientsInvites ? [...filtrDataPatientsInvites].reverse() : []}
            rowKey={(record) => record.id}
          />
        </>
    },
  ];

  // Assurez-vous que handleOpenRCP est défini pour gérer le clic et récupérer l'ID
  const handleOpenRCP = (record) => {
    setSelectedData({
      IntituleRcp: record.IntituleRcp,
      Description: record.Description,
      DateRcp: record.DateRcp,
      joinUrl: record.joinUrl,
      ResponsableRcp: record.ResponsableRcp,
      startUrl: record.startUrl,
      rcpId: record.id
    })
    setSelectedRowId(record.id);
  };

  const columnsListRCP = [
    {
      key: '',
      dataIndex: '',
      IntituleRcp: '',
      Description: '',
      DateRcp: '',
      ResponsableRcp: '',
      joinUrl: '',
      render: (text, record) => (
        <div
          style={{ display: "flex", alignItems: "left", justifyContent: "left", }}
        >
          <Card style={{ backgroundColor: '#F5F5F5', width: "6vw", height: "9vh", }}>
            <div style={{ fontSize: "10px", marginTop: "-23px", }}>
              <div style={{ fontWeight: "normal", }}>{new Date(record.DateRcp).toLocaleDateString('fr-FR', { weekday: 'long' }).slice(0, 3).toUpperCase()}</div>
              <strong style={{ fontSize: "15px", marginLeft: "1px", }}>{String(new Date(record.DateRcp).toLocaleDateString('fr-FR', { day: 'numeric' })).padStart(2, '0')}</strong>
              <div style={{ fontWeight: "normal", }}>{new Date(record.DateRcp).toLocaleDateString('fr-FR', { month: 'long' }).slice(0, 3).toUpperCase()}</div>
            </div>
          </Card>
          <>
            <div className="divContainDescrip">  <div className="rcsDescript">{record.IntituleRcp}</div>
              <br />
              <div className="divHeur">
                {new Date(record.DateRcp).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }).replace(':', 'h ') + 'mn'}
              </div>
            </div>
          </>
        </div>
      ),
    },
  ];
  const { Title, Text } = Typography;
  const items = [
    {
      key: '1',
      label: 'Détails',
      icon: <FolderOutlined />,
      children: (
        <Card
          size="small"
          title="Informations sur la RCP"
          bordered={true}
          headStyle={{ backgroundColor: '#faf9f9' }}
          style={{ width: 850, borderWidth: '3px', borderStyle: 'solid', marginTop: "30px" }}
        >
          <div style={{ padding: '5px', textAlign: 'left' }}>
            {/* Section des détails de la RCP */}
            <h4 style={{ color: 'rgb(33, 82, 255)', marginBottom: '1px', marginTop: '0px' }}>
            </h4>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', }}>
              <p><strong>Intitulé :</strong> {selectedData.IntituleRcp}</p>
              <p><strong>Description :</strong> {selectedData.Description}</p>
            </div>

            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', }}>
              <p><strong>Date : </strong>
                {new Date(selectedData.DateRcp).toLocaleDateString('fr-FR', { weekday: 'long', }) + ' '}
                {String(new Date(selectedData.DateRcp).toLocaleDateString('fr-FR', { day: 'numeric' })) + ' '}
                {new Date(selectedData.DateRcp).toLocaleDateString('fr-FR', { month: 'long' })}
              </p>
              <p><strong>Heure : </strong>
                {new Date(selectedData.DateRcp).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' }).replace(':', 'h ') + 'mn'}
              </p>
            </div>
            <p><strong>Responsable de la RCP : </strong>
              {selectedData.ResponsableRcp}
            </p>

            {((role === 'Admin' || role === 'Admin, Medecin' || NomComplet === selectedData.ResponsableRcp)) && (
              <div style={{ display: 'grid', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                <p>
                  <strong>Lien de démarrage : </strong>
                  <a href={selectedData.startUrl} target="_blank" rel="noopener noreferrer">
                    Cliquez ici pour démarrer la réunion
                  </a>
                </p>
              </div>
            )}
            <p><strong>Lien d'invitation :</strong>
              <a>{selectedData.joinUrl}</a>
            </p>
          </div>
        </Card>
      ),
    },
    {
      key: '2',
      label: 'Patients',
      icon: <UserOutlined />,
      children: <div>
        <div style={{ alignItems: "center", display: "flex", gap: "7.75rem" }}>
          <h4 style={{ float: "left", padding: "0px", marginTop: "3px", marginLeft: "10px", marginRight: "275px" }}>Gérer les patients</h4><br /><br />
          <BtnSearch
            bgNormalColor="#ffffff"
            border_="1.5px solid #1677FF"
            onChange_={(e) => setSearchValue(e.target.value)}
            allowClear
          />
        </div>
        <Tabs
          onChange={onChange}
          items={itemsTabPatients}
        />
      </div>,
    },
    {
      key: '3',
      label: 'Participants',
      icon: <TeamOutlined />,
      children:
        <>
          <div style={{ alignItems: "center", display: "flex", gap: "2rem" }}>
            <h4 style={{ float: "left", padding: "0px", marginTop: "3px", marginLeft: "10px", marginRight: "275px" }}>Gérer les participants</h4><br /><br />
            <BtnSearch
              bgNormalColor="#ffffff"
              border_="1.5px solid #1677FF"
              onChange_={(e) => setSearchValueParticipant(e.target.value)}
              allowClear
            />
            
        {((role === 'Admin' || role === 'Admin, Medecin' || NomComplet === selectedData.ResponsableRcp)) && (
            <Button
              onClick={handleInviteParticipantClick}
              size="middle"
              style={{ fontSize: "12px", color: "#1677FF", border: "1.5px solid #1677FF", }} variant="filled">
              <div>Envoyer une invitation</div>
            </Button>
          )}
          </div>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeTabParticipant}
            onChange={setActiveTabParticipant}
            items={itemsTabParticipants}
          />
        </>,
    },

  ];

  const handleSubmitRCP = async (e) => {
    e.preventDefault(); // Empêche le rechargement de la page à la soumission du formulaire
    let RCPData = {
      IntituleRcp: IntituleRcp,
      Description: Description,
      DateRcp: DateRcp,
      userId: userId,
      ResponsableRcp: ResponsableRcp,
    };
    try {
      const result = await dispatch(createRCP(RCPData)).unwrap(); // Unwrap pour accéder directement à la réponse
      notification.success({
        message: "Création de la RCP réussie",
        description: result.message,
        duration: 6,
      });
      onCancel(); // Appel correct de la fonction pour fermer le modal
      await dispatch(fetchRcps());
    }
    catch (err) {
      notification.error({
        message: "Erreur d'enregistrement",
        description: err.message,
        duration: 6,
      });
    }
  };

  const handleBack = () => {
    setShowResume(false);
    };
    const [userId, setUserId] = useState();
    const [ResponsableRcp, setResponsableRcp] = useState();

    const handleChangeResponsableRcp = (value) => {
      setUserId(value);
    
      const selectedOption = ResponsableRcpOptions.find(
        (option) => option.value === value
      );
      
      if (selectedOption) {
        setResponsableRcp(selectedOption.label); // Utiliser le label
      }
    };
    const ResponsableRcpOptions = dataMedecins?.map((medecins) => ({
      label: medecins.Prenom + " " + medecins.Nom,
      value: medecins.id,
    }));

     const handleUpdateDetailRCP = async (e) => {
          e.preventDefault();
          const id = PatientIdDetailRCP;
          const body = inputDataDetailRCP;
          // console.log(id)
          try {
            const result = await dispatch(updateDetailRCP({id,body})).unwrap();
            notification.success({
              message: "Détail RCP mis à jour avec succès",
              description: result.message,
              duration: 6,
            });
            setDetailRCPData(inputDataDetailRCP);
            setOpenUpdateForm(false);
            await dispatch(fetchFichesRCP(PatientId));  
            await dispatch(fetchRcps());
          } catch (err) {
            notification.error({
              message: "Erreur de mise à jour",
              description: err.message,
              duration: 6,
            });
          }
        };

  return (
    <div>
{!showResume ? (
    <div>
      <div className="buttons-containerRCP" style={{ marginLeft: collapsed ? 192 : 90 }}>
        <BtnSubmit type_="button"
          onClick={handleOpen}
          className="btnAjouter"
          width_="100px"
          margin_="0px 65px 80px 655px"
          buttonText="Ajouter"
        />
        <ModalComponent
          _maskClosable={false}
          _width="500px"
          _marginTop="-200px"
          _top="90px"
          onCancel={onCancel}
          _open={open}
          _title="Ajouter un RCP"
          form=
          {
            <form>
              <Row>

                <Col span={24}>
                  <p>Intitulé de la RCP</p>
                  <InputText
                    required="required"
                    type="text"
                    placeholder_="Intitulé de la RCP"
                    name={IntituleRcp}
                    value={IntituleRcp}
                    onChange={(e) => setIntituleRcp(e.target.value)}
                  />
                </Col>
                <Col span={24}>
                  <p>Description</p>
                  <InputText required
                    type="text" placeholder_="Description"
                    name={Description}
                    value={Description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  {/* <TimePicker style={{width: '100%',}} defaultValue={dayjs('15:00', format)} format={format} /> */}
                </Col>

                <Col span={24}>
                  <p>Date de la RCP</p>
                  <DatePicker
                    value={DateRcp !== null ? dayjs(DateRcp) : null}
                    format="YYYY-MM-DD HH:mm"
                    placeholder_="Date du RCP"
                    name="DateRcp"
                    style={{ width: '100%' }}
                    showTime
                    onChange={(DateRcp, dateString) => {
                      setDateRcp(dateString);
                    }}
                  /></Col>
              </Row> 
              <Row>
                <Col span={24}>
                  <p>Responsable de la RCP</p>
                    <Select style={{width: '100%',}}
                      showSearch
                      placeholder="Choisir un médecin responsable"
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={ResponsableRcpOptions}
                      value={userId}
                      onChange={handleChangeResponsableRcp}
                    />

                </Col>
              </Row>
              
              <br /><br />

              <BtnSubmit float_="right"
                type_="button"
                width_="80px"
                bgNormalColor="#78953E"
                buttonText="Enregistrer"
                color="#fff"
                boldColor={700}
                height_="35px"
                border_="none"
                position_="relative"
                top_="-28px"
                borderRadius_="0.5rem"
                onClick={handleSubmitRCP}
              />
            </form>
          }
        />
        <BtnSearch
          margin_="-65px 0px 80px -40px"
          height_="39px"
          bgNormalColor="#ffffff"
          border_="1.5px solid #1677FF"
          onChange_={(e) => setSearchValueRCP(e.target.value)}
          allowClear
        />
      </div>
      <Card
        size="small"
        title={<strong><p style={{ float: "left", padding: "0px", }}>Liste des RCP</p></strong>}
        bordered={true}
        headStyle={{ backgroundColor: '#faf9f9', }}
        style={{ borderWidth: '3px', borderStyle: 'solid', marginTop: "-65px" }}
      >

        <div style={{ display: "flex", marginTop: "-2vh", }}>
          <div>
            <div style={{ background: "#ffff", }} >
              <Table
                onRow={(record) => ({
                  onClick: () => handleOpenRCP(record),
                  className: record.id === selectedRowId ? 'selected-row' : '',
                })}
                columns={columnsListRCP}
                dataSource={filtrDataRCP ? [...filtrDataRCP].reverse() : []}
                // dataSource={dataRcps}
                pagination={false}
                scroll={{
                  y: 450, // Hauteur pour le défilement vertical 
                }}
                showHeader={false}
                style={{ padding: "0px" }}
                className="custom-table"
                loading = {loading}
              />
            </div>
          </div>

          <div className="tabs-container">
            <Tabs
              className="tabsItems"
              type="card"
              size="large"
              defaultActiveKey={1}
              items={items}
              onChange={onChange}
            />
          </div>
        </div>
      </Card>
    </div>
) :(
  <>
          <div>
          <BtnSubmit 
            float_="right"
            margin_="50px 0px 20px"
            buttonText="Retour" border_="none" color="#ffff"
            type_="button" width_="7vw" height_="5.5vh" borderRadius_="0.2rem"
            bgNormalColor="linear-gradient( 310deg, rgb(33, 202, 253), rgb(33, 82, 255))"
            onClick={handleBack}
            />
          </div>

      <div>
      <PdfView  PatientId={selectedRecordResume?.id}/>
      </div>

  </>
)
}
    <>
          <ModalComponent
          _maskClosable={false}
          _width="1000px"
          _top="20px"
          onCancel={handleCancelFiche}
          _open={showModal}
          _title="Fiche Patient"
          form={<Fiche data={selectedRecord} RcpId={rcpId} close={handleCancelFiche} />}
          />
          <Modal
            maskClosable={false}
            closable={false}
            title="Supprimer un patient d'un RCP"
            open={openModalConfirmDeletPatientRCP}
            onOk={() => handleDeletPatientRcp(idPatientDelet)}
            onCancel={handleCancelDeletPatient}
            okText="Oui"
            cancelText="Non">
            <p>Voulez-vous retirer ce patient de cette réunion?</p>
          </Modal>

          <Modal
            maskClosable={false}
            closable={false}
            title="Retirer un participant d'un RCP"
            open={openModalConfirmDeletParticipantRCP}
            onOk={() => handleDeletParticipantRcp(idParticipantDelet)}
            onCancel={handleCancelDeletParticipant}
            okText="Oui"
            cancelText="Non">
            <p>Voulez-vous retirer le participant de cette réunion?</p>
          </Modal>
    </>

 {/* Modal et formulaire d'édite de detail rcp */}
 <>
                  <ModalComponent _top="25px"
                    _maskClosable={false}
                    onCancel={onCloseUpdateForm}
                    _open={openUpdateForm} 
                    _width="700px" 
                    _title="Modifier détail RCP"
                    form=
                    {
                      <form onSubmit={handleUpdateDetailRCP}>
                         <Row gutter={8}> 
                        <Col span={12}>
                          <p className='inputPatient2'>Diagnostic </p>
                          <Select name="DiagnosticId" placeholder_="Veuillez choisir le diagnostique correspondant" 
                         onChange={(value) => handleChange(value, 'DiagnosticId')}  
                         value={inputDataDetailRCP.DiagnosticId}
                         
                         style={{width:"100%", height: "5vh",}}
    
                           >
                            {DiagnostiqueOptions.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                        </Select>
                        </Col>
                        <Col span={12}>
                        <p className='inputPatient2'>RCP </p>
                      
    
                        {/* <div>
                        {loading ? (
                          <Spin /> // Indicateur de chargement
                        ) : ( */}
                          <Select
                            name="RcpId"
                            placeholder="Veuillez choisir la RCP"
                            // onChange={(value) => handleChange(value, "RcpId")}
                            value={inputDataDetailRCP.RcpId}
                            style={{ width: "100%", height: "5vh" }}
                          >
                            {RCPsOptions.map((option) => (
                              <Select.Option key={option.value} value={option.value}>
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        {/* )} */}
                      {/* </div> */}
                      
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col span={12}>
                            <p className='inputPatient2'>Motif de RCP</p>
                            <Select
                                mode="tags"
                                name="motif"
                                onChange={(value) => handleChange(value, 'MotifRcp')}  
                                value={inputDataDetailRCP.MotifRcp}
                                style={{ width: "100%", height: "5vh", }}
                                aria-required
                            >
                                {optionMotif.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                        <Col span={12}>
                            <p className='inputPatient2'>Traitement réalisé</p>
                            <Select
                                mode="tags"
                                name="traitement"
                                onChange={(value) => handleChange(value, 'TraitementRealises')}  
                                value={inputDataDetailRCP.TraitementRealises || []}
                                
                                style={{ width: "100%", height: "5vh", }}
                            >
                                {optionTraitement.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                      </Row>
                      
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Tumeurs cerebrales' && traitementRealise.includes('Chirurgie') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={resectionComplete}
                                        onChange={handleResectionCompleteChange}
                                    >
                                        résection complète
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}>
                                    <Checkbox
                                        checked={resectionIncomplete}
                                        onChange={handleResectionIncompleteChange}
                                    >
                                        résection incomplète
                                    </Checkbox>
                                </Col>
    
                            </Row>
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Hépatectomies réglées') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={segmentectomies}
                                        onChange={handleSegmentectomiesChange}
                                    >
                                        Segmentectomies
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={hepatectomies}
                                        onChange={handleHepatectomiesChange}
                                    >
                                        Hépatectomies droite ou gauche
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={lobectomies}
                                        onChange={handleLobectomiesChange}
                                    >
                                        Lobectomies droite ou gauche
                                    </Checkbox>
                                </Col>
                            </Row>
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Exérèses atypiques') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={enucleation}
                                        onChange={handleEnucleationChange}
                                    >
                                        Énucléation
                                    </Checkbox>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}>
                                    <Checkbox
                                        checked={resectionCuneiforme}
                                        onChange={handleResectionCuneiformeChange}
                                    >
                                        Résection cunéiforme
                                    </Checkbox>
                                </Col>
                            </Row>
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Transplantation') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={chimio}
                                        onChange={handleChimioChange}
                                    >
                                        Chimiothérapie
                                    </Checkbox>
                                </Col>
    
                            </Row>
    
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Hépatoblastome de bas risque') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={cisPlatPre}
                                        onChange={handleCisPlatPreChange}
                                    >
                                        cis-plat en pré
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={postOp}
                                        onChange={handlePostOpChange}
                                    >
                                        post-op
                                    </Checkbox>
                                </Col>
    
    
                            </Row>
    
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Hépatoblastome de risque standard') &&
                        <div style={{ marginTop: "20px" }}>
                            <p className='inputPatient2'>Hépatoblastome de risque standard</p>
    
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={cispla}
                                        onChange={handleCisplaChange}
                                    >
                                        cispla
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={plado}
                                        onChange={handlePladoChange}
                                    >
                                        PLADO
                                    </Checkbox>
                                </Col>
                            </Row>
                            <p style={{ marginTop: "10px" }}>Chimio post-o</p>
                            <Row style={{ marginTop: "10px" }}>
                                <Col span={12}>
                                    <Checkbox
                                        checked={pladoChimio}
                                        onChange={handlePladoChimioChange}
                                    >
                                        PLADO
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={cisplaChimio}
                                        onChange={handleCisplaChimioChange}
                                    >
                                        cispla
                                    </Checkbox>
                                </Col>
    
                            </Row>
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Hépatoblastome de haut risque') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={carboplatine}
                                        onChange={handleCarboplatineChange}
                                    >
                                        carboplatine
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={adria}
                                        onChange={handleAdriaChange}
                                    >
                                        adria
                                    </Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={cisplat}
                                        onChange={handleCisplatChange}
                                    >
                                        cisplat
                                    </Checkbox>
                                </Col>
    
                            </Row>
                        </div>
                    }
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Hépatocarcinomes') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={pladoHepatocarcinome}
                                        onChange={handlePladoHepatocarcinomeChange}
                                    >
                                        PLADO
                                    </Checkbox>                            </Col>
    
    
                            </Row>
                        </div>
                    }
    
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('Métastatiques, rupture') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={cisplatMetastatique}
                                        onChange={handleCisplatMetastatiqueChange}
                                    >cisplat</Checkbox>
                                </Col>
                                <Col span={12}>
                                    <Checkbox
                                        checked={adriaMetastatique}
                                        onChange={handleAdriaMetastatiqueChange}
                                    >adria</Checkbox>
                                </Col>
    
    
                            </Row>
                        </div>
                    }
                    {data.Specialite === 'Pédiatrie' && data.Organe === 'Foie' && traitementRealise.includes('HCC') &&
                        <div style={{ marginTop: "20px" }}>
                            <Row>
                                <Col span={12}>
                                    <Checkbox
                                        checked={sorafenib}
                                        onChange={handleSorafenibChange}
                                    >Sorafenib (Nexavar°)</Checkbox>
                                </Col>
    
    
                            </Row>
                        </div>
                    }
    
                    <Row>
                        <Col span={24}>
                            <p className='inputPatient2'>Type de la proposition</p>
                            <Select
                                mode="tags"
                                name="typeProposition"
                                onChange={(value) => handleChange(value, 'TypeProposition')}  
                                value={inputDataDetailRCP.TypeProposition || []}
                                
                                style={{ width: "100%", height: "5vh", }}
                            >
                                {optionTypeProposition.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                    <Divider orientation="center" plain style={{ marginTop: "30px" }}>
                        Décision de la RCP
                    </Divider>
                    <Row gutter={8}>
                        <Col span={12}>
                            <p className='inputPatient2'>Nécessité d'examens complémentaires </p>
                            <Input placeholder="Nécessité d'examens complémentaires" name="necessiteExam" 
                            onChange={(e) => handleChange(e.target.value, 'DecisionRcpExamensComplementaires')}  
                            value={inputDataDetailRCP.DecisionRcpExamensComplementaires || ""}
                                style={{ height: "5vh", }}
                            />
                        </Col>
                        <Col span={12}>
                            <p className='inputPatient2'>Mise en traitement</p>
                            <Select
                                mode="tags"
                                name="miseEnTraitement"
                                onChange={(value) => handleChange(value, 'DecisionRcpMiseEnTraitement')}  
                                value={inputDataDetailRCP.DecisionRcpMiseEnTraitement || []}
                                style={{ width: "100%", height: "5vh", }}
                            >
                                {optionDecision.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
    
                    </Row>
                    <Row>
                        <Col span={24}>
                            <p className='inputPatient2'>Motivation de la décision de la RCP </p>
                            <TextArea
                             onChange={(e) => handleChange(e.target.value, 'MotivationDecisionRcp')}  
                             value={inputDataDetailRCP.MotivationDecisionRcp || []}
                                placeholder="Motivation de la décision de la RCP"
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 3,
                                }}
                            />
                        </Col>
                        </Row>
                        <BtnSubmit 
                                    type_="submit"
                                    width_="86px"
                                    float_="right"
                                    bgNormalColor="linear-gradient(310deg, rgb(33, 212, 253), rgb(33, 82, 255))"
                                    buttonText="Enregistrer"
                                    color="#fff"
                                    boldColor={400}
                                    height_="37px"
                                    border_="none"
                                    position_="relative"
                                    top_="13px"
                                    borderRadius_="0.5rem"
                                />
                                <br/><br/>
                      </form>
                    }  
                  />
                          <Modal
                            maskClosable={false}
                            closable={false}
                            title="Modifier un detail RCP" 
                            open={openModalConfirmation} 
                            onOk={() => handleOpenUpdateDetailRCPForm(UpdateDetailRCPdata)}       
                            onCancel={handleCancelModalConfirmation}
                            okText="Oui" 
                            cancelText="Non">
                            <p>Voulez-vous modifier ce deatil RCP?</p>
                          </Modal>
              </>   

</div>

  );
};

export default Rcp;