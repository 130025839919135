import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./UserSlice";
import { registerSlice } from './RegistreSlice';
import { specialiteSlice } from "./specialiteSlice";
import { centreSlice } from "./centreSlice";
import { createSpecialiteSlice } from "./CreateSpecialiteSlice";
import { createCentreSlice } from "./CreateCentreSlice";
import { utilisateurSlice } from "./UtilisateurSlice";
import { antecedantSlice } from "./AntecedantSlice";
import { createAntecedantSlice } from "./CreateAntecedantSlice";
import { typeAntecedantSlice } from "./TypeAntecedantSlice";
import { createPatientSlice } from "./CreatePatientSlice";
import { patientSlice } from "./patientSlice";
import { createTypeAntecedantSlice } from "./CreateTypeAntecedantSlice";
import { patientsSlice } from "./patientsSlice";
import { medecinsSlice } from "./MedecinSlice";
import { medecinsInviteSlice } from "./MedecinInviteSlice";
import { patientsRCPSlice } from "./PatientsRCPSlice";
import { pathologiesSlice } from "./PathologiesSlice";
import { createPathologieSlice } from "./CreatePathologieSlice";
import { rcpsSlice } from "./RcpsSlice";
import { createRCPSlice } from "./CreateRCPSlice";
import { createAjoutParticipantRCPSlice } from "./AjoutParticipantRCPSlice";
import { createAjoutPatientRCPSlice } from "./AjoutPatientRCPSlice";
import { antecedentsPatientSlice } from "./AntecedentsPatientSlice";
import { updateDetailsUserSlice } from "./ModifierDetailsUserSlice";
import { updatePasswordSlice } from "./ModifierPasswordSlice";
import { updateActiverUserSlice } from "./ActiverUserSlice";
import { updateDesactiverUserSlice } from "./DesactiverUserSlice";
import { createForgotPasswordSlice } from "./PasswordOublieSlice";
import { createResetPasswordSlice } from "./ResetPasswordSlice";
import { createAntecedantPatientSlice } from "./CreateAntecedentPatientSlice";
import { createAGOSlice } from "./CreateAGOSlice";
import { createFicheRcpSlice } from "./CreateFicheRcpSlice";
import { agosSlice } from "./AGOsSlice";
import { fichesRCPSlice } from "./FicheRCPSlice";
import { updatePatientSlice } from "./UpdatePatientSlice";
import { updateCentreSlice } from "./UpdateCentreSlice";
import { updateSpecialiteSlice } from "./UpdateSpecialiteSlice";
import { updatePathologieSlice } from "./UpdatePathologieSlice";
import { updateArchivSpecialiteSlice } from "./ArchivSpecialiteSlice";
import { CreateDiagniosticRcpSlice } from "./CreateDiagniosticRcpSlice";
import { updateArchivCentreSlice } from "./ArchivCentreSlice";
import { updateArchivPathologieSlice } from "./ArchivPathologieSlice";
import { updateArchivPatientSlice } from "./ArchivPatientSlice";
import { deletePatientRcpSlice } from "./ArchivPatientRcpSlice";
import { deleteParticipantRcpSlice } from "./DeleteParticipantRcpSlice";
import { resumePatientSlice } from "./ResumePatientSlice";
import { diagnostiquesSlice } from "./DiagnostiquesSlice";
import { diagnosticsPatientSlice } from "./DignosticsPatientSlice";
import { updateDetailRCPSlice } from "./UpdateDetailRCPSlice";
import { updateDiagnosticSlice } from "./UpdateDiagnosticSlice";
import { updateAntecedentPSlice } from "./UpdateAntecedentPSlice";
import { updateAGOPatientSlice } from "./UpdateAGOPatientSlice";
import { statisticSlice } from "./StatisticSlice";
import { statisticSpecialitePatientSlice } from "./StatisticSpecialitePatientSlice";


// Configuration et création du magasin Redux
const store = configureStore({
  reducer: {
    // Association de l'état `user` avec le réducteur `userReducer`
    user: userReducer,
    // Association de l'état `authRegister` avec le réducteur `registerSlice`
    authRegister: registerSlice.reducer,
    // Association de l'état `Specialite` avec le réducteur `specialiteSlice`
    specialite: specialiteSlice.reducer,
    centre: centreSlice.reducer,
    creatingSpecialite: createSpecialiteSlice.reducer,
    creatingCentre: createCentreSlice.reducer,
    utilisateur: utilisateurSlice.reducer,
    antecedant: antecedantSlice.reducer,
    createAntecedant: createAntecedantSlice.reducer,
    typeAntecedant: typeAntecedantSlice.reducer,
    creatingPatient: createPatientSlice.reducer,
    patient: patientSlice.reducer,
    createTypeAntecedant: createTypeAntecedantSlice.reducer,
    patients: patientsSlice.reducer,
    medecins: medecinsSlice.reducer,
    medecinsInvite: medecinsInviteSlice.reducer,
    patientsRCP: patientsRCPSlice.reducer,
    pathologies: pathologiesSlice.reducer,
    createPathologie: createPathologieSlice.reducer,
    Rcps: rcpsSlice.reducer,
    createRCP: createRCPSlice.reducer,
    createAjoutParticipantRCP: createAjoutParticipantRCPSlice.reducer,
    createAjoutPatientRCP: createAjoutPatientRCPSlice.reducer,
    antecedentsPatient: antecedentsPatientSlice.reducer,
    updateDetailsUser: updateDetailsUserSlice.reducer,
    Password: updatePasswordSlice.reducer,
    activerUser: updateActiverUserSlice.reducer,
    desactiverUser: updateDesactiverUserSlice.reducer,
    creatingForgotPassword: createForgotPasswordSlice.reducer,
    creatingResetPassword: createResetPasswordSlice.reducer,
    ficheRcp: createFicheRcpSlice.reducer,
    digniosticRcp: CreateDiagniosticRcpSlice.reducer,
    creatingAntecedantPatient: createAntecedantPatientSlice.reducer,
    creatingAGO: createAGOSlice.reducer,
    agos: agosSlice.reducer,
    FichesRCP: fichesRCPSlice.reducer,
    updatingPatient: updatePatientSlice.reducer,
    updatingCentre: updateCentreSlice.reducer,
    updatingSpecialite: updateSpecialiteSlice.reducer,
    updatingPathologie: updatePathologieSlice.reducer,
    updatingArchivSpecialite: updateArchivSpecialiteSlice.reducer,
    updatingArchivCentre: updateArchivCentreSlice.reducer,
    updatingArchivPathologie: updateArchivPathologieSlice.reducer,
    updatingArchivPatient: updateArchivPatientSlice.reducer,
    deletingPatientRcp:deletePatientRcpSlice.reducer,
    deletingParticipantRcp:deleteParticipantRcpSlice.reducer,
    resumePatient:resumePatientSlice.reducer,
    diagnostiques:diagnostiquesSlice.reducer,
    diagnosticsPatient:diagnosticsPatientSlice.reducer,
    updatingDetailRCP:updateDetailRCPSlice.reducer,
    updatingDiagnostic:updateDiagnosticSlice.reducer,
    updatingAntecedentP:updateAntecedentPSlice.reducer,
    updatingAGOPatient:updateAGOPatientSlice.reducer,
    Statistics:statisticSlice.reducer,
    StatisticSpecialitePatient:statisticSpecialitePatientSlice.reducer,

  }
});

// Exportation du magasin pour qu'il puisse être utilisé dans d'autres parties de l'application
export default store;
